import { isMobile, isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, pwaStyleAdapter, isStandalonePWA } from '../../utils/mobileSupported';
import { isLadyluck } from 'shared/utils/targetApplication';

const MobileOnlyNotPWA = isNotStandaloneMobilePWA && !isTablet;
const MobileOnlyPWA = isStandalonePWA && !isTablet;
const TabletOnlyPWA = isTablet && isStandalonePWA;
const TabletOnlynotPWA = isTablet && isNotStandaloneMobilePWA;
const MobileOnlyLLC = isMobile && !isTablet && isLadyluck;

const Styles = {
  fottetButtonStyle: {
    width:
      window.innerWidth < 1400 && window.innerHeight < 800
        ? isTablet
          ? pwaStyleAdapter(255, 'px', 1.7)
          : pwaStyleAdapter(200, 'px')
        : '295px',
    height:
      window.innerWidth < 1400 && window.innerHeight < 800
        ? isTablet
          ? pwaStyleAdapter(45, 'px', 1.7)
          : pwaStyleAdapter(45, 'px')
        : '55px',
    cursor: 'pointer',
  },
  NoAssetLayoutModalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isTablet ? '0 10vmin' : '0 50vmin',
  },
  NoAssetLayoutModalBodyStyle: {
    height: TabletOnlyPWA
      ? '50%'
      : TabletOnlynotPWA
        ? '59%'
        : MobileOnlyLLC && isStandalonePWA
          ? pwaStyleAdapter(47, '%', 1.5)
          : MobileOnlyLLC
            ? pwaStyleAdapter(52, '%', 1.7)
            : MobileOnlyNotPWA
              ? pwaStyleAdapter(40, '%', 1.7)
              : MobileOnlyPWA
                ? pwaStyleAdapter(40, '%', 1.4)
                : '40%',
  },
  NoAssetLayoutModalContainerStyle: {
    height: isTablet
      ? '44vh'
      : MobileOnlyLLC && isStandalonePWA
        ? pwaStyleAdapter(38, 'vh', 1.5)
        : MobileOnlyLLC
          ? pwaStyleAdapter(40, 'vh', 1.5)
          : MobileOnlyNotPWA
            ? pwaStyleAdapter(30, 'vh', 1.4)
            : MobileOnlyPWA
              ? pwaStyleAdapter(30, 'vh', 1.5)
              : '30vh',
  },
};

export default Styles;

