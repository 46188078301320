import { useEffect } from 'react';
import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';

function useAutoScroll({ scrollRef, shouldScroll = false }) {
  useEffect(() => {
    if (scrollRef.current && shouldScroll && isNotStandaloneMobilePWA) {
      scrollRef.current.scrollTop = -scrollRef.current.scrollHeight - 50;
    }
  }, [scrollRef.current, shouldScroll, isNotStandaloneMobilePWA]);
}

export default useAutoScroll;

