import React from 'react';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import environmentVariables from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import styled from 'styled-components';
import Button from '../../components/Button/Button';

const BrowserScreen = ({ setShowBrowserMobileScreen }) => {
  const { BrowserScreen } = useThemeContext();

  return (
    <PageWrapper>
      <TitleWrap>
        <Title>{getText(TEXT_KEY.BROWSER_MOBILE_SCREEN_TITLE)}</Title>
        <Title>{`${getText(TEXT_KEY.BROWSER_MOBILE_SCREEN_SUBTITLE_PRE_FIX)} ${environmentVariables.APPLICATION_NAME.toLocaleUpperCase()} ${getText(TEXT_KEY.BROWSER_MOBILE_SCREEN_SUBTITLE_POST_FIX)}`}</Title>
      </TitleWrap>
      <DescriptionWrap theme={BrowserScreen.DescriptionWrap}>
        <DescriptionLine>
          <ShareIcon src={assetSource(ASSET_KEY.BROWSER_MOBILE_SCREEN_SHARED_ICON)} />
          <Description>{getText(TEXT_KEY.BROWSER_MOBILE_SCREEN_STEP_TAP_SHARE)}</Description>
        </DescriptionLine>
        <DescriptionLine>
          <AddIcon src={assetSource(ASSET_KEY.BROWSER_MOBILE_SCREEN_ADD_ICON)} />
          <Description>{getText(TEXT_KEY.BROWSER_MOBILE_SCREEN_STEP_TAP_ADD)}</Description>
        </DescriptionLine>
      </DescriptionWrap>
      <Button
        textStroke={BUTTON_STROKES.PRIMARY}
        imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
        onClick={() => setShowBrowserMobileScreen(false)}
        label={getText(TEXT_KEY.CONTINUE_BUTTON)}
        containerStyle={{
          width: '18rem',
          ...BrowserScreen.ButtonContainer,
        }}
        imageStyle={{ width: '18rem' }}
        textStyle={{ fontSize: '2.5rem' }}
      />
    </PageWrapper>
  );
};

export default BrowserScreen;

const PageWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url(${assetSource(ASSET_KEY.BROWSER_MOBILE_SCREEN_BACKGROUND)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 999;
  box-sizing: border-box;
  position: fixed;
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const DescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  ${({theme}) => theme}
`;

const Title = styled.p`
  width: 80%;
  white-space: pre-line;
  text-align: center;
  font-size: 3.5rem;
  font-weight: 600;
  margin: 0;
`;

const Description = styled.p`
  white-space: pre-line;
  font-size: 2.2rem;
  flex-wrap: wrap;
  margin: 0;
  font-weight: 500;
`;

const DescriptionLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
`;

const ShareIcon = styled.img`
  width: 3rem;
  height: 4rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const AddIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;