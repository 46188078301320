/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  LoyaltyPointsLogo,
  PriceWrapper,
  ButtonWrapper,
  ItemPlusOffer,
  VIPAccessWrapper,
  ItemPrice,
  PlusLoyaltyText,
  Styles,
  DurationFriendlyFull,
} from './styledComponents';
import ImageBW from '../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { purchaseActionTriggers } from 'shared/constants/userActions';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';
import LazyImage from '../../components/LazyImage/LazyImage';
import { TAG } from 'shared/constants';

const GREEN_BUTTON = generateRender({
  hri: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  default: assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY),
  millelacs: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  jamul: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  ladyluck: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
});

/** @typedef {import('shared/api/Constants').BoostPackageItem} BP */

/** @param {BP} item */
export default function makeVIPItem(item, index, buyHandle, hasVIPPass, unlimitedVipAccess, themeContext) {
  const headerImg = [
    assetSource(ASSET_KEY.VIP_HOUR_TILE_1),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_2),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_3),
  ];

  const logos = [
    assetSource(ASSET_KEY.VIP_HOUR_TILE_1STAR),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_2STARS),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_3STARS),
  ];

  const actionButton = unlimitedVipAccess ? assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED) : GREEN_BUTTON;
  const buttonTextStroke = unlimitedVipAccess ? BUTTON_STROKES.DISABLED : BUTTON_STROKES.GREEN;

  return (
    <ImageBW
      source={headerImg[index]}
      ibw={require(asset`images/coin-store-assets/vip-tiles/vip-hour-tile.ibw`)}
      manualAssetScale={3}
      key={'vip-' + item.item_id}
      style={themeContext.VIPItem.VIPHourTile}
      className="vipHourTile"
      innerStyle={Styles.tileInnerStyle}
    >
      <LazyImage tag={TAG.IMG} src={logos[index]} style={themeContext.VIPItem.LogoImage} />
      <DurationFriendlyFull>{item.duration_friendly_full}</DurationFriendlyFull>
      <VIPAccessWrapper>{`${getText(TEXT_KEY.VIP)} ${getText(TEXT_KEY.ACCESS)}`}</VIPAccessWrapper>

      <ItemPlusOffer theme={themeContext.VIPItem.ItemPlusOffer}>
        <PlusLoyaltyText theme={themeContext.VIPItem.PlusLoyaltyText}>{getText(TEXT_KEY.PLUS_SYMBOL)}</PlusLoyaltyText>
        <LoyaltyPointsLogo theme={themeContext.VIPItem.LoyaltyPointsLogo} src={assetSource(ASSET_KEY.LOYALTY_ICON)} />
        <PlusLoyaltyText theme={themeContext.VIPItem.PlusLoyaltyText}>
          {item.bonus_loyalty_points || '0'}
        </PlusLoyaltyText>
      </ItemPlusOffer>
      <PriceWrapper>
        <ItemPrice>{`${getText(TEXT_KEY.CURRENCY_SIGN)}${item.price}`}</ItemPrice>
      </PriceWrapper>
      <ButtonWrapper>
        <Button
          imageSource={actionButton}
          imageStyle={Styles.buyButtonImage}
          containerStyle={Styles.buyButtonStyle}
          objectFit="contain"
          textStyle={Styles.buyButtonText}
          textStroke={buttonTextStroke}
          label={getText(TEXT_KEY.BUY)}
          onClick={() =>
            unlimitedVipAccess
              ? hasVIPPass()
              : buyHandle(item.item_id, item.name, 'vip_room_access', item.price, purchaseActionTriggers.STORE_VIP_ITEM)
          }
        />
      </ButtonWrapper>
    </ImageBW>
  );
}

