import React from 'react';
import { connect } from 'shared/node_modules/react-redux';
import DefaultLinkAccount from './DefaultLinkAccount';
import generateRender from 'shared/utils/generateRender';

const LinkAccount = props => {
  const { googleAuth, googleToken } = props;

  const LinkAccountComponent = generateRender({
    default: DefaultLinkAccount,
  });

  return <LinkAccountComponent googleAuth={googleAuth} googleToken={googleToken} />;
};

const mapStateToProps = state => {
  return {
    googleToken: state.token.google_token,
    googleAuth: state.availableServices.googleAuth,
  };
};

export default connect(mapStateToProps)(LinkAccount);

