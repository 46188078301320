import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';

const findRewardContentStyleDefault = (rewardValue, hasMysteryRewardTypeNoRevealed) => {
  let iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.5 : 0.7;
  const dynamicStyle = {
    fontSize: isNotStandaloneMobilePWA ? '2vh' : '2.5vh',
    marginBottom: '4vh',
    lineHeight: '2.5vh',
    winFontSize: hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '2.5vh'
        : '3vh'
      : isNotStandaloneMobilePWA
        ? '3vh'
        : '3.4vh',
    winMarginBottom: '7%',
  };
  const textLength = rewardValue?.length || 0;

  if (textLength < 25) {
    iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.3 : 0.5;
    dynamicStyle.marginBottom = '4.5vh';
  }

  if (textLength < 23) {
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '3.3vh'
        : '3.8vh'
      : isNotStandaloneMobilePWA
        ? '4vh'
        : '4.5vh';
    dynamicStyle.winMarginBottom = '8%';
  }

  if (textLength < 20) {
    dynamicStyle.fontSize = isNotStandaloneMobilePWA ? '2.5vh' : '3vh';
    dynamicStyle.lineHeight = '4vh';
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '3vh'
        : '3.5vh'
      : isNotStandaloneMobilePWA
        ? '3.7vh'
        : '4.2vh';
    dynamicStyle.winMarginBottom = '6%';
    iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.5 : 0.7;
  }

  if (textLength < 13) {
    dynamicStyle.fontSize = isNotStandaloneMobilePWA ? '4vh' : '4.5vh';
    dynamicStyle.marginBottom = '4vh';
    dynamicStyle.lineHeight = '5vh';
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '3.3vh'
        : '3.8vh'
      : isNotStandaloneMobilePWA
        ? '4vh'
        : '4.5vh';
    dynamicStyle.winMarginBottom = '5%';
    iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.7 : 1;
  }

  if (textLength === 0) {
    dynamicStyle.winMarginBottom = '7%';
  }

  return {
    dynamicStyle,
    iconSizeMultiplier,
  };
};

export default findRewardContentStyleDefault;

