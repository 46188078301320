import styled, { css } from 'styled-components';

export const headerContainerStyle = css`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

export const wrapperStyle = css`
  width: calc(100% + 2rem);
  left: -1rem;
  top: -1rem;
`;

export const titleStyle = css`
  font-size: 3vmin;
`;

export const headerTitleWrapperStyle = css`
  margin-bottom: 1vmin;
  margin-top: -0.5vmin;
  font-size: 4vmin;
  font-weight: 600;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: calc(100% - 4%);
  width: calc(100% - 4%);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 15%;
`;

export const ErrorMessageText = styled.span`
  font-weight: 500;
  text-align: center;
`;

export const styles = {
  buyNowButtonContainer: {
    margin: '1rem 0px',
  },
  buttonImage: {
    width: '25%',
  },
  okButtonImage: {
    width: '35%',
  },
};

