import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useEffect, useState, useRef } from 'react';
function useResendAndTimer(timerSeconds) {
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    timeLeft = _useState2[0],
    updateTimer = _useState2[1];
  var timerIntervalRef = useRef(null);
  var triggerInterval = function triggerInterval() {
    if (timeLeft > 0) return;
    updateTimer(timerSeconds);
    clearInterval(timerIntervalRef.current);
    timerIntervalRef.current = setInterval(function () {
      updateTimer(function (prevTimeLeft) {
        if (prevTimeLeft <= 1) {
          clearInterval(timerIntervalRef.current);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);
  };
  useEffect(function () {
    return function () {
      return clearInterval(timerIntervalRef.current);
    };
  }, []);
  return {
    timeLeft: timeLeft,
    triggerInterval: triggerInterval
  };
}
export default useResendAndTimer;