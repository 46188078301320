import React, { useRef } from 'react';
import { DAILY_WHEEL_ASSUMTIONS } from '../../utils';
import StreakDaysRow from './StreakDaysRow/';
import StreakMilestone from './StreakMilestone/StreakMilestone';
import {
  StreakColumnContainer,
  StreakColumnContentWrapper,
  StreakColumnHolderSpace,
  StreakColumnBackground,
  StreakColumnHolder,
  DaysStreakContainer,
  StreakColumnInfoIcon,
} from '../styledComponents';
import { useSelector } from 'shared/node_modules/react-redux';
import useThemeContext from 'shared/context/hooks/useThemeContext';

export default function StreakColumn(props) {
  const dailyWheelAssets = useSelector(state => state.assetsPackage.assets.DAILY_WHEEL);
  const { DailySpin } = useThemeContext();
  const scrollRef = useRef();
  const { streakAnimDeg, bottomAnimDeg, loginStreakFinal, loginStreakList, bonusInfo, toggleInfoModal, enableLoyalty } =
    props;
  const { dailyListWidth, dailyWidthUnit } = DAILY_WHEEL_ASSUMTIONS;
  const wheelHero = bonusInfo?.WHEEL_HERO;

  return (
    <StreakColumnContainer
      hero={wheelHero ? 1 : 0}
      width={dailyListWidth}
      unit={dailyWidthUnit}
      style={{
        transform: [
          {
            translateX: streakAnimDeg,
          },
          {
            translateY: bottomAnimDeg,
          },
        ],
      }}
    >
      <StreakColumnContentWrapper theme={DailySpin.StreakColumnWrapper}>
        <StreakColumnBackground src={dailyWheelAssets.DAILY_SPIN_COLUMN_BACKGROUND} />
        <StreakColumnInfoIcon onClick={toggleInfoModal} src={dailyWheelAssets.DAILY_SPIN_COLUMN_INFO_ICON} />
        <StreakColumnHolder>
          <StreakMilestone
            dailyWheelAssets={dailyWheelAssets}
            wheelHero={wheelHero}
            loginStreakFinal={loginStreakFinal}
            enableLoyalty={enableLoyalty}
          />
          <StreakColumnHolderSpace />
          <DaysStreakContainer smallScreenTheme={DailySpin.DaysStreakContainerSmallScreen} ref={scrollRef}>
            {loginStreakList?.map((item, index) => {
              return (
                <StreakDaysRow
                  scrollRef={scrollRef}
                  dailyWheelAssets={dailyWheelAssets}
                  key={`login-bonus-item-${index}`}
                  item={item}
                  index={index}
                  day={item?.day}
                  bonusInfo={bonusInfo}
                  enableLoyalty={enableLoyalty}
                />
              );
            })}
          </DaysStreakContainer>
        </StreakColumnHolder>
      </StreakColumnContentWrapper>
    </StreakColumnContainer>
  );
}

