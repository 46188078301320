import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { useSelector } from 'shared/node_modules/react-redux';
import ThemeContext from 'shared/context/ThemeContext';
import { BottomLeftPartIcon, BottomBarPlusText, BottomBarStyles } from '../../styledComponents';
export default function LeftSideSmallScreen(props) {
  const showLinkLoyaltyCardSetting = useSelector(state => state.properties.data.showLinkLoyaltyCardSetting);
  const themeContext = useContext(ThemeContext);

  const {
    dailyWheelAssets,
    step,
    loyaltyCardComingSoon,
    wheelBonus,
    dailyBonus,
    linkedAccount,
    currentTierBonus,
    cardIconPath,
  } = props;
  return (
    <React.Fragment>
      <IconWrapper
        step={step}
        type={iconTypes.WHEEL_BONUS}
        style={
          showLinkLoyaltyCardSetting
            ? themeContext.DailySpin.BottomBarLeftContainerWithCard
            : themeContext.DailySpin.BottomBarLeftContainerNoCard
        }
      >
        <ItemDetailsWrapper>
          <BonusAmount data-label={wheelBonus}>{wheelBonus}</BonusAmount>
          <BonusInfo
            style={
              showLinkLoyaltyCardSetting
                ? themeContext.DailySpin.BottomBarLeftContainerWithCardText
                : themeContext.DailySpin.BottomBarLeftContainerNoCardText
            }
            data-label="WHEEL BONUS"
          >
            {getText(TEXT_KEY.WHEEL_BONUS)}
          </BonusInfo>
        </ItemDetailsWrapper>
        <BottomLeftPartIcon src={dailyWheelAssets.DAILY_SPIN_WHEEL_ICON} alt="Small Wheel" />
      </IconWrapper>
      <BottomBarPlusText>{getText(TEXT_KEY.PLUS_SYMBOL)}</BottomBarPlusText>
      <IconWrapper
        step={step}
        type={iconTypes.DAILY_BONUS}
        style={
          showLinkLoyaltyCardSetting
            ? themeContext.DailySpin.BottomBarLeftContainerWithCard
            : themeContext.DailySpin.BottomBarLeftContainerNoCard
        }
      >
        <ItemDetailsWrapper>
          <BonusAmount data-label={dailyBonus}>{dailyBonus}</BonusAmount>
          <BonusInfo
            style={
              showLinkLoyaltyCardSetting
                ? themeContext.DailySpin.BottomBarLeftContainerWithCardText
                : themeContext.DailySpin.BottomBarLeftContainerNoCardText
            }
            data-label="DAILY BONUS"
          >
            {getText(TEXT_KEY.DAILY_BONUS)}
          </BonusInfo>
        </ItemDetailsWrapper>
        <BottomLeftPartIcon src={dailyWheelAssets.DAILY_SPIN_CALENDAR_ICON} alt="Calendar" />
      </IconWrapper>
      {showLinkLoyaltyCardSetting && (
        <>
          <BottomBarPlusText>{getText(TEXT_KEY.PLUS_SYMBOL)}</BottomBarPlusText>
          <IconWrapper step={step} type={iconTypes.TIER_BONUS}>
            <ItemDetailsWrapper>
              <BonusAmount
                style={BottomBarStyles.comingSoonCardtext}
                data-label={
                  loyaltyCardComingSoon
                    ? `${getText(TEXT_KEY.COMING_SOON_TITLE)}`
                    : linkedAccount
                      ? `${currentTierBonus}%`
                      : '-'
                }
              >
                {loyaltyCardComingSoon
                  ? `${getText(TEXT_KEY.COMING_SOON_TITLE)}`
                  : linkedAccount
                    ? `${currentTierBonus}%`
                    : '-'}
              </BonusAmount>
              {!loyaltyCardComingSoon && (
                <BonusInfo unlinked={!linkedAccount} data-label="TIER BONUS">
                  {linkedAccount ? getText(TEXT_KEY.TIER_BONUS) : getText(TEXT_KEY.TIER_BONUS_NOT_LINKED)}
                </BonusInfo>
              )}
            </ItemDetailsWrapper>
            <BottomLeftPartIcon src={cardIconPath ? cardIconPath : dailyWheelAssets.DAILY_SPIN_CARDS_ICON} alt="Card" />
          </IconWrapper>
        </>
      )}
    </React.Fragment>
  );
}

const WheelBonusAnimation = css`
  animation-duration: 1500ms;
  animation-name: wheelBonusZoom;
  transform: scale(1.1);

  @keyframes wheelBonusZoom {
    0% {
      transform: scale(0.8);
    }
    66% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.1);
    }
  }
`;

const DailyBonusAnimation = css`
  animation-duration: 2000ms;
  animation-name: dailyBonusZoom;
  transform: scale(1.1);

  @keyframes dailyBonusZoom {
    0% {
      transform: scale(0.8);
    }
    75% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.1);
    }
  }
`;

const TierBonusAnimation = css`
  animation-duration: 2500ms;
  animation-name: tierBonusZoom;
  transform: scale(1.1);

  @keyframes tierBonusZoom {
    0% {
      transform: scale(0.8);
    }
    80% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.1);
    }
  }
`;

const iconTypes = {
  WHEEL_BONUS: 'WHEEL_BONUS',
  DAILY_BONUS: 'DAILY_BONUS',
  TIER_BONUS: 'TIER_BONUS',
};

const IconAnimation = {
  WHEEL_BONUS: WheelBonusAnimation,
  DAILY_BONUS: DailyBonusAnimation,
  TIER_BONUS: TierBonusAnimation,
};

const BonusContainerSmall = css`
  transform: scale(0.8);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1rem;
  height: 100%;
  max-width: 200px;
  line-height: 1.2;
  ${({ step, type }) => (step === 2 ? IconAnimation[type] : BonusContainerSmall)}
`;

const ItemDetailsWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 5px;
  margin-right: 0.5rem;
  width: 6rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`;

const BonusAmount = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
`;

const tierNotLinked = css`
  font-size: 0.8rem;
  font-weight: 400;
`;

const BonusInfo = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  ${({ unlinked }) => unlinked && tierNotLinked}
`;

