import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useRef } from 'react';
export var battlesMockDataAssets = {
  BATTLES_BATTLE_GOAL_BOX: require('./assets/battle-goal-box.png'),
  BATTLES_BACKGROUND_MAIN: require('./assets/battles-background-main.png'),
  BATTLES_BACKGROUND_SPLASH: require('./assets/battles-background-splash.png'),
  BATTLES_CURRENT_INFO: require('./assets/battles-current-info.png'),
  BATTLES_GRAND_REWARD_POPUP: require('./assets/battles-grand-reward-popup.png'),
  BATTLES_INFO_BOX_ICON: require('./assets/battles-info-box-icon.png'),
  BATTLES_INFO_BOX: require('./assets/battles-info-box.png'),
  BATTLES_INFO_SCREEN_1: require('./assets/battles-info-screen-1.png'),
  BATTLES_INFO_SCREEN_2: require('./assets/battles-info-screen-2.png'),
  BATTLES_INFO_SCREEN_3: require('./assets/battles-info-screen-3.png'),
  BATTLES_INFO_SCREEN_4: require('./assets/battles-info-screen-4.png'),
  BATTLES_LEADERBOARD: require('./assets/battles-leaderboard.png'),
  BATTLES_SPLASH_LOGO: require('./assets/battles-splash-logo.png'),
  BATTLES_STREAK_INFO_2: require('./assets/battles-streak-info-2.png'),
  BATTLES_STREAK_INFO: require('./assets/battles-streak-info.png'),
  BATTLES_TOOLTIP_1: require('./assets/battles-tooltip-1.png'),
  BATTLES_TOOLTIP_2: require('./assets/battles-tooltip-2.png'),
  BATTLES_TOOLTIP_3: require('./assets/battles-tooltip-3.png'),
  BATTLES_INFO_BUTTON_BATTLES: require('./assets/info-button-battles.png'),
  BATTLES_NO_BATTLES: require('./assets/no-battles.png'),
  BATTLES_NOTIFICATION_ICON: require('./assets/notification-icon.png'),
  BATTLES_POINTS_ICON_BATTLES_LARGE: require('./assets/points-icon-battles-large.png'),
  BATTLES_POINTS_ICON_BATTLES_SMALL: require('./assets/points-icon-battles-small.png'),
  BATTLES_TABS_COUNTDOWN_REGULAR: require('./assets/tabs-battles-countdown-regular.png'),
  BATTLES_TABS_DISABLED: require('./assets/tabs-battles-disabled.png'),
  BATTLES_TABS_DOWN: require('./assets/tabs-battles-down.png'),
  BATTLES_TABS_EXPLORER_REGULAR: require('./assets/tabs-battles-explorer-regular.png'),
  BATTLES_TABS_REGULAR: require('./assets/tabs-battles-regular.png'),
  BATTLES_TABS_EXPLORER_DISABLED: require('./assets/tabs-explorer-disabled.png'),
  BATTLES_TABS_EXPLORER_DOWN: require('./assets/tabs-explorer-down.png'),
  BATTLES_TOP_BAR_BATTLES_ICON: require('./assets/top-bar-battles-icon.png'),
  BATTLES_TOP_BAR_BATTLES_TIMER_BACKING: require('./assets/top-bar-battles-timer-backing.png'),
  BATTLES_TOP_BAR_COLLECT_DISABLED: require('./assets/top-bar-collect-disabled.png'),
  BATTLES_TOP_BAR_COLLECT_REGULAR: require('./assets/top-bar-collect-regular.png'),
  BATTLES_TOP_BAR_LEADERBOARD_BACKING: require('./assets/top-bar-leaderboard-backing.png'),
  BATTLES_TOP_BAR_TIMER_CLOCK_ICON: require('./assets/top-bar-timer-clock-icon.svg'),
  BATTLES_TOOLTIP: require('./assets/battles-tooltip.png')
};

/**
 * Represents the battle progress.
 *
 * @typedef {Object} BattleProgress
 * @property {number} rank - The rank of the progress.
 * @property {number} points - The accumulated points.
 * @property {string} progress - The progress as a percentage string.
 */

/**
 * Represents the challenge details for the battles feature.
 *
 * @typedef {Object} BattlesFeatureChallenge
 * @property {string} goalText - The description of the challenge goal.
 * @property {number} goalAmout - The numeric value representing the challenge goal.
 * @property {Object} progress - The progress details of the challenge.
 * @property {BattleProgress} progress.current - The current progress details.
 * @property {BattleProgress} progress.streak - The streak progress details.
 */

/**
 * Represents the battles feature.
 *
 * @typedef {Object} BattlesFeature
 * @property {number} battlesFeatureCountdown - The countdown timer value for the feature.
 * @property {BattlesFeatureChallenge} battlesFeatureChallenge - The challenge details.
 */

/**
 * Represents a player.
 *
 * @typedef {Object} Player
 * @property {number} id - The unique identifier of the player.
 * @property {string} name - The first name of the player.
 * @property {string} lastName - The last name or initial of the player.
 * @property {number} points - The accumulated points of the player.
 * @property {string} progress - The player's progress as a percentage string.
 */

/**
 * Represents the texts used in the battles feature.
 *
 * @typedef {Object} BattlesFeatureTexts
 * @property {string} finishMissions - Text shown when a mission needs to be completed.
 * @property {string} finishBattles - Text shown when a battle needs to be completed.
 * @property {string} countdownBattles - Countdown text until the battle begins.
 */

/**
 * Represents the state of the battles feature.
 *
 * @typedef {Object} BattlesFeatureState
 * @property {BattlesFeature} battlesFeature - The details of the battles feature.
 * @property {Player[]} players - An array of player objects.
 * @property {BattlesFeatureTexts} texts - An object containing texts for the feature.
 */

/**
 * Mock object representing the state of the battles feature.
 *
 * @type {{ battlesFeatureState: BattlesFeatureState }}
 */
export var battlesMockFeatureState = {
  battlesFeatureState: {
    battlesFeature: {
      battlesFeatureCountdown: 60,
      battlesFeatureChallenge: {
        goalText: 'spin 10 times and win',
        goalAmout: 1234567890,
        progress: {
          current: {
            rank: 1,
            points: 15,
            progress: '50%'
          },
          streak: {
            rank: 1,
            points: 20,
            progress: '70%'
          }
        }
      }
    },
    players: [{
      id: 0,
      name: 'You',
      lastName: '',
      points: 0,
      progress: '0%'
    }, {
      id: 1,
      name: 'Marilyn',
      lastName: 'Y',
      points: 0,
      progress: '0%'
    }, {
      id: 2,
      name: 'Emily',
      lastName: 'M',
      points: 0,
      progress: '0%'
    }, {
      id: 3,
      name: 'Hazen',
      lastName: 'P',
      points: 0,
      progress: '0%'
    }, {
      id: 4,
      name: 'Chris',
      lastName: 'C',
      points: 0,
      progress: '0%'
    }, {
      id: 5,
      name: 'Gabriel',
      lastName: 'J',
      points: 0,
      progress: '0%'
    }, {
      id: 6,
      name: 'Mark',
      lastName: 'S',
      points: 0,
      progress: '0%'
    }, {
      id: 7,
      name: 'Maria',
      lastName: 'R',
      points: 0,
      progress: '0%'
    }, {
      id: 8,
      name: 'Jaseng',
      lastName: 'T',
      points: 0,
      progress: '0%'
    }],
    texts: {
      finisihMissions: 'First, you must finish the Mission that you started!',
      finishBattles: 'First, you must finish the Battle that you started!',
      countdownBattles: 'COUNTDOWN UNTIL BATTLE BEGINS!'
    }
  }
};
export var useBattlesPlayers = function useBattlesPlayers(_ref) {
  var players = _ref.players,
    setPlayers = _ref.setPlayers,
    battlesStarted = _ref.battlesStarted,
    isPlayerJoining = _ref.isPlayerJoining,
    countdown = _ref.countdown;
  var intervalRef = useRef(null);
  useEffect(function () {
    if (!battlesStarted) return;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (isPlayerJoining) {
      var intervalTime = countdown * 1000 / players.length;
      intervalRef.current = setInterval(function () {
        setPlayers(function (prevPlayers) {
          var nextPlayerIndex = prevPlayers.findIndex(function (player) {
            return !player.hasJoined;
          });
          if (nextPlayerIndex === -1) {
            clearInterval(intervalRef.current);
            return prevPlayers;
          }
          // Update only the next player that hasn't joined
          var updatedPlayers = _toConsumableArray(prevPlayers);
          updatedPlayers[nextPlayerIndex] = _objectSpread(_objectSpread({}, updatedPlayers[nextPlayerIndex]), {}, {
            hasJoined: true
          });
          return updatedPlayers;
        });
      }, intervalTime);
    } else {
      intervalRef.current = setInterval(function () {
        setPlayers(function (prevPlayers) {
          return prevPlayers.map(function (player) {
            var randomPoints = Math.floor(Math.random() * 10) + 1;
            return _objectSpread(_objectSpread({}, player), {}, {
              points: randomPoints,
              progress: "".concat(randomPoints * 10, "%")
            });
          }).sort(function (a, b) {
            return b.points - a.points;
          });
        });
      }, 5000);
    }
    return function () {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [battlesStarted, isPlayerJoining, countdown, players.length, setPlayers]);
};