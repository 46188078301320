import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPromoActionRunning } from '../state/slices/actionCommands/actionCommands';
function useClearRunningPromoAction(_ref) {
  var promoType = _ref.promoType;
  var dispatch = useDispatch();
  var isPrpmoActionRunning = useSelector(function (state) {
    return state.actionCommands.promoActionRunning === promoType;
  });
  useEffect(function () {
    if (isPrpmoActionRunning) dispatch(setPromoActionRunning(null));
  }, [isPrpmoActionRunning, dispatch]);
}
export default useClearRunningPromoAction;