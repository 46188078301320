import { useEffect } from 'react';
import { setTarget } from 'shared/state/actions/app';
import { useDispatch } from 'shared/node_modules/react-redux';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

function useSetAppTarget() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTarget(ENVIRONMENT_VARIABLES.APPLICATION_TARGET));
  }, []);
}

export default useSetAppTarget;

