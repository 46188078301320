import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { EVENT_EMITTER_TYPE } from '../../../../constants';
import Emitter from '../../../../services/EmitterService';
import utils from '../../../../utils';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_FEATURE_GROUP, ACTION_COMMANDS_TOOLTIP_TYPE, ACTION_COMMANDS_TYPE } from '../../constants';
import { EVENTS_ACTIONS } from '../../../../screens/Events/constants/dictionary';
import { EVENTS_EVENT_TYPES } from '../../../../screens/Events/constants';
import { sendEventsEvent } from '../../../../screens/Events/analytics/eventsLogger';
var setTooltipCommands = function setTooltipCommands(actionCommand) {
  var _actionCommand$toolti, _actionCommand$toolti2, _actionCommand$toolti3, _actionCommand$payloa;
  var uuid = actionCommand.uuid;
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
    data: {
      event: ACTION_COMMANDS_EVENT_TYPE.DISPLAY_APP_TOOLTIP,
      modalType: actionCommand === null || actionCommand === void 0 || (_actionCommand$toolti = actionCommand.tooltip) === null || _actionCommand$toolti === void 0 ? void 0 : _actionCommand$toolti.tooltipType,
      triggerExecution: actionCommand === null || actionCommand === void 0 || (_actionCommand$toolti2 = actionCommand.tooltip) === null || _actionCommand$toolti2 === void 0 || (_actionCommand$toolti2 = _actionCommand$toolti2.onDisplayAction) === null || _actionCommand$toolti2 === void 0 ? void 0 : _actionCommand$toolti2.triggerExecution,
      payload: actionCommand === null || actionCommand === void 0 || (_actionCommand$toolti3 = actionCommand.tooltip) === null || _actionCommand$toolti3 === void 0 || (_actionCommand$toolti3 = _actionCommand$toolti3.onDisplayAction) === null || _actionCommand$toolti3 === void 0 ? void 0 : _actionCommand$toolti3.payload,
      isFtueFlow: (actionCommand === null || actionCommand === void 0 || (_actionCommand$payloa = actionCommand.payload) === null || _actionCommand$payloa === void 0 ? void 0 : _actionCommand$payloa.featureGroup) === ACTION_COMMANDS_FEATURE_GROUP.FTUE
    }
  });
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND,
    data: {
      uuid: uuid
    }
  });
};
var notifyEventsTooltip = function notifyEventsTooltip(actionCommand, props) {
  var uuid = actionCommand.uuid;
  sendEventsEvent(EVENTS_EVENT_TYPES.DISPLAY_EVENTS_TOOLTIP);
  props.postEvents({
    action: EVENTS_ACTIONS.NOTIFY_EVENT_TOOLTIP_DISPLAYED,
    eventId: actionCommand.tooltip.onDisplayAction.eventId,
    tooltipType: actionCommand.tooltip.tooltipType
  });
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND,
    data: {
      uuid: uuid
    }
  });
};
var tooltipHandlers = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ACTION_COMMANDS_TOOLTIP_TYPE.CARD_LINK, setTooltipCommands), ACTION_COMMANDS_TOOLTIP_TYPE.REWARD_CENTER, setTooltipCommands), ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_ABOUT_TO_END, notifyEventsTooltip), ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_COMPLETED, notifyEventsTooltip), ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_EXPIRED, notifyEventsTooltip);
export var handleTooltip = function handleTooltip(details, props, actionCommand) {
  props.setTooltipDetails(details);
  props.shouldShowTooltip(true);
  setTimeout(function () {
    var out = _objectSpread(_objectSpread({}, details), {}, {
      animationState: utils.isWebOrMobileWeb() ? 'AnimationFadeOut' : 'out'
    });
    props.setTooltipDetails(out);
  }, 3500);
  setTimeout(function () {
    props.shouldShowTooltip(false);
    tooltipHandlers[actionCommand.tooltip.tooltipType](actionCommand, props);
  }, 4500);
};