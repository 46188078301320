import React from 'react';
import { isTablet } from 'react-device-detect';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import GradientLabel from './components/GradientLabel';
import { isIPadMini, pwaStyleAdapter } from '../../utils/mobileSupported';

const Button = props => {
  const {
    containerStyle = {},
    imageStyle = {},
    label,
    imageSource,
    onClick,
    objectFit,
    textStyle,
    disabled,
    isDebounce,
    textStroke,
    buttonIcon,
    buttonIconStyle,
    buttonWrapperStyle,
    children,
    gradientLabel = false,
    gradentLabelTextStyle = {},
    display = 'flex',
  } = props;

  const onClickHandler = debounce(() => onClick(), isDebounce ? 500 : 0, { leading: true, trailing: false });

  const strokeColor = disabled ? BUTTON_STROKES.DISABLED : textStroke || BUTTON_STROKES.DISABLED;

  return (
    <ButtonWrapper
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
        return !disabled ? onClickHandler() : null;
      }}
      containerStyle={containerStyle}
      display={display}
    >
      <ButtonImage
        imageSource={imageSource ?? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
        imageStyle={imageStyle}
        objectFit={objectFit}
      />
      <ButtonContentWrapper style={buttonWrapperStyle} hasChildren={!!children}>
        {buttonIcon && <ButtonIcon src={buttonIcon} buttonIconStyle={buttonIconStyle} />}
        {!gradientLabel ? (
          <ButtonText textStroke={strokeColor} textStyle={textStyle}>
            {label}
          </ButtonText>
        ) : (
          <GradientLabel gradentLabelTextStyle={gradentLabelTextStyle} label={label} />
        )}
        {children}
      </ButtonContentWrapper>
    </ButtonWrapper>
  );
};

export default Button;

const ButtonWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 180px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: ${({ display }) => display};
  ${({ containerStyle }) => containerStyle}
`;

const ButtonImage = styled.img.attrs(({ imageSource }) => ({
  src: imageSource,
}))`
  position: absolute;
  width: 180px;
  object-fit: ${({ objectFit }) => objectFit || 'contain'};
  z-index: 1;
  ${({ imageStyle }) => imageStyle}
`;

const ButtonContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ hasChildren }) => (hasChildren ? 'column' : 'row')};
`;

const ButtonIcon = styled.img`
  ${({ buttonIconStyle }) => buttonIconStyle};
  z-index: 1;
`;

const ButtonText = styled.p`
  font-size: ${isTablet ? pwaStyleAdapter(isIPadMini ? 24 : 20, 'px', 1.5) : '24px'};
  font-weight: 700;
  margin: 0 auto !important;
  z-index: 2;
  color: ${theme.palette.common[1]};
  text-stroke: 1px ${({ textStroke }) => textStroke};
  -webkit-text-stroke: 1px ${({ textStroke }) => textStroke};
  padding-bottom: 3%;
  ${({ textStyle }) => textStyle}
`;

