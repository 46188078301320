import { getText, TEXT_KEY } from '../localization';
import generateRender from '../generateRender';
export var KYC_STATUS = {
  UNSET: '',
  PASS: 'Pass',
  FAILED: 'Fail'
};
export var DOCUMENT_TYPES = [{
  label: 'Driver License',
  value: 'dl'
}, {
  label: 'Passport',
  value: 'pp'
}];
export var DRIVER_LICENSE_STATE = [{
  label: 'NSW',
  value: 'dl_nsw'
}, {
  label: 'QLD',
  value: 'dl_qld'
}, {
  label: 'VIC',
  value: 'dl_vic'
}, {
  label: 'ACT',
  value: 'dl_act'
}, {
  label: 'NT',
  value: 'dl_nt'
}, {
  label: 'SA',
  value: 'dl_sa'
}, {
  label: 'TAS',
  value: 'dl_tas'
}, {
  label: 'WA',
  value: 'dl_wa'
}];
export var DRIVER_LICENSE_STATE_PAYLOAD = {
  dl_nsw: {
    type: 'dl',
    country: 'AU',
    value: 'NSW'
  },
  dl_qld: {
    type: 'dl',
    country: 'AU',
    value: 'QLD'
  },
  dl_vic: {
    type: 'dl',
    country: 'AU',
    value: 'VIC'
  },
  dl_act: {
    type: 'dl',
    country: 'AU',
    value: 'ACT'
  },
  dl_nt: {
    type: 'dl',
    country: 'AU',
    value: 'NT'
  },
  dl_sa: {
    type: 'dl',
    country: 'AU',
    value: 'SA'
  },
  dl_tas: {
    type: 'dl',
    country: 'AU',
    value: 'TAS'
  },
  dl_wa: {
    type: 'dl',
    country: 'AU',
    value: 'WA'
  }
};
export var PASSPORT_COUNTRIES = [{
  value: 'AU',
  label: 'Australia'
}, {
  value: 'AF',
  label: 'Afghanistan'
}, {
  value: 'AX',
  label: "\xC5land Islands"
}, {
  value: 'AL',
  label: 'Albania'
}, {
  value: 'DZ',
  label: 'Algeria'
}, {
  value: 'AS',
  label: 'American Samoa'
}, {
  value: 'AD',
  label: 'Andorra'
}, {
  value: 'AO',
  label: 'Angola'
}, {
  value: 'AI',
  label: 'Anguilla'
}, {
  value: 'AQ',
  label: 'Antarctica'
}, {
  value: 'AG',
  label: 'Antigua and Barbuda'
}, {
  value: 'AR',
  label: 'Argentina'
}, {
  value: 'AM',
  label: 'Armenia'
}, {
  value: 'AW',
  label: 'Aruba'
}, {
  value: 'AT',
  label: 'Austria'
}, {
  value: 'AZ',
  label: 'Azerbaijan'
}, {
  value: 'BS',
  label: 'Bahamas'
}, {
  value: 'BH',
  label: 'Bahrain'
}, {
  value: 'BD',
  label: 'Bangladesh'
}, {
  value: 'BB',
  label: 'Barbados'
}, {
  value: 'BY',
  label: 'Belarus'
}, {
  value: 'BE',
  label: 'Belgium'
}, {
  value: 'BZ',
  label: 'Belize'
}, {
  value: 'BJ',
  label: 'Benin'
}, {
  value: 'BM',
  label: 'Bermuda'
}, {
  value: 'BT',
  label: 'Bhutan'
}, {
  value: 'BO',
  label: 'Bolivia, Plurinational State of'
}, {
  value: 'BQ',
  label: 'Bonaire, Sint Eustatius and Saba'
}, {
  value: 'BA',
  label: 'Bosnia and Herzegovina'
}, {
  value: 'BW',
  label: 'Botswana'
}, {
  value: 'BV',
  label: 'Bouvet Island'
}, {
  value: 'BR',
  label: 'Brazil'
}, {
  value: 'IO',
  label: 'British Indian Ocean Territory'
}, {
  value: 'BN',
  label: 'Brunei Darussalam'
}, {
  value: 'BG',
  label: 'Bulgaria'
}, {
  value: 'BF',
  label: 'Burkina Faso'
}, {
  value: 'BI',
  label: 'Burundi'
}, {
  value: 'KH',
  label: 'Cambodia'
}, {
  value: 'CM',
  label: 'Cameroon'
}, {
  value: 'CA',
  label: 'Canada'
}, {
  value: 'CV',
  label: 'Cape Verde'
}, {
  value: 'KY',
  label: 'Cayman Islands'
}, {
  value: 'CF',
  label: 'Central African Republic'
}, {
  value: 'TD',
  label: 'Chad'
}, {
  value: 'CL',
  label: 'Chile'
}, {
  value: 'CN',
  label: 'China'
}, {
  value: 'CX',
  label: 'Christmas Island'
}, {
  value: 'CC',
  label: 'Cocos (Keeling) Islands'
}, {
  value: 'CO',
  label: 'Colombia'
}, {
  value: 'KM',
  label: 'Comoros'
}, {
  value: 'CG',
  label: 'Congo'
}, {
  value: 'CD',
  label: 'Congo, the Democratic Republic of the'
}, {
  value: 'CK',
  label: 'Cook Islands'
}, {
  value: 'CR',
  label: 'Costa Rica'
}, {
  value: 'CI',
  label: "C\xF4te d'Ivoire"
}, {
  value: 'HR',
  label: 'Croatia'
}, {
  value: 'CU',
  label: 'Cuba'
}, {
  value: 'CW',
  label: "Cura\xE7ao"
}, {
  value: 'CY',
  label: 'Cyprus'
}, {
  value: 'CZ',
  label: 'Czech Republic'
}, {
  value: 'DK',
  label: 'Denmark'
}, {
  value: 'DJ',
  label: 'Djibouti'
}, {
  value: 'DM',
  label: 'Dominica'
}, {
  value: 'DO',
  label: 'Dominican Republic'
}, {
  value: 'EC',
  label: 'Ecuador'
}, {
  value: 'EG',
  label: 'Egypt'
}, {
  value: 'SV',
  label: 'El Salvador'
}, {
  value: 'GQ',
  label: 'Equatorial Guinea'
}, {
  value: 'ER',
  label: 'Eritrea'
}, {
  value: 'EE',
  label: 'Estonia'
}, {
  value: 'ET',
  label: 'Ethiopia'
}, {
  value: 'FK',
  label: 'Falkland Islands (Malvinas)'
}, {
  value: 'FO',
  label: 'Faroe Islands'
}, {
  value: 'FJ',
  label: 'Fiji'
}, {
  value: 'FI',
  label: 'Finland'
}, {
  value: 'FR',
  label: 'France'
}, {
  value: 'GF',
  label: 'French Guiana'
}, {
  value: 'PF',
  label: 'French Polynesia'
}, {
  value: 'TF',
  label: 'French Southern Territories'
}, {
  value: 'GA',
  label: 'Gabon'
}, {
  value: 'GM',
  label: 'Gambia'
}, {
  value: 'GE',
  label: 'Georgia'
}, {
  value: 'DE',
  label: 'Germany'
}, {
  value: 'GH',
  label: 'Ghana'
}, {
  value: 'GI',
  label: 'Gibraltar'
}, {
  value: 'GR',
  label: 'Greece'
}, {
  value: 'GL',
  label: 'Greenland'
}, {
  value: 'GD',
  label: 'Grenada'
}, {
  value: 'GP',
  label: 'Guadeloupe'
}, {
  value: 'GU',
  label: 'Guam'
}, {
  value: 'GT',
  label: 'Guatemala'
}, {
  value: 'GG',
  label: 'Guernsey'
}, {
  value: 'GN',
  label: 'Guinea'
}, {
  value: 'GW',
  label: 'Guinea-Bissau'
}, {
  value: 'GY',
  label: 'Guyana'
}, {
  value: 'HT',
  label: 'Haiti'
}, {
  value: 'HM',
  label: 'Heard Island and McDonald Islands'
}, {
  value: 'VA',
  label: 'Holy See (Vatican City State)'
}, {
  value: 'HN',
  label: 'Honduras'
}, {
  value: 'HK',
  label: 'Hong Kong'
}, {
  value: 'HU',
  label: 'Hungary'
}, {
  value: 'IS',
  label: 'Iceland'
}, {
  value: 'IN',
  label: 'India'
}, {
  value: 'ID',
  label: 'Indonesia'
}, {
  value: 'IR',
  label: 'Iran, Islamic Republic of'
}, {
  value: 'IQ',
  label: 'Iraq'
}, {
  value: 'IE',
  label: 'Ireland'
}, {
  value: 'IM',
  label: 'Isle of Man'
}, {
  value: 'IL',
  label: 'Israel'
}, {
  value: 'IT',
  label: 'Italy'
}, {
  value: 'JM',
  label: 'Jamaica'
}, {
  value: 'JP',
  label: 'Japan'
}, {
  value: 'JE',
  label: 'Jersey'
}, {
  value: 'JO',
  label: 'Jordan'
}, {
  value: 'KZ',
  label: 'Kazakhstan'
}, {
  value: 'KE',
  label: 'Kenya'
}, {
  value: 'KI',
  label: 'Kiribati'
}, {
  value: 'KP',
  label: "Korea, Democratic People's Republic of"
}, {
  value: 'KR',
  label: 'Korea, Republic of'
}, {
  value: 'KW',
  label: 'Kuwait'
}, {
  value: 'KG',
  label: 'Kyrgyzstan'
}, {
  value: 'LA',
  label: "Lao People's Democratic Republic"
}, {
  value: 'LV',
  label: 'Latvia'
}, {
  value: 'LB',
  label: 'Lebanon'
}, {
  value: 'LS',
  label: 'Lesotho'
}, {
  value: 'LR',
  label: 'Liberia'
}, {
  value: 'LY',
  label: 'Libya'
}, {
  value: 'LI',
  label: 'Liechtenstein'
}, {
  value: 'LT',
  label: 'Lithuania'
}, {
  value: 'LU',
  label: 'Luxembourg'
}, {
  value: 'MO',
  label: 'Macao'
}, {
  value: 'MK',
  label: 'Macedonia, the Former Yugoslav Republic of'
}, {
  value: 'MG',
  label: 'Madagascar'
}, {
  value: 'MW',
  label: 'Malawi'
}, {
  value: 'MY',
  label: 'Malaysia'
}, {
  value: 'MV',
  label: 'Maldives'
}, {
  value: 'ML',
  label: 'Mali'
}, {
  value: 'MT',
  label: 'Malta'
}, {
  value: 'MH',
  label: 'Marshall Islands'
}, {
  value: 'MQ',
  label: 'Martinique'
}, {
  value: 'MR',
  label: 'Mauritania'
}, {
  value: 'MU',
  label: 'Mauritius'
}, {
  value: 'YT',
  label: 'Mayotte'
}, {
  value: 'MX',
  label: 'Mexico'
}, {
  value: 'FM',
  label: 'Micronesia, Federated States of'
}, {
  value: 'MD',
  label: 'Moldova, Republic of'
}, {
  value: 'MC',
  label: 'Monaco'
}, {
  value: 'MN',
  label: 'Mongolia'
}, {
  value: 'ME',
  label: 'Montenegro'
}, {
  value: 'MS',
  label: 'Montserrat'
}, {
  value: 'MA',
  label: 'Morocco'
}, {
  value: 'MZ',
  label: 'Mozambique'
}, {
  value: 'MM',
  label: 'Myanmar'
}, {
  value: 'NA',
  label: 'Namibia'
}, {
  value: 'NR',
  label: 'Nauru'
}, {
  value: 'NP',
  label: 'Nepal'
}, {
  value: 'NL',
  label: 'Netherlands'
}, {
  value: 'NC',
  label: 'New Caledonia'
}, {
  value: 'NZ',
  label: 'New Zealand'
}, {
  value: 'NI',
  label: 'Nicaragua'
}, {
  value: 'NE',
  label: 'Niger'
}, {
  value: 'NG',
  label: 'Nigeria'
}, {
  value: 'NU',
  label: 'Niue'
}, {
  value: 'NF',
  label: 'Norfolk Island'
}, {
  value: 'MP',
  label: 'Northern Mariana Islands'
}, {
  value: 'NO',
  label: 'Norway'
}, {
  value: 'OM',
  label: 'Oman'
}, {
  value: 'PK',
  label: 'Pakistan'
}, {
  value: 'PW',
  label: 'Palau'
}, {
  value: 'PS',
  label: 'Palestine, State of'
}, {
  value: 'PA',
  label: 'Panama'
}, {
  value: 'PG',
  label: 'Papua New Guinea'
}, {
  value: 'PY',
  label: 'Paraguay'
}, {
  value: 'PE',
  label: 'Peru'
}, {
  value: 'PH',
  label: 'Philippines'
}, {
  value: 'PN',
  label: 'Pitcairn'
}, {
  value: 'PL',
  label: 'Poland'
}, {
  value: 'PT',
  label: 'Portugal'
}, {
  value: 'PR',
  label: 'Puerto Rico'
}, {
  value: 'QA',
  label: 'Qatar'
}, {
  value: 'RE',
  label: "R\xE9union"
}, {
  value: 'RO',
  label: 'Romania'
}, {
  value: 'RU',
  label: 'Russian Federation'
}, {
  value: 'RW',
  label: 'Rwanda'
}, {
  value: 'BL',
  label: "Saint Barth\xE9lemy"
}, {
  value: 'SH',
  label: 'Saint Helena, Ascension and Tristan da Cunha'
}, {
  value: 'KN',
  label: 'Saint Kitts and Nevis'
}, {
  value: 'LC',
  label: 'Saint Lucia'
}, {
  value: 'MF',
  label: 'Saint Martin (French part)'
}, {
  value: 'PM',
  label: 'Saint Pierre and Miquelon'
}, {
  value: 'VC',
  label: 'Saint Vincent and the Grenadines'
}, {
  value: 'WS',
  label: 'Samoa'
}, {
  value: 'SM',
  label: 'San Marino'
}, {
  value: 'ST',
  label: 'Sao Tome and Principe'
}, {
  value: 'SA',
  label: 'Saudi Arabia'
}, {
  value: 'SN',
  label: 'Senegal'
}, {
  value: 'RS',
  label: 'Serbia'
}, {
  value: 'SC',
  label: 'Seychelles'
}, {
  value: 'SL',
  label: 'Sierra Leone'
}, {
  value: 'SG',
  label: 'Singapore'
}, {
  value: 'SX',
  label: 'Sint Maarten (Dutch part)'
}, {
  value: 'SK',
  label: 'Slovakia'
}, {
  value: 'SI',
  label: 'Slovenia'
}, {
  value: 'SB',
  label: 'Solomon Islands'
}, {
  value: 'SO',
  label: 'Somalia'
}, {
  value: 'ZA',
  label: 'South Africa'
}, {
  value: 'GS',
  label: 'South Georgia and the South Sandwich Islands'
}, {
  value: 'SS',
  label: 'South Sudan'
}, {
  value: 'ES',
  label: 'Spain'
}, {
  value: 'LK',
  label: 'Sri Lanka'
}, {
  value: 'SD',
  label: 'Sudan'
}, {
  value: 'SR',
  label: 'Surilabel'
}, {
  value: 'SJ',
  label: 'Svalbard and Jan Mayen'
}, {
  value: 'SZ',
  label: 'Swaziland'
}, {
  value: 'SE',
  label: 'Sweden'
}, {
  value: 'CH',
  label: 'Switzerland'
}, {
  value: 'SY',
  label: 'Syrian Arab Republic'
}, {
  value: 'TW',
  label: 'Taiwan, Province of China'
}, {
  value: 'TJ',
  label: 'Tajikistan'
}, {
  value: 'TZ',
  label: 'Tanzania, United Republic of'
}, {
  value: 'TH',
  label: 'Thailand'
}, {
  value: 'TL',
  label: 'Timor-Leste'
}, {
  value: 'TG',
  label: 'Togo'
}, {
  value: 'TK',
  label: 'Tokelau'
}, {
  value: 'TO',
  label: 'Tonga'
}, {
  value: 'TT',
  label: 'Trinidad and Tobago'
}, {
  value: 'TN',
  label: 'Tunisia'
}, {
  value: 'TR',
  label: 'Turkey'
}, {
  value: 'TM',
  label: 'Turkmenistan'
}, {
  value: 'TC',
  label: 'Turks and Caicos Islands'
}, {
  value: 'TV',
  label: 'Tuvalu'
}, {
  value: 'UG',
  label: 'Uganda'
}, {
  value: 'UA',
  label: 'Ukraine'
}, {
  value: 'AE',
  label: 'United Arab Emirates'
}, {
  value: 'GB',
  label: 'United Kingdom'
}, {
  value: 'US',
  label: 'United States'
}, {
  value: 'UM',
  label: 'United States Minor Outlying Islands'
}, {
  value: 'UY',
  label: 'Uruguay'
}, {
  value: 'UZ',
  label: 'Uzbekistan'
}, {
  value: 'VU',
  label: 'Vanuatu'
}, {
  value: 'VE',
  label: 'Venezuela, Bolivarian Republic of'
}, {
  value: 'VN',
  label: 'Viet Nam'
}, {
  value: 'VG',
  label: 'Virgin Islands, British'
}, {
  value: 'VI',
  label: 'Virgin Islands, U.S.'
}, {
  value: 'WF',
  label: 'Wallis and Futuna'
}, {
  value: 'EH',
  label: 'Western Sahara'
}, {
  value: 'YE',
  label: 'Yemen'
}, {
  value: 'ZM',
  label: 'Zambia'
}, {
  value: 'ZW',
  label: 'Zimbabwe'
}];
export var AUSTRALIAN_STATES = [{
  label: 'New South Wales',
  value: 'NSW'
}, {
  label: 'Queensland',
  value: 'QLD'
}, {
  label: 'Victoria',
  value: 'VIC'
}, {
  label: 'Australian Capital Territory',
  value: 'ACT'
}, {
  label: 'Northern Territory',
  value: 'NT'
}, {
  label: 'South Australia',
  value: 'SA'
}, {
  label: 'Tasmania',
  value: 'TAS'
}, {
  label: 'Western Australia',
  value: 'WA'
}];
export var STREET_TYPES = [{
  label: 'Alley',
  value: 'Alley'
}, {
  label: 'Arcade',
  value: 'Arcade'
}, {
  label: 'Avenue',
  value: 'Avenue'
}, {
  label: 'Boulevard',
  value: 'Boulevard'
}, {
  label: 'Bypass',
  value: 'Bypass'
}, {
  label: 'Circuit',
  value: 'Circuit'
}, {
  label: 'Close',
  value: 'Close'
}, {
  label: 'Corner',
  value: 'Corner'
}, {
  label: 'Court',
  value: 'Court'
}, {
  label: 'Crescent',
  value: 'Crescent'
}, {
  label: 'Cul-de-sac',
  value: 'Cul-de-sac'
}, {
  label: 'Drive',
  value: 'Drive'
}, {
  label: 'Esplanade',
  value: 'Esplanade'
}, {
  label: 'Green',
  value: 'Green'
}, {
  label: 'Grove',
  value: 'Grove'
}, {
  label: 'Highway',
  value: 'Highway'
}, {
  label: 'Junction',
  value: 'Junction'
}, {
  label: 'Lane',
  value: 'Lane'
}, {
  label: 'Link',
  value: 'Link'
}, {
  label: 'Mews',
  value: 'Mews'
}, {
  label: 'Parade',
  value: 'Parade'
}, {
  label: 'Place',
  value: 'Place'
}, {
  label: 'Ridge',
  value: 'Ridge'
}, {
  label: 'Road',
  value: 'Road'
}, {
  label: 'Square',
  value: 'Square'
}, {
  label: 'Street',
  value: 'Street'
}, {
  label: 'Terrace',
  value: 'Terrace'
}];
export var NEW_ZEALAND_REGIONS = [{
  value: 'AUK',
  label: 'Auckland'
}, {
  value: 'BOP',
  label: 'Bay of Plenty'
}, {
  value: 'CAN',
  label: 'Canterbury'
}, {
  value: 'GIS',
  label: 'Gisborne'
}, {
  value: 'HKB',
  label: "Hawke's Bay"
}, {
  value: 'MBH',
  label: 'Marlborough'
}, {
  value: 'MWT',
  label: 'Manawatu-Wanganui'
}, {
  value: 'NSN',
  label: 'Nelson'
}, {
  value: 'NTL',
  label: 'Northland'
}, {
  value: 'OTA',
  label: 'Otago'
}, {
  value: 'STL',
  label: 'Southland'
}, {
  value: 'TAS',
  label: 'Tasman'
}, {
  value: 'TKI',
  label: 'Taranaki'
}, {
  value: 'WKO',
  label: 'Waikato'
}, {
  value: 'WGN',
  label: 'Wellington'
}, {
  value: 'WTC',
  label: 'West Coast'
}, {
  value: 'CIT',
  label: 'Chatham Islands Territory'
}];
export var COUNTRY = function COUNTRY() {
  return generateRender({
    default: [{
      value: '',
      label: getText(TEXT_KEY.SELECT_COUNTRY)
    }, {
      value: 'US',
      label: 'United States'
    }, {
      value: 'CA',
      label: 'Canada'
    }]
  });
};
export var USA_STATES = function USA_STATES() {
  return [{
    value: '',
    label: getText(TEXT_KEY.SELECT_STATE)
  }, {
    value: 'AL',
    label: 'Alabama'
  }, {
    value: 'AK',
    label: 'Alaska'
  }, {
    value: 'AZ',
    label: 'Arizona'
  }, {
    value: 'AR',
    label: 'Arkansas'
  }, {
    value: 'CA',
    label: 'California'
  }, {
    value: 'CO',
    label: 'Colorado'
  }, {
    value: 'CT',
    label: 'Connecticut'
  }, {
    value: 'DE',
    label: 'Delaware'
  }, {
    value: 'DC',
    label: 'District'
  }, {
    value: 'FL',
    label: 'Florida'
  }, {
    value: 'GA',
    label: 'Georgia'
  }, {
    value: 'HI',
    label: 'Hawaii'
  }, {
    value: 'ID',
    label: 'Idaho'
  }, {
    value: 'IL',
    label: 'Illinois'
  }, {
    value: 'IN',
    label: 'Indiana'
  }, {
    value: 'IA',
    label: 'Iowa'
  }, {
    value: 'KS',
    label: 'Kansas'
  }, {
    value: 'KY',
    label: 'Kentucky'
  }, {
    value: 'LA',
    label: 'Louisiana'
  }, {
    value: 'ME',
    label: 'Maine'
  }, {
    value: 'MD',
    label: 'Maryland'
  }, {
    value: 'MA',
    label: 'Massachusetts'
  }, {
    value: 'MI',
    label: 'Michigan'
  }, {
    value: 'MN',
    label: 'Minnesota'
  }, {
    value: 'MS',
    label: 'Mississippi'
  }, {
    value: 'MO',
    label: 'Missouri'
  }, {
    value: 'MT',
    label: 'Montana'
  }, {
    value: 'NE',
    label: 'Nebraska'
  }, {
    value: 'NV',
    label: 'Nevada'
  }, {
    value: 'NH',
    label: 'New Hampshire'
  }, {
    value: 'NJ',
    label: 'New Jersey'
  }, {
    value: 'NM',
    label: 'New Mexico'
  }, {
    value: 'NY',
    label: 'New York'
  }, {
    value: 'NC',
    label: 'North Carolina'
  }, {
    value: 'ND',
    label: 'North Dakota'
  }, {
    value: 'OH',
    label: 'Ohio'
  }, {
    value: 'OK',
    label: 'Oklahoma'
  }, {
    value: 'OR',
    label: 'Oregon'
  }, {
    value: 'PA',
    label: 'Pennsylvania'
  }, {
    value: 'RI',
    label: 'Rhode Island'
  }, {
    value: 'SC',
    label: 'South Carolina'
  }, {
    value: 'SD',
    label: 'South Dakota'
  }, {
    value: 'TN',
    label: 'Tennessee'
  }, {
    value: 'TX',
    label: 'Texas'
  }, {
    value: 'UT',
    label: 'Utah'
  }, {
    value: 'VT',
    label: 'Vermont'
  }, {
    value: 'VA',
    label: 'Virginia'
  }, {
    value: 'WA',
    label: 'Washington'
  }, {
    value: 'WV',
    label: 'West Virginia'
  }, {
    value: 'WI',
    label: 'Wisconsin'
  }, {
    value: 'WY',
    label: 'Wyoming'
  }];
};
export var CA_STATES = function CA_STATES() {
  return [{
    value: '',
    label: getText(TEXT_KEY.SELECT_STATE)
  }, {
    value: 'NL',
    label: 'Newfoundland and Labrador'
  }, {
    value: 'PE',
    label: 'Prince Edward Island'
  }, {
    value: 'NS',
    label: 'Nova Scotia'
  }, {
    value: 'NB',
    label: 'New Brunswick'
  }, {
    value: 'QC',
    label: 'Quebec'
  }, {
    value: 'ON',
    label: 'Ontario'
  }, {
    value: 'MB',
    label: 'Manitoba'
  }, {
    value: 'SK',
    label: 'Saskatchewan'
  }, {
    value: 'AB',
    label: 'Alberta'
  }, {
    value: 'BC',
    label: 'British Columbia'
  }, {
    value: 'YT',
    label: 'Yukon'
  }, {
    value: 'NT',
    label: 'Northwest Territories'
  }, {
    value: 'NU',
    label: 'Nunavut'
  }];
};
export var AT_STATES = function AT_STATES() {
  return [{
    value: '',
    label: getText(TEXT_KEY.SELECT_STATE)
  }, {
    value: 'Burgenland',
    label: 'Burgenland'
  }, {
    value: 'Kärnten',
    label: 'Kärnten'
  }, {
    value: 'Niederösterreich',
    label: 'Niederösterreich'
  }, {
    value: 'Oberösterreich',
    label: 'Oberösterreich'
  }, {
    value: 'Salzburg',
    label: 'Salzburg'
  }, {
    value: 'Steiermark',
    label: 'Steiermark'
  }, {
    value: 'Tirol',
    label: 'Tirol'
  }, {
    value: 'Vorarlberg',
    label: 'Vorarlberg'
  }, {
    value: 'Wien',
    label: 'Wien'
  }];
};
export var ES_STATES = function ES_STATES() {
  return [{
    value: '',
    label: getText(TEXT_KEY.SELECT_STATE)
  }, {
    value: 'AL',
    label: 'Álava'
  }, {
    value: 'AB',
    label: 'Albacete'
  }, {
    value: 'ALC',
    label: 'Alicante'
  }, {
    value: 'ALM',
    label: 'Almería'
  }, {
    value: 'AST',
    label: 'Asturias'
  }, {
    value: 'AV',
    label: 'Ávila'
  }, {
    value: 'BJ',
    label: 'Badajoz'
  }, {
    value: 'BCL',
    label: 'Barcelona'
  }, {
    value: 'BIZ',
    label: 'Biscay (Bizkaia)'
  }, {
    value: 'BUR',
    label: 'Burgos'
  }, {
    value: 'CC',
    label: 'Cáceres'
  }, {
    value: 'CAD',
    label: 'Cádiz'
  }, {
    value: 'CANT',
    label: 'Cantabria'
  }, {
    value: 'CS',
    label: 'Castellón'
  }, {
    value: 'CR',
    label: 'Ciudad Real'
  }, {
    value: 'CORD',
    label: 'Córdoba'
  }, {
    value: 'CU',
    label: 'Cuenca'
  }, {
    value: 'GRO',
    label: 'Gerona (Girona)'
  }, {
    value: 'GRA',
    label: 'Granada'
  }, {
    value: 'GU',
    label: 'Guadalajara'
  }, {
    value: 'SS',
    label: 'Guipúzcoa (Gipuzkoa)'
  }, {
    value: 'H',
    label: 'Huelva'
  }, {
    value: 'HU',
    label: 'Huesca'
  }, {
    value: 'J',
    label: 'Jaén'
  }, {
    value: 'CR',
    label: 'La Coruña (A Coruña)'
  }, {
    value: 'LO',
    label: 'La Rioja'
  }, {
    value: 'LP',
    label: 'Las Palmas'
  }, {
    value: 'LE',
    label: 'León'
  }, {
    value: 'LLE',
    label: 'Lérida (Lleida)'
  }, {
    value: 'LU',
    label: 'Lugo'
  }, {
    value: 'M',
    label: 'Madrid'
  }, {
    value: 'MA',
    label: 'Málaga'
  }, {
    value: 'MU',
    label: 'Murcia'
  }, {
    value: 'NAV',
    label: 'Navarre (Navarra)'
  }, {
    value: 'OU',
    label: 'Ourense (Ourense)'
  }, {
    value: 'P',
    label: 'Palencia'
  }, {
    value: 'PO',
    label: 'Pontevedra'
  }, {
    value: 'SA',
    label: 'Salamanca'
  }, {
    value: 'TF',
    label: 'Santa Cruz de Tenerife'
  }, {
    value: 'SG',
    label: 'Segovia'
  }, {
    value: 'SE',
    label: 'Seville (Sevilla)'
  }, {
    value: 'SO',
    label: 'Soria'
  }, {
    value: 'T',
    label: 'Tarragona'
  }, {
    value: 'TE',
    label: 'Teruel'
  }, {
    value: 'TO',
    label: 'Toledo'
  }, {
    value: 'V',
    label: 'Valencia'
  }, {
    value: 'VA',
    label: 'Valladolid'
  }, {
    value: 'ZA',
    label: 'Zamora'
  }, {
    value: 'Z',
    label: 'Zaragoza'
  }];
};
export var STATES = {
  US: function US() {
    return USA_STATES();
  },
  CA: function CA() {
    return CA_STATES();
  },
  AT: function AT() {
    return AT_STATES();
  },
  ES: function ES() {
    return ES_STATES();
  }
};