import generateRender from 'shared/utils/generateRender';

export const styles = {
  header: {
    width: '100%',
    height: '4rem',
  },
  listButton: {
    padding: '0.7rem 0 1.3rem 0',
  },
  divider: {
    width: '100%',
    height: '0.2rem',
    zIndex: 500,
  },
  forwardArrow: {
    objectFit: 'contain',
    height: '1.8rem',
    width: '3.5rem',
  },
  switchContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  signOutButton: {
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2rem',
    width: '100%',
  },
  backArrow: {
    objectFit: 'contain',
  },
};

export const listButtonTextStyle = title =>
  generateRender({
    default: defaultListButtonStyle,
  });

const defaultListButtonStyle = () => ({
  display: 'flex',
});

