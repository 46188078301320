import _toConsumableArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { configureStore, getDefaultMiddleware } from '../node_modules/@reduxjs/toolkit';
import { persistStore } from '../node_modules/redux-persist';
import reactotron from '../utils/reactotron';
import { apiBaseMiddleware } from './api/services/rtkQuery/apiBaseQuery';
import { pReducer } from './rootReducer';
var customizedDefaultMiddleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false
});
var middlewares = [];
var enhancers = [];
middlewares.push(apiBaseMiddleware);
reactotron && enhancers.push(reactotron.createEnhancer());
export var store = configureStore({
  reducer: pReducer,
  devTools: true,
  middleware: [].concat(_toConsumableArray(customizedDefaultMiddleware), middlewares),
  enhancers: [].concat(enhancers)
});
setupListeners(store.dispatch);
export var persistor = persistStore(store);
export default store;