import DefaultStoreDownload from './components/DefaultStoreDownload';
import HriStoreDownload from './components/HriStoreDownload';
import ComingSoonLayout from './components/ComingSoonLayout';
import generateRender from 'shared/utils/generateRender';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

const showComingSoonLandingPage = ENVIRONMENT_VARIABLES.COMING_SOON === 'true' || showComingSoonLandingPage === true;

const StoreDownload = showComingSoonLandingPage
  ? ComingSoonLayout
  : generateRender({
      default: DefaultStoreDownload,
      hri: HriStoreDownload,
    });

export default StoreDownload;

