import React, { useContext } from 'react';
import { useSelector } from 'shared/node_modules/react-redux';
import { getEvents } from 'shared/state/query/events';
import styled from 'styled-components';
import ThemeContext from 'shared/context/ThemeContext';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import Screens from 'shared/screens';
import { EVENTS_FEATURE_STATUS } from 'shared/screens/Events/constants';
import useEventsIconTooltip from 'shared/screens/Events/hooks/useEventsIconTooltip';
import useGenerateEventsIconRender from 'shared/screens/Events/hooks/useGenerateEventsIconRender';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../../utils/mobileSupported';
import LazyImage from '../../LazyImage/LazyImage';
import { TAG } from 'shared/constants';

export default function EventsIcon({ navigation }) {
  const themeContext = useContext(ThemeContext);

  const events = useSelector(state => getEvents.select({ requestAssetsData: true })(state));

  const eventsState = events?.data?.eventsFeatureState?.eventsFeatureStatus;
  const eventsCount = events?.data?.eventsFeatureState?.newEventsCount;
  const eventsAssets = useSelector(state => state.assetsPackage?.assets?.EVENTS_FEATURE);
  const { eventIcon } = useGenerateEventsIconRender({ eventsAssets, eventsState });
  const { handleEventsIconTooltip } = useEventsIconTooltip({ eventsState, navigation });

  const handleEventsButton = () =>
    eventsState === EVENTS_FEATURE_STATUS.ACTIVE && eventsAssets
      ? QueryParamsProxy.navigate(navigation, Screens.Events)
      : handleEventsIconTooltip();

  return (
    <React.Fragment>
      <LazyImage
        tag={TAG.IMG}
        alt="divider"
        src={assetSource(ASSET_KEY.BOTTOM_BAR_DIVIDER)}
        style={pwaStyleAdapter(themeContext.BottomBar.Divider)}
      />
      <div id="EVENTS_ICON" className="BarItem" onClick={handleEventsButton}>
        <EventIcon icon={eventIcon} draggable="false" theme={pwaStyleAdapter(themeContext.BottomBar.BarItemIcon)} />
        {eventsState === EVENTS_FEATURE_STATUS.ACTIVE && eventsCount !== 0 && (
          <EventNotificationBadge
            theme={pwaStyleAdapter(themeContext.BottomBar.NotificationEvents)}
            eventsCount={eventsCount}
            eventsAssets={eventsAssets}
          >
            {eventsCount}
          </EventNotificationBadge>
        )}
      </div>
    </React.Fragment>
  );
}

const EventIcon = styled.img.attrs(({ icon }) => ({
  src: icon,
  alt: 'Bottom Bar Events',
}))`
  ${({ theme }) => theme}
`;

const EventNotificationBadge = styled.div`
  background-image: url(${({ eventsAssets }) => eventsAssets?.EVENTS_NOTIFICATION_BADGE});
  background-size: cover;
  height: ${pwaStyleAdapter(2.1, 'rem')};
  justify-content: center;
  align-items: center;
  position: absolute;
  width: ${pwaStyleAdapter(2.1, 'rem')};
  z-index: 1;
  display: flex;
  font-size: ${({ eventsCount }) => (eventsCount > 9 ? 0.8 : 1)}rem;
  font-weight: 600 !important;
  color: #4c0e00;
  ${({ theme }) => theme}
`;

