import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MissionHelp from './components/MissionHelp';
import { MissionTutorialStepsKeys, tutorialOpacity } from 'shared/utils/tutorial';
import MissionCard from './components/MissionCard';
import { hasExplorerType, hasGrandType } from 'shared/screens/Missions/utils/missionsPerformance';
import { Spinner } from 'react-bootstrap';
import Screens from 'shared/screens';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import useMissionsRequest from 'shared/screens/Missions/hooks/useMissions';
import CustomScrollBar from '../../components/CustomScrollBar';
import './scrollBarStyles.scss';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../utils/mobileSupported';
import { isSmallerScreenHeight } from './utils';
import { isMobile, isTablet } from 'react-device-detect';
import MissionTabs from './components/MissionTabs';

const LoadingSpinner = () => (
  <Loading>
    <Spinner animation="border" />
  </Loading>
);

const Missions = props => {
  const { navigation, missionsData, missionsAssets, assetsLoaded, activeTutorial, tutorialStep } = props;

  useEffect(() => {
    if (missionsData.length === 0) QueryParamsProxy.navigate(navigation, Screens.Home);
  }, []);

  const { filteredValue, filterData, showTabs } = useMissionsRequest();

  return missionsAssets ? (
    <Container>
      {!assetsLoaded ? (
        <LoadingSpinner />
      ) : (
        <>
          <Background src={missionsAssets.BACKGROUND} />
          <MissionHelp missionsAssets={missionsAssets} activeTutorial={activeTutorial} />
          {showTabs && <MissionTabs {...props} index={0} />}

          <MissionsContainerWrapper>
            <MissionsContainer opacity={tutorialOpacity(tutorialStep === MissionTutorialStepsKeys.EXPLORE)}>
              <CustomScrollBar
                showByDefault={true}
                isDraggableX={true}
                showHorizontal={true}
                showVertical={false}
                customStyle={styles.CustomScrollBarContainerStyle}
                customClassNames={`custom-scrollbar-missions ${isStandalonePWA && !isTablet ? 'custom-scrollbar-missions-mobile' : ''}`}
              >
                {!hasExplorerType(filterData) ? (
                  <DisplayContainer>
                    <ComingSoon src={missionsAssets.COMING_SOON} />
                  </DisplayContainer>
                ) : filteredValue.length === 0 ? (
                  <DisplayContainer>
                    <WithoutMissions src={missionsAssets.NO_MISSIONS} />
                  </DisplayContainer>
                ) : (
                  <>
                    {filteredValue.map((item, index) => (
                      <MissionCard object={{ item, index }} key={item.missionId} {...props} />
                    ))}
                    {filteredValue.length === 1 && hasGrandType(filteredValue[0].missionType) && (
                      <NoExplorerMissions src={missionsAssets.NO_MISSIONS} />
                    )}
                  </>
                )}
              </CustomScrollBar>
            </MissionsContainer>
          </MissionsContainerWrapper>
        </>
      )}
    </Container>
  ) : (
    <></>
  );
};

const isNotStandalonePWAOnlyMobile = isNotStandaloneMobilePWA && !isTablet;
const isNotStandalonePWAOnlyTablet = isNotStandaloneMobilePWA && isTablet;

const Loading = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MissionsContainerSmall = css`
  height: ${isNotStandalonePWAOnlyTablet
    ? 82
    : isNotStandalonePWAOnlyMobile
      ? isSmallerScreenHeight
        ? 67
        : 73
      : 90}vh;
  padding-left: ${!isTablet ? 4 : 0}rem;
`;

const MissionsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 6vh;
  ${isMobile && MissionsContainerSmall}
`;

const Container = styled.div`
  height: 100%;
  align-items: center;
`;

const MissionsContainerWrapper = styled.div`
  width: 99vw;
  padding-left: 5vh;
  box-sizing: border-box;
  overflow: hidden;
`;

const Background = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const WithoutMissions = styled.img`
  max-height: 50vh;
  margin-top: 10vh;
`;

const NoExplorerMissions = styled.img`
  max-height: 35vh;
  margin-left: calc(33vw - 26vh);
  align-self: center;
`;

const DisplayContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ComingSoon = styled.img`
  height: 14vh;
  width: 120vh;
  margin-top: 28vh;
`;

const styles = {
  CustomScrollBarContainerStyle: {
    paddingTop: isNotStandalonePWAOnlyMobile ? '10vh' : isMobile && !isTablet ? '8vh' : '12vh',
    flexDirection: 'row',
    display: 'flex',
  },
};

export default Missions;

