import { isMobile, isTablet } from 'react-device-detect';
import { isStandalonePWA } from '../../../../../web/src/utils/mobileSupported';
import { getResponsiveValue } from '../../../../../web/src/utils/globalMethods';
import generateRender from '../../../../utils/generateRender';
var LinkAccountTooltipLeftPosConditions = [[680, 76], [710, 77], [780, isStandalonePWA ? 76 : 78], [860, 77], [900, 79]];
var LinkAccountTooltipLeftPosConditionsTablet = [[780, 70], [840, isStandalonePWA ? 70 : 72]];
var LinkAccountTooltipLeftPosConditionsLadyLuck = [[680, 74], [710, 76], [780, isStandalonePWA ? 74 : 77], [860, 76], [900, 77]];
var LinkAccountTooltipLeftPos = generateRender({
  default: isTablet ? LinkAccountTooltipLeftPosConditionsTablet : LinkAccountTooltipLeftPosConditions,
  ladyluck: isTablet ? LinkAccountTooltipLeftPosConditionsTablet : LinkAccountTooltipLeftPosConditionsLadyLuck
});
var regularLinkAccountTooltipPosition = function regularLinkAccountTooltipPosition() {
  return {
    top: '12vh',
    left: window.innerWidth >= 1920 ? '69%' : window.innerWidth >= 1700 ? '72vw' : '73vw'
  };
};
var mobileLinkAccountTooltipPosition = function mobileLinkAccountTooltipPosition() {
  var windowHeight = window.innerHeight;
  return {
    top: isTablet ? '15vh' : '17vh',
    left: "".concat(getResponsiveValue({
      windowHeight: windowHeight,
      conditions: LinkAccountTooltipLeftPos,
      defaultValue: isTablet ? 72 : 77
    }), "vw")
  };
};
export var linkAccountTooltipPosition = isMobile ? mobileLinkAccountTooltipPosition : regularLinkAccountTooltipPosition;
var regularRewardCenterTooltipPosition = function regularRewardCenterTooltipPosition() {
  return {
    top: 'calc(100% - 280px)',
    left: 'calc(50% - 200px)'
  };
};
var mobileRewardCenterTooltipPosition = function mobileRewardCenterTooltipPosition() {
  return {
    top: isTablet ? 'calc(100% - 290px)' : 'calc(100% - 340px)',
    left: 'calc(50% - 200px)'
  };
};
export var rewardCenterTooltipPosition = isMobile ? mobileRewardCenterTooltipPosition : regularRewardCenterTooltipPosition;
var regularDetailEventsTooltipPosition = function regularDetailEventsTooltipPosition() {
  return {
    top: '10vh',
    left: '1vw'
  };
};
var mobileDetailEventsTooltipPosition = function mobileDetailEventsTooltipPosition() {
  return {
    top: isTablet ? '13vh' : '17vh',
    left: isTablet ? '3vw' : '7vw'
  };
};
export var detailEventsTooltipPosition = isMobile ? mobileDetailEventsTooltipPosition : regularDetailEventsTooltipPosition;