import React, { useContext } from 'react';
import ThemeContext from 'shared/context/ThemeContext';
import styled from 'styled-components';
import ImageBoundsWrapper from '../ImageBoundsWrapper';
import TimeRemainingText from '../TimeRemainingText';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { isStandalonePWA } from '../../utils/mobileSupported';

const TimeRemaining = ({ timeRemaining }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <VIPTimer theme={themeContext.TimeRemaining.VIPTimer}>
      <TimerBackground
        source={assetSource(ASSET_KEY.GLOWING_CONTAINER)}
        ibw={require(asset`images/vip-assets/glowing-container.ibw`)}
      />
      <ClockIconGlowing src={assetSource(ASSET_KEY.CLOCK_ICON_GLOWING)} alt={'Clock Icon'} />
      <TimeRemainingText style={themeContext.TimeRemaining.TimerLabel} until={timeRemaining} />
    </VIPTimer>
  );
};

export default TimeRemaining;

export const VIPTimer = styled.div`
  position: absolute;
  z-index: 9;
  left: -1rem;
  top: ${isStandalonePWA ? 7.5 : 5.5}rem;
  @media only screen and (max-width: 1600px) and (min-width: 1500px) and (max-height: 900px) and (min-height: 850px) {
    top: 5rem;
  }
  @media only screen and (max-height: 800px) {
    ${({ theme }) => theme}
  }
`;

export const TimerBackground = styled(ImageBoundsWrapper)`
  width: 10rem;
  height: 3rem;
`;

export const ClockIconGlowing = styled.img`
  position: absolute;
  top: 0.3rem;
  left: 0.7rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 9;
`;

