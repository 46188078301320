import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  LoyaltyValueText,
  Container,
  LoyaltyPointsValueContainer,
  LoyaltyPointsExpiryText,
  LoyaltyIcon,
} from './styledComponents';
import { numberWithCommas } from 'shared/utils/number';
import { isMillelacs } from 'shared/utils/targetApplication';

const RewardLoyaltyPointsTab = ({ loyaltyExpiryDate, loyaltyPoints, isLoyaltyExpired }) => {
  const { YOUR, LOYALTY_POINTS, EXPIRE_ON, BE_QUICK_AND_REDEEM_NOW, DONT_HAVE_LOYALTY_POINTS } = TEXT_KEY;

  return (
    <Container>
      <LoyaltyPointsValueContainer>
        <LoyaltyIcon alt="" src={assetSource(ASSET_KEY.LOYALTY_ICON)} />
        <LoyaltyValueText>{numberWithCommas(loyaltyPoints)}</LoyaltyValueText>
      </LoyaltyPointsValueContainer>
      
      <LoyaltyPointsExpiryText>
        {(isLoyaltyExpired && isMillelacs)
          ? getText(DONT_HAVE_LOYALTY_POINTS)
          : `${getText(YOUR)} ${getText(LOYALTY_POINTS)} ${getText(EXPIRE_ON)} ${loyaltyExpiryDate}, ${getText(BE_QUICK_AND_REDEEM_NOW)}`
        }
      </LoyaltyPointsExpiryText>
    </Container>
  );
};

export default RewardLoyaltyPointsTab;

