import Logger from '../../../lib/analytics/logger';
import { REWARD_CENTER_EVENT_TYPES } from '../constants';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_WIDGETS } from '../../../lib/analytics/constants';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import { FEATURES_LIST } from '../../../constants/types';
export var sendRewardCenterEvent = function sendRewardCenterEvent(eventType, payload) {
  switch (eventType) {
    case REWARD_CENTER_EVENT_TYPES.ENTER_REWARD_CENTER_SCREEN:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.REWARD_CENTER,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN
      });
      Logger.sendEvent(EVENT_TYPES.GTM_FEATURE_ENGAGEMENT, {
        feature: FEATURES_LIST.REWARD_CENTER
      });
      break;
    case REWARD_CENTER_EVENT_TYPES.OPEN_REWARD_CENTER_CATEGORY:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.REWARD_CENTER,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.REWARD_CENTER].REWARD_CENTER_CATEGORY,
        rc_id: payload.categoryId
      });
      break;
    case REWARD_CENTER_EVENT_TYPES.OPEN_REWARD_CENTER_OFFER:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.REWARD_CENTER,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.REWARD_CENTER].REWARD_CENTER_OFFER,
        r_id: payload.offerId
      });
      break;
    default:
      break;
  }
};