export const BannerHeightMultiSlides = [
  [600, 31],
  [660, 36],
  [760, 41],
];

export const BannerHeightMultiSlidesTablets = [
  [660, 36],
  [760, 43],
  [840, 43],
];

export const BannerWidthMultiSlides = [
  [600, 12],
  [660, 15],
  [760, 17],
  [800, 18],
];

export const BannerWidthMultiSlidesTablets = [
  [660, 15],
  [770, 16],
  [840, 18],
];

export const BannerTopPositionMultiSlides = [
  [600, 0.3],
  [660, 0.5],
  [760, 0.7],
];

export const BannerTopPositionMultiSlidesTablets = [
  [770, -0.2],
  [840, 0.7],
];

export const BannerDetailTopMultiSlides = [
  [600, -0.4],
  [660, -0.3],
  [760, -0.2],
  [800, 0],
];

export const BannerDetailTopMultiSlidesTablets = [
  [770, 1.5],
  [840, 0],
];

export const BannerDetailHeightMultiSlides = [
  [600, 30],
  [660, 36],
  [760, 40],
];

export const BannerDetailHeightMultiSlidesTablets = [
  [660, 36],
  [840, 42],
];

export const BannerDetailHeightSingleSlide = [
  [600, 31],
  [660, 34],
  [760, 41],
];

export const BannerDetailTopSingleSlide = [
  [600, -0.5],
  [660, 0],
  [760, -0.2],
];

export const BannerDetailWidthMultiSlides = [
  [600, 20],
  [760, 25],
  [800, 27],
];

export const BannerDetailWidthMultiSlidesTablets = [
  [600, 20],
  [840, 30],
];

export const BannerDetailGameIconHeight = [
  [600, 15],
  [660, 19],
  [760, 21],
];

export const BannerDetailGameIconHeightTablet = [
  [770, 23],
  [840, 22],
];

export const BannerDetailGameIconWidth = [
  [600, 7],
  [660, 10],
  [760, 11],
];

export const BannerDetailGameIconWidthTablet = [
  [770, 13],
  [840, 12],
];

