import { useEffect, useState, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { isStandalonePWA } from '../utils/mobileSupported';
const useKeyboardAwareModal = ({
  modalClassName = 'modal-content',
  topOffsetWithScroll = isStandalonePWA ? 100 : 30,
  topOffsetNoScroll = isStandalonePWA ? 100 : 30,
}) => {
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const checkKeyboardOpen = useRef(false);
  useEffect(() => {
    if (!(isMobile && !isTablet)) return;

    const handleFocus = () => {
      const modal = document.querySelector(`.${modalClassName}`);
      if (!modal) return;
      setTimeout(() => {
        setKeyboardOpen(true);
        checkKeyboardOpen.current = true;
        const lastScrollY = window.scrollY;
        modal.style.position = 'fixed';
        modal.style.top = '50%';
        modal.style.left = '50%';
        if (lastScrollY > 150) {
          modal.style.top = '50%';
          modal.style.transform = `translate(-50%, -${topOffsetWithScroll}px)`;
        } else {
          modal.style.transition = 'top 300ms ease-in-out';
          modal.style.top = `${topOffsetNoScroll}px`;
          modal.style.transform = 'translate(-50%, -50%)';
        }
      }, 500);
    };

    const handleBlur = () => {
      checkKeyboardOpen.current = false;
      const modal = document.querySelector(`.${modalClassName}`);
      if (!modal) return;
      setTimeout(() => {
        if (checkKeyboardOpen.current === false) {
          setKeyboardOpen(false);
          modal.style.transition = 'top 300ms ease-in-out';
          modal.style.position = 'fixed';
          modal.style.top = '50%';
          modal.style.left = '50%';
          modal.style.transform = 'translate(-50%, -50%)';
        }
      }, 800);
    };

    document.addEventListener('focusin', handleFocus);
    document.addEventListener('focusout', handleBlur);

    return () => {
      document.removeEventListener('focusin', handleFocus);
      document.removeEventListener('focusout', handleBlur);
    };
  }, [modalClassName]);

  useEffect(() => {
    if (!(isMobile && !isTablet)) return;
    const inputs = document.querySelectorAll('input, textarea');

    const scrollToInput = event => {
      const input = event.target;
      setTimeout(() => {
        input.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    };

    inputs.forEach(input => {
      input.addEventListener('focus', scrollToInput);
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', scrollToInput);
      });
    };
  }, []);

  return { keyboardOpen };
};

export default useKeyboardAwareModal;

