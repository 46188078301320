import { pwaStyleAdapter } from '../../utils/mobileSupported';

const styles = {
  peeker: {
    position: 'absolute',
    top: 24,
    right: 16,
    zIndex: 2,
    cursor: 'pointer',
  },
  peekerImage: {
    width: pwaStyleAdapter(1.2, 'rem', 1.1, true),
  },
};

export default styles;
