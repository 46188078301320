import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Spinner } from 'react-bootstrap';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import routeParams from 'shared/utils/routeParamConstants';
import useVerifyEmailConfirmation from 'shared/screens/DoubleOptIn/hooks/useVerifyEmailConfirmation';
import useNotification from 'shared/hooks/useNotification';
import { getText, TEXT_KEY } from 'shared/utils/localization';

function DoubleOptIn({ navigation }) {
  const { Start } = useThemeContext();
  const { addNotification } = useNotification();
  const verificationId = navigation.getParam(routeParams.PARAM_EMAIL_VERIFICATION);
  const { data, error, isLoading, isSuccess } = useVerifyEmailConfirmation({ verificationId });

  const changePageAppClass = useCallback(() => {
    let element = document.getElementsByClassName('App');
    if (element[0]) element[0].className = '';
  }, []);

  useEffect(() => {
    changePageAppClass();
    if (error?.data) {
      addNotification({
        message: error?.data?.errorMessage || getText(TEXT_KEY.GENERIC_MESSAGE_ERROR),
        title: getText(TEXT_KEY.OOPS),
        hideCloseButton: true,
        hideHelpButton: true,
      });
    }
  }, [error?.data, changePageAppClass]);

  if (isLoading)
    return (
      <PageWrapper>
        <Spinner animation="border" />
      </PageWrapper>
    );

  return (
    <PageWrapper theme={isMobile ? Start.ComingSoonBackgroundsMobile : Start.ComingSoonBackgrounds}>
      {isSuccess ? (
        <BoxContainer>
          <CongratsTextWrapper>
            <CongratsText>
              {data?.verification?.successMessage || getText(TEXT_KEY.EMAIL_CONFIRMATION_MESSAGE)}
            </CongratsText>
          </CongratsTextWrapper>
        </BoxContainer>
      ) : (
        <></>
      )}
    </PageWrapper>
  );
}

export default DoubleOptIn;

const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url(${assetSource(ASSET_KEY.EMAIL_CONFIRMATION_BAKCGROUND)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 700px) {
    background-position: 50%;
    ${({ theme }) => theme}
  }

  ${({ theme }) => theme}
`;

const BoxContainer = styled.div`
  display: flex;
  width: 70vw;
  height: 80vh;
  max-width: 800px;
  max-height: 600px;
  position: relative;
  justify-content: center;
  background-image: url(${assetSource(ASSET_KEY.EMAIL_CONFIRMATION_POPUP)});
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 1100px) {
    background-position: center;
  }

  @media screen and (max-width: 600px) and (min-height: 600px) {
    width: 100vw;
    margin-left: 1vw;
    margin-bottom: 25%;
  }

  @media screen and (max-width: 900px) and (max-height: 500px) {
    background-position: center;
    margin-bottom: 10%;
    height: 300px;
  }
`;

const CongratsTextWrapper = styled.div`
  position: absolute;
  width: 70%;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  p {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 1100px) {
    bottom: 23%;
    width: 75%;
  }

  @media screen and (max-height: 650px) {
    bottom: 5%;
    width: 58%;
  }

  @media screen and (max-height: 500px) {
    bottom: 8%;
    height: 8rem;
    width: 50%;
    p {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 600px) and (min-height: 600px) {
    height: 7rem;
    bottom: 29%;
    p {
      font-size: 0.6rem;
    }
  }

  @media screen and (max-width: 600px) and (min-height: 600px) and (max-height: 650px), screen and (max-width: 385px) {
    bottom: 27%;
  }

  @media screen and (max-width: 380px) and (min-height: 651px) and (max-height: 850px) {
    bottom: 30%;
  }

  @media screen and (min-width: 370px) and (max-width: 380px) and (min-height: 600px) and (max-height: 700px) {
    bottom: 29.6%;
  }

  @media screen and (max-width: 900px) and (max-height: 500px) {
    height: 7rem;
    bottom: 8%;
    width: 55%;
    p {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-height: 600px) and (min-width: 900px) and (max-width: 1100px) and (max-height: 1000px) {
    height: 11rem;
    bottom: 16%;
    width: 70%;
  }

  @media screen and (min-height: 1000px) and (min-width: 750px) and (max-width: 1050px) and (max-height: 1400px) {
    height: 11rem;
    bottom: 18%;
    width: 70%;
  }
`;

const CongratsText = styled.p`
  white-space: pre-line;
  text-align: center;
  margin: 0;
`;

