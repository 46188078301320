import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { EVENT_TYPES } from '../../../../lib/analytics/constants';
import Logger from '../../../../lib/analytics/logger';
import { HEADER, METRICS } from './constants';
import { validApiTracking } from './validations';
var sendMetrics = function sendMetrics(event, body) {
  return Logger.sendEvent(event, body);
};
var requestSentMetric = function requestSentMetric(_ref) {
  var headers = _ref.headers,
    baseURL = _ref.baseURL,
    url = _ref.url;
  return validApiTracking(url) && sendMetrics(EVENT_TYPES.REQUEST_SENT, {
    requestId: headers[HEADER.REQUEST_ID],
    headers: JSON.stringify(headers),
    uri: baseURL + url
  });
};
var responseReceivedMetric = function responseReceivedMetric(_ref2) {
  var status = _ref2.status,
    data = _ref2.data,
    _ref2$config = _ref2.config,
    headers = _ref2$config.headers,
    url = _ref2$config.url;
  return validApiTracking(url) && sendMetrics(EVENT_TYPES.RESPONSE_RECEIVED, {
    requestId: headers[HEADER.REQUEST_ID],
    status: status,
    body: JSON.stringify(data)
  });
};
export var saveMetrics = _defineProperty(_defineProperty({}, METRICS.REQUEST_SENT, requestSentMetric), METRICS.RESPONSE_RECEIVED, responseReceivedMetric);