import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import useNotification from 'shared/hooks/useNotification';
import ThemeContext from 'shared/context/ThemeContext';
import { onPressHelp } from 'shared/utils/redirectionHelp';
import Modal from '../JSModal';
import Button from '../Button/Button';
import {
  ActionsContainer,
  HeaderContainer,
  HeaderContent,
  HelpText,
  HelpTextContainer,
  Message,
  ModalContainer,
  ModalContent,
  ModalMainBodyStyle,
  SocialModalStyle,
  Title,
  TitleContainer,
} from './styledComponents';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const NotificationModal = () => {
  const { clearNotification, notification } = useNotification();
  const {
    title,
    message,
    button,
    buttonAction = null,
    hideCloseButton = false,
    hideHelpButton = false,
    hasCancelButton = false,
    customMessageStyle = {},
  } = notification;

  const { NotificationModal } = useContext(ThemeContext);

  const handleButtonAction = () => {
    buttonAction && buttonAction();
    clearNotification();
  };

  return (
    <Modal
      customBackground
      hideCloseButton={hideCloseButton}
      hideOverlay={false}
      isVisible={!!notification}
      modalMainBodyStyle={ModalMainBodyStyle}
      onBackButtonPress={() => (!hideCloseButton || hasCancelButton) && clearNotification()}
      style={SocialModalStyle}
      zIndex={9999}
    >
      <ModalContainer>
        <HeaderContainer
          ibw={require(asset`full-modal-header.ibw`)}
          position="absolute"
          source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
          theme={NotificationModal.HeaderContainer}
        >
          <HeaderContent>
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
            {!hideHelpButton && (
              <HelpTextContainer onClick={onPressHelp}>
                <HelpText>{getText(TEXT_KEY.NEED_HELP)}</HelpText>
              </HelpTextContainer>
            )}
          </HeaderContent>
        </HeaderContainer>
        <ModalContent withButton={!!button}>
          <Message customStyle={customMessageStyle}>{message}</Message>
          <ActionsContainer>
            {button && (
              <Button
                textStroke={BUTTON_STROKES.PRIMARY}
                imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                onClick={handleButtonAction}
                label={button}
              />
            )}
            {hasCancelButton && (
              <Button
                textStroke={BUTTON_STROKES.DANGER}
                imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_DANGER)}
                onClick={clearNotification}
                label={getText(TEXT_KEY.CANCEL)}
              />
            )}
          </ActionsContainer>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default NotificationModal;

