import generateRender from 'shared/utils/generateRender';
import { DefaultTermsModal } from './TermsModalComponents/DefaulTermsModal';
import TextLinksTermsLayoutModal from './TermsModalComponents/TextLinksTermsLayoutModal';

const TermsModal = generateRender({
  default: DefaultTermsModal,
  ladyluck: TextLinksTermsLayoutModal,
});

export default TermsModal;

