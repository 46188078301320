import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../../utils/mobileSupported';

const LoginButtonDefault = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED),
  containerStyle: { height: pwaStyleAdapter(100) },
}))`
  cursor: pointer;
  font-size: ${pwaStyleAdapter(1.7, 'rem')};
  font-weight: 600;
  width: ${pwaStyleAdapter(181, 'px')};
  height: ${pwaStyleAdapter(59, 'px')};
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButtonMillelacs = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid
    ? assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)
    : assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE),
  imageStyle: {
    width: pwaStyleAdapter(35, 'vh'),
    height: pwaStyleAdapter(6, 'vh'),
  },
  containerStyle: { height: pwaStyleAdapter(6, 'vh'), marginTop: '30px' },
}))`
  cursor: pointer;
  font-size: ${pwaStyleAdapter(1.7, 'rem')};
  font-weight: 600;
  width: ${pwaStyleAdapter(181, 'px')};
  height: ${pwaStyleAdapter(59, 'px')};
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButton = generateRender({
  default: LoginButtonDefault,
  millelacs: LoginButtonMillelacs,
  ladyluck: LoginButtonMillelacs,
});

export default LoginButton;

