import React from 'react';
import styled from 'styled-components';

import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import useForgetPassword from 'shared/screens/ForgotPassword/hooks/useForgetPassword';
import { trimInputString } from 'shared/utils/string';

import { styles } from './../styledComponents';
import TermsModalWrapper from '../../../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import Button from '../../../../components/Button/Button';
import { TextInput } from '../../../../components/TextInput';
import BoxyLayoutTextInputsWrapper from '../../../../components/TextInput/BoxyLayoutTextInputsWrapper';

function OneButtonLayoutForm({ handleSubmit, error: responseError, handleCancel, themeContext, resetState }) {
  const { formikValidation } = useForgetPassword({ handleSubmit });
  const {
    isValid,
    isSubmitting,
    handleBlur,
    setFieldValue,
    handleSubmit: submitForm,
    values,
    errors,
  } = formikValidation;
  const readyToSend = isValid && values.email !== '' && !isSubmitting;

  const handleValueChange = e => {
    setFieldValue('email', trimInputString(e.target.value));
    if (responseError) resetState();
  };

  return (
    <TermsModalWrapper
      isVisible={true}
      closeModal={handleCancel}
      title={getText(TEXT_KEY.FORGOT_PASSWORD_TITLE)}
      zIndex={101}
      headerStyle={themeContext.ForgotPassword.ModalHeaderStyle}
      headerImageStyle={themeContext.ForgotPassword.ModalHeaderImageStyle}
      modalMainBodyStyle={themeContext.ForgotPassword.ModalMainBodyStyle}
      bodyStyle={themeContext.ForgotPassword.ModalBody}
      modalContainerStyle={themeContext.ForgotPassword.ModalContainer}
      removeLeftPart
    >
      <div style={styles.modal}>
        <PageDescription>{getText(TEXT_KEY.ENTER_YOUR_REGISTERED_EMAIL)} </PageDescription>

        {responseError && <ErrorMessage>{responseError}</ErrorMessage>}

        <FormWrapper>
          <BoxyLayoutTextInputsWrapper
            icon={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_REGULAR)}
            iconError={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_ERROR)}
            error={errors?.email}
          >
            <TextInput
              theme={themeContext.SignUp.FormInputTheme}
              icon
              boxErrorLayout
              error={errors?.email}
              type="text"
              maxLength="100"
              value={values.email}
              onChange={e => handleValueChange(e)}
              onBlur={handleBlur('email')}
              placeholder={getText(TEXT_KEY.EMAIL)}
            />
          </BoxyLayoutTextInputsWrapper>
          <FormButtonContainer>
            <Button
              isDebounce
              imageSource={
                readyToSend
                  ? assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)
                  : assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE)
              }
              disabled={!readyToSend}
              imageStyle={styles.wideButtonStyle}
              containerStyle={styles.wideButtonStyle}
              label={getText(TEXT_KEY.SUBMIT)}
              onClick={submitForm}
            />
          </FormButtonContainer>
        </FormWrapper>
      </div>
    </TermsModalWrapper>
  );
}

export default OneButtonLayoutForm;

const FormButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const PageDescription = styled.p`
  text-align: center;
  margin: 1rem 0 !important;
`;

const ErrorMessage = styled(PageDescription)`
  color: ${theme.palette.error[1]};
`;

const FormWrapper = styled.div`
  height: 100%;
  width: 90%;
  max-width: 800px;
`;

