import theme from '../assets/style/theme';
import generateRender from './generateRender';
export var getLinkTextClientColor = generateRender({
  hri: '#cda228',
  sga: '#f1894d',
  millelacs: theme.palette.primary[9],
  ti: '#ed1c8f',
  jamul: '#ffa400',
  ladyluck: '#6cb6e5',
  default: theme.palette.common[1]
});