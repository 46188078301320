import { useState, useEffect } from 'react';
import { PINNED_PANEL_CONTAINER_ID } from './../constants';

let initialPosition = { left: 0, bottom: 0, width: 0 };

function useMobileSliderDotPosCalculator() {
  const [position, setPosition] = useState(initialPosition);

  const calculatePosition = () =>
    setTimeout(() => {
      const targetContainer = document.getElementById(PINNED_PANEL_CONTAINER_ID);
      if (targetContainer) {
        const targetRect = targetContainer.getBoundingClientRect();
        const contentPosition = {
          left: targetRect.left,
          bottom: window.innerHeight - (targetRect.y + targetRect.height + 30),
          width: targetRect.width,
        };
        setPosition(contentPosition);
        initialPosition = contentPosition;
      }
    }, 1000);

  useEffect(() => {
    calculatePosition();
    window.addEventListener('resize', calculatePosition);
    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, []);

  return { position };
}

export default useMobileSliderDotPosCalculator;

