import generateRender from 'shared/utils/generateRender';
import { DEFAULT_RESET_PASSWORD_TEXTS } from '../constants';

export const RESET_PASSWORD_TEXTS = generateRender({
  default: DEFAULT_RESET_PASSWORD_TEXTS,
});

export const GENERATE_TEXTS = (local = false, getText) => {
  if (local) {
    return key => RESET_PASSWORD_TEXTS[key];
  }
  return key => getText(key);
};

