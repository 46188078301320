import styled from 'styled-components';
import { isMobile, isTablet } from 'react-device-detect';
import { isIPadMini, pwaStyleAdapter } from '../../utils/mobileSupported';
import theme from 'shared/assets/style/theme';

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StarTermsContainer = styled.div`
  height: 30% !important;
`;

export const TermsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'space-around';
  width: '90%';
  height: '90%';
`;

export const TermsUpdateScreenTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${isTablet ? pwaStyleAdapter(1.5, 'rem') : `calc(14px + (20 - 14) * ((100vw - 800px) / (1600 - 800)))`};
  margin-bottom: ${isTablet ? '3rem' : isMobile ? '1rem' : 0};
  ${isTablet && !isIPadMini && 'line-height: 2rem;'}
`;

export const TermsImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const TermsImage = styled.img`
  pointer-events: auto;
  cursor: pointer;
  width: 100%;
  height: auto;
`;

export const TermsFooterWrapper = styled.div`
  justify-content: space-between !important;
  height: 10% !important;
  margin-top: 3%;
`;

export const TermsUpdateButtonText = styled.p`
  font-weight: 700 !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ termsState }) => (termsState ? theme.palette.primary[28] : theme.palette.primary[1])};
`;

export const GeneralSignImModalTopSpace = styled.div`
  height: 25%;
`;

export const SingInModalContainer = styled.div`
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.5vmin;
`;

export const ModalButtonWrapper = styled.div`
  padding-top: 2vmin;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 1vmin;
`;

export const ModalFooterTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5vmin;
`;

export const TermsItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
`;

export const TermsAgreeText = styled.p`
  font-weight: 300;
  font-size: ${isTablet ? pwaStyleAdapter(1.2, 'rem') : isMobile ? pwaStyleAdapter(0.9, 'rem') : '1rem'};
  color: ${theme.palette.common[1]};
  padding-left: 1.5vmin !important;
  cursor: pointer;
`;

