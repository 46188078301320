export const getResponsiveValue = ({
  windowHeight,
  conditions,
  defaultValue,
  conditionFn = (windowHeight, limit) => windowHeight < limit,
}) => {
  for (const [limit, value] of conditions) {
    if (windowHeight && conditionFn(windowHeight, limit)) return value;
  }
  return defaultValue;
};

