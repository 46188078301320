import React, { Component } from 'react';
import styled from 'styled-components';
import SLinkAccount from 'shared/screens/LinkAccount';
import './LinkAccount.scss';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Button from '../../components/Button/Button';
import ModalHeader from './components/LinkAccountHeader';

export default class UnityLinkAccount extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-account',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  handleLinkAccountClick = parentComponentMethod => async () => {
    parentComponentMethod();
    return false;
  };

  render() {
    const themeContext = this.context;

    return (
      <SLinkAccount
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { linkAccount, handleLinkAccountForm, hideLinkButton } = args;

          return (
            <div style={themeContext.LinkAccount.LinkAccountContainer}>
              <ModalHeader
                theme={themeContext.LinkAccount.LinkAccountTitle}
                title={getText(TEXT_KEY.UNITY_LINK_ACCOUNT_INFO_TITLE)}
                subTitle={getText(TEXT_KEY.UNITY_LINK_ACCOUNT_INFO_SUBTITLE)}
              />
              <MainImageWrapper>
                <MainImage src={linkAccount.icon} />
              </MainImageWrapper>
              {!hideLinkButton && (
                <ButtonWrapper>
                  <Button
                    imageSource={assetSource(ASSET_KEY.LINK_NOW_BUTTON)}
                    onClick={this.handleLinkAccountClick(handleLinkAccountForm)}
                    containerStyle={Styles.ButtonContainerStyle}
                    objectFit="contain"
                    imageStyle={Styles.ButtonImageStyle}
                  />
                </ButtonWrapper>
              )}
            </div>
          );
        }}
      />
    );
  }
}

UnityLinkAccount.hideOverlay = true;
UnityLinkAccount.contextType = ThemeContext;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: center;
`;

const MainImage = styled.img`
  object-fit: contain;
  height: 130%;
  width: 100%;
`;

const MainImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem;
  height: auto;
`;

const Styles = {
  ButtonContainerStyle: {
    width: '25%',
    height: '4rem',
  },
  ButtonImageStyle: {
    width: '25%',
  },
};

