import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import generateRender from 'shared/utils/generateRender';
import theme from 'shared/assets/style/theme';
import { pwaStyleAdapter } from '../../utils/mobileSupported';

const TextInputMillelacs = styled(Form.Control)`
  background: ${theme.palette.primary[32]};
  border-radius: 0.6em;
  border: none;
  height: ${pwaStyleAdapter(42, 'px')};
  color: ${theme.palette.common[1]};
  padding-left: ${({ icon }) => pwaStyleAdapter(icon ? 30 : 10, 'px')};
  font-size: ${pwaStyleAdapter(1, 'rem')};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${pwaStyleAdapter(1, 'rem')};
    color: ${theme.palette.common[1]};
  }
  &:focus {
    box-shadow: none;
    outline: none;
    background: ${theme.palette.primary[32]};
    border-radius: 0.6em;
    border: none;
    color: ${theme.palette.common[1]};
    outline: none;
    box-shadow: none;
  }

  ${({ error, boxErrorLayout }) => error && boxErrorLayout && BoxErrorLayout}
`;

const TextInputLadyLuck = styled(Form.Control)`
  background: ${theme.palette.primary[32]};
  border-radius: 0.6em;
  border: none;
  height: ${pwaStyleAdapter(42, 'px')};
  color: ${theme.palette.common[1]};
  padding-left: ${({ icon }) => pwaStyleAdapter(icon ? 30 : 10, 'px')};
  font-size: ${pwaStyleAdapter(1, 'rem')};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${pwaStyleAdapter(1, 'rem')};
    color: ${theme.palette.common[1]};
  }
  &:focus {
    background: ${theme.palette.primary[32]};
    border-radius: 0.6em;
    border: none;
    color: ${theme.palette.common[1]};
    outline: none;
    box-shadow: none;
    font-size: ${pwaStyleAdapter(1, 'rem')};
  }

  ${({ error, boxErrorLayout }) => error && boxErrorLayout && BoxErrorLayout}
`;

const TextInputJamul = styled(Form.Control)`
  background: ${theme.palette.primary[32]};
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.6em;
  height: ${pwaStyleAdapter(42, 'px')};
  color: ${theme.palette.common[1]};
  padding-left: ${({ icon }) => pwaStyleAdapter(icon ? 30 : 10, 'px')};
  font-size: ${pwaStyleAdapter(1, 'rem')};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${pwaStyleAdapter(1, 'rem')};
    color: ${theme.palette.common[1]};
  }
  &:focus {
    box-shadow: none;
    outline: none;
    background: ${theme.palette.primary[32]};
    border-radius: 0.6em;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: ${theme.palette.common[1]};
    outline: none;
    box-shadow: none;
    font-size: ${pwaStyleAdapter(1, 'rem')};
  }

  ${({ error, boxErrorLayout }) => error && boxErrorLayout && BoxErrorLayout}
`;

const BoxErrorLayout = css`
  border-bottom-left-radius: 0.6em;
  border-bottom-right-radius: 0.6em;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
  &:focus {
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
  }
`;

export const TextInputBootstrap = generateRender({
  default: TextInputJamul,
  millelacs: TextInputMillelacs,
  jamul: TextInputJamul,
  ladyluck: TextInputLadyLuck,
});

