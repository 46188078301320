import { useFormik } from 'formik';
import * as yup from 'yup';
import { getText, TEXT_KEY } from '../../../utils/localization';
import Utils from '../../../utils/platform';
function useForgetPassword(_ref) {
  var handleSubmit = _ref.handleSubmit;
  var formikValidation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSumbitHandler,
    valideOnMount: true
  });
  function onSumbitHandler(data, _ref2) {
    var setSubmitting = _ref2.setSubmitting;
    handleSubmit(data, setSubmitting);
  }
  return {
    formikValidation: formikValidation
  };
}
export default useForgetPassword;
var initialValues = {
  email: ''
};
var isWeb = Utils.isWebOrMobileWeb();
var validationSchema = yup.object({
  email: yup.string().email(isWeb ? function () {
    return getText(TEXT_KEY.INVALID_EMAIL_FORMAT);
  } : getText(TEXT_KEY.INVALID_EMAIL_FORMAT)).required(isWeb ? function () {
    return getText(TEXT_KEY.LOGIN_EMAIL_REQUIRED);
  } : getText(TEXT_KEY.LOGIN_EMAIL_REQUIRED))
});