import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { ACTION_COMMANDS_BUTTON_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import QueryParamProxy from '../../../utils/queryParamProxy';
import routeParamConstants from '../../../utils/routeParamConstants';
import { ASSET_KEY, assetSource } from '../../../utils/assetSource';
import { TYPEOF } from '../../../constants';
var generateImageSource = _defineProperty(_defineProperty(_defineProperty({}, ACTION_COMMANDS_BUTTON_TYPE.ACCEPT, assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)), ACTION_COMMANDS_BUTTON_TYPE.RESEND_AND_TIMER, assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)), "default", null);
var generateActionHandler = function generateActionHandler(modal, onClickAction, callback) {
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: onClickAction.actionType,
    data: {
      modal: modal,
      onClickAction: onClickAction,
      dismissModalHandler: dismissModalHandler
    }
  });
  if (typeof callback === TYPEOF.FUNCTION) callback();
};
export var generateButtonsRendering = function generateButtonsRendering(_ref) {
  var modal = _ref.modal;
  var buttons = modal.buttons;
  var buttonsRendering = [];
  buttons && Object.values(buttons).forEach(function (button) {
    var buttonType = button.buttonType,
      label = button.label,
      onClickAction = button.onClickAction;
    var buttonRendering = {
      label: label,
      buttonType: buttonType,
      imageSource: generateImageSource[buttonType] || generateImageSource['default'],
      action: function action(callback) {
        return generateActionHandler(modal, onClickAction, callback);
      }
    };
    buttonsRendering.push(buttonRendering);
  });
  return buttonsRendering;
};
export var checkButtonTitleAndStatus = function checkButtonTitleAndStatus(_ref2) {
  var buttonType = _ref2.buttonType,
    imageSource = _ref2.imageSource,
    label = _ref2.label,
    timeLeft = _ref2.timeLeft,
    triggerInterval = _ref2.triggerInterval;
  var isResetAndTimerButton = buttonType === ACTION_COMMANDS_BUTTON_TYPE.RESEND_AND_TIMER;
  var disabled = !isResetAndTimerButton ? false : isResetAndTimerButton && timeLeft > 0 ? true : false;
  var actionHandlerCallback = function actionHandlerCallback() {
    if (isResetAndTimerButton) {
      if (typeof triggerInterval === TYPEOF.FUNCTION) triggerInterval();
    } else {
      dismissModalHandler();
    }
  };
  return {
    buttonImage: !disabled ? imageSource : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED),
    disabled: disabled,
    buttonLabel: disabled ? formatTimeLeft(timeLeft) : label,
    actionHandlerCallback: actionHandlerCallback
  };
};
var dismissModalHandler = function dismissModalHandler() {
  return QueryParamProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, undefined));
};
var formatTimeLeft = function formatTimeLeft(timeLeft) {
  var minutes = Math.floor(timeLeft / 60);
  var seconds = timeLeft % 60;
  return "".concat(String(minutes).padStart(2, '0'), ":").concat(String(seconds).padStart(2, '0'));
};