import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import {
  UpdateTermsBackgroundImage,
  TermsContainer,
  ErrorText,
  TermsTextContainer,
  TermsUpdateScreenTextWrapper,
  TermsImageWrapper,
  TermsImage,
  TermsFooterWrapper,
  TermsUpdateButtonText,
  TermsAgreeText,
} from './styledComponents';
import SUpdateTerms from 'shared/screens/UpdateTerms';
import ThemeContext from 'shared/context/ThemeContext';
import ImageBW from '../../../components/ImageBoundsWrapper';
import TermsModalWrapper from '../../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class DefaultUpdateTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oauthLoading: false,
      statusMessage: null,
      emailPermissionAccepted: null,
    };
  }

  render() {
    return (
      <SUpdateTerms
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { setTerms, terms, onSubmit, error, disableClose, termsAndConditionsUrl, privacyPolicyUrl } = args;
          const themeContext = this.context;
          return (
            <>
              <UpdateTermsBackgroundImage alt="background" src={assetSource(ASSET_KEY.SPLASH_BG)} />
              <TermsModalWrapper
                isVisible={true}
                title={getText(TEXT_KEY.TERMS_AND_CONDITIONS_TITLE)}
                zIndex={101}
                modalStyle={isMobile ? themeContext.TermsModal.TermsAndPrivacyModal : null}
                disableClose={disableClose}
                hideCloseBtn={true}
              >
                <TermsContainer className="terms-container">
                  {error && <ErrorText>{error}</ErrorText>}
                  <TermsTextContainer>
                    <>
                      <TermsUpdateScreenTextWrapper>
                        {getText(TEXT_KEY.TERMS_UPDATE_SCREEN_TEXT)}
                      </TermsUpdateScreenTextWrapper>

                      <div>
                        <TermsImageWrapper>
                          <TermsImage
                            src={assetSource(ASSET_KEY.TERMS_CONDITIONS)}
                            alt=""
                            resizeMode={'contain'}
                            onClick={() => window.open(termsAndConditionsUrl)}
                          />
                        </TermsImageWrapper>

                        <TermsImageWrapper>
                          <TermsImage
                            src={assetSource(ASSET_KEY.PRIVACY_POLICY)}
                            alt=""
                            resizeMode={'contain'}
                            onClick={() => window.open(privacyPolicyUrl)}
                          />
                        </TermsImageWrapper>
                      </div>
                    </>
                  </TermsTextContainer>
                </TermsContainer>
                <TermsFooterWrapper className="terms-footer">
                  <div className="accept-terms-container">
                    <ImageBW
                      source={terms ? assetSource(ASSET_KEY.CHECKBOX) : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)}
                      ibw={require(asset`checkbox.ibw`)}
                      style={{
                        objectFit: 'contain',
                      }}
                      className="checkbox-image"
                      onClick={setTerms}
                    />
                    <TermsAgreeText qa-id="checkboxTermsAndConditions" onClick={setTerms}>
                      {getText(TEXT_KEY.AGREE_TERMS)}
                    </TermsAgreeText>
                  </div>
                  <div qa-id="continueBtn" className="continue-button-container" onClick={terms ? onSubmit : null}>
                    <ImageBW
                      source={
                        terms
                          ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
                          : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
                      }
                      ibw={require(asset`continue-button.ibw`)}
                      style={{ objectFit: 'contain' }}
                      className="continue-button"
                      innerStyle={{
                        cursor: 'pointer',
                      }}
                    >
                      <div className="continue-button-content">
                        <TermsUpdateButtonText termsState={terms} className="continue-button-text">
                          {getText(TEXT_KEY.CONTINUE_BUTTON)}
                        </TermsUpdateButtonText>
                      </div>
                    </ImageBW>
                  </div>
                </TermsFooterWrapper>
              </TermsModalWrapper>
            </>
          );
        }}
      />
    );
  }
}

DefaultUpdateTerms.contextType = ThemeContext;

