import React, { useContext } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import useLogin from 'shared/screens/Login/useLogin';
import ThemeContext from 'shared/context/ThemeContext';
import CustomScrollBar from '../../components/CustomScrollBar';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import LoginButton from './components/LoginButton';
import {
  Container,
  Status,
  LoginModalTopOffsetMobile,
  LoginModalTopOffsetMobilePWA,
  LoginModalTopOffsetNoScrollMobile,
  LoginModalTopOffsetNoScrollMobilePWA,
} from './styles';
import FormInputs from './components/FormInputs';
import useUpdateHeight from '../../hooks/useUpdateHeight';
import { isStandalonePWA } from '../../utils/mobileSupported';
import useKeyboardAwareModal from './../../hooks/useKeyboardAwareModal';
import { getResponsiveValue } from '../../utils/globalMethods';

const Login = ({ navigation }) => {
  const { formikValidation, closeModal, openForgotPassword, isLoading, onChangeHandler } = useLogin(navigation);
  const { windowHeight } = useUpdateHeight(0);
  const topOffsetWithScroll = getResponsiveValue({
    windowHeight,
    conditions: isStandalonePWA ? LoginModalTopOffsetMobilePWA : LoginModalTopOffsetMobile,
    defaultValue: isStandalonePWA ? 130 : 30,
  });
  const topOffsetNoScroll = getResponsiveValue({
    windowHeight,
    conditions: isStandalonePWA ? LoginModalTopOffsetNoScrollMobilePWA : LoginModalTopOffsetNoScrollMobile,
    defaultValue: isStandalonePWA ? 200 : 200,
  });
  useKeyboardAwareModal({ topOffsetWithScroll, topOffsetNoScroll });
  const { values, errors, status, handleSubmit, setFieldValue, handleBlur } = formikValidation;

  const themeContext = useContext(ThemeContext);

  return (
    <TermsModalWrapper
      isVisible={true}
      closeModal={closeModal}
      title={getText(TEXT_KEY.LOGIN)}
      zIndex={101}
      modalContainerStyle={themeContext.Login.ModalContainer}
      headerStyle={themeContext.Login.HeaderStyle}
      bodyStyle={themeContext.Login.BodyStyle}
      modalStyle={themeContext.Login.ModalStyle}
    >
      <ContentWrapper>
        <Container>
          <FormInputs
            values={values}
            errors={errors}
            onChangeHandler={onChangeHandler}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            openForgotPassword={openForgotPassword}
            loginStyles={themeContext.Login}
          />
          {status && <Status>{status}</Status>}

          <LoginButton
            isValid={!isLoading}
            disabled={isLoading}
            textStroke={BUTTON_STROKES.PRIMARY}
            onClick={handleSubmit}
            label={getText(TEXT_KEY.LOGIN)}
            textStyle={themeContext.Login.LoginButtonText}
          />
        </Container>
      </ContentWrapper>
    </TermsModalWrapper>
  );
};

export default Login;

const ContentWrapper = ({ children }) => {
  if (isMobile && !isTablet) {
    return children;
  }
  return <CustomScrollBar>{children}</CustomScrollBar>;
};

