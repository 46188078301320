import store from '../../state/store';
import { imageSource } from '../../manifests/imageSource';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { FE_ASSETS_CDN } from '../featureFlags/constants';
export var assetSource = function assetSource(key) {
  var _store$getState;
  var _useFeatureFlags = useFeatureFlags(),
    hasFeature = _useFeatureFlags.hasFeature;
  var assetsManager = store === null || store === void 0 || (_store$getState = store.getState()) === null || _store$getState === void 0 || (_store$getState = _store$getState.assetsPackage) === null || _store$getState === void 0 ? void 0 : _store$getState.assets;
  var asset = (assetsManager === null || assetsManager === void 0 ? void 0 : assetsManager.MAIN) || (assetsManager === null || assetsManager === void 0 ? void 0 : assetsManager.LOCALIZATION);
  return asset && key in asset && hasFeature(FE_ASSETS_CDN) ? asset[key] : imageSource[key];
};