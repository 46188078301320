import { useEffect, useState } from 'react';
import useCheckOrientation from './useCheckOrientation';
function useUpdateHeight(delay = 1000) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const { isLandscape } = useCheckOrientation();

  useEffect(() => {
    if (isLandscape) {
      setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, delay);
    }
  }, [isLandscape]);

  return {
    windowHeight,
  };
}

export default useUpdateHeight;

