import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';
import generateRender from 'shared/utils/generateRender';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../utils/mobileSupported';
import { isMobile, isTablet } from 'react-device-detect';

const mobileNotBookmarked = isNotStandaloneMobilePWA && !isTablet;
const windowWidth = window.innerWidth;
const LoyaltyPointsLogo = styled.img`
  height: 5rem;
  width: 5rem;
  object-fit: contain;

  @media screen and (max-width: 1500px) {
    height: 3rem;
    width: 3rem;
    ${({ theme }) => theme}
  }
  ${({ theme }) => theme}
`;

const DefaultPriceWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

const LadyluckPriceWrapper = styled(DefaultPriceWrapper)`
  position: relative;
  top: ${isTablet ? (isStandalonePWA && windowWidth > 1150 ? -1 : 0) : mobileNotBookmarked ? 0 : -1}rem;
  @media screen and (max-height: 960px) {
    top: ${isTablet ? (isStandalonePWA && windowWidth > 1150 ? -2 : -1) : mobileNotBookmarked ? -1 : -2}rem;
  }
`;

const JamulPriceWrapper = styled(DefaultPriceWrapper)`
  margin-left: 0.5rem;
`;

const DefaultButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: 0.7rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1600px) {
    margin-bottom: 0rem;
  }
`;

const LadyluckButtonWrapperTablet = css`
  position: relative;
  top: -2rem;
`;

const LadyluckButtonWrapper = styled(DefaultButtonWrapper)`
  @media screen and (max-height: 800px) {
    position: relative;
    top: -2rem;
    ${isTablet && LadyluckButtonWrapperTablet}
  }
  @media screen and (min-height: 800px) and (max-height: 960px) {
    position: relative;
    top: -1.5rem;
    ${isTablet && LadyluckButtonWrapperTablet}
  }
  ${isTablet && LadyluckButtonWrapperTablet}
`;

const JamulButtonWrapper = styled(DefaultButtonWrapper)`
  @media screen and (max-height: 800px) {
    position: relative;
    top: -0.8rem;
  }
  @media screen and (min-height: 800px) and (max-height: 960px) {
    position: relative;
    top: -0.5rem;
  }
`;

const DefaultItemPlusOffer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24%;
  padding-top: 0px;
  width: 100%;
  @media screen and (max-width: 1550px) {
    height: 26%;
    padding-top: 1rem;
    ${({ theme }) => theme}
  }

  @media screen and (max-width: 1300px) and (min-height: 900px) {
    height: 30%;
  }

  ${({ theme }) => theme}
`;

const LadyluckItemPlusOfferMobile = css`
  top: ${isNotStandaloneMobilePWA ? -0.3 : -2}rem;
  @media screen and (max-height: 960px) {
    top: ${isNotStandaloneMobilePWA ? -1.8 : -3}rem;
  }

  @media screen and (max-height: 750px) {
    top: ${isNotStandaloneMobilePWA ? -1.3 : -3}rem;
  }
`;

const LadyluckItemPlusOfferTablet = css`
  top: ${windowWidth > 1150 ? (!isStandalonePWA ? -1.5 : -2.5) : -2}rem;
  @media screen and (max-height: 960px) {
    top: ${windowWidth > 1150 ? (!isStandalonePWA ? -1.5 : -2.5) : -2}rem;
  }

  @media screen and (max-height: 750px) {
    top: -1.5rem;
  }
`;

const LadyluckItemPlusOffer = styled(DefaultItemPlusOffer)`
  padding-top: 0.5rem;
  position: relative;
  top: -3rem;
  @media screen and (max-height: 960px) {
    top: -4rem;
  }
  ${isMobile && !isTablet && LadyluckItemPlusOfferMobile}
  ${isTablet && LadyluckItemPlusOfferTablet}
`;

const JamulItemPlusOfferMobile = css`
  top: ${mobileNotBookmarked ? -0.7 : -1}rem;
  @media screen and (max-height: 960px) {
    top: ${mobileNotBookmarked ? -1 : -2}rem;
  }

  @media screen and (max-height: 750px) {
    top: ${mobileNotBookmarked ? -0.7 : -2}rem;
  }
`;

const JamulItemPlusOfferTablet = css`
  top: ${windowWidth > 1150 ? (!isStandalonePWA ? -1.3 : -2.3) : -1.8}rem;
  @media screen and (max-height: 960px) {
    top: ${windowWidth > 1150 ? (!isStandalonePWA ? -1.3 : -2.3) : -1.8}rem;
  }

  @media screen and (max-height: 750px) {
    top: -1.3rem;
  }
`;

const JamulItemPlusOffer = styled(DefaultItemPlusOffer)`
  padding-top: 0.5rem;
  position: relative;
  top: -2.5rem;
  @media screen and (max-height: 960px) {
    top: -3.3rem;
  }

  @media screen and (max-height: 750px) {
    top: -3rem;
  }
  ${isTablet && JamulItemPlusOfferTablet}
  ${isMobile && !isTablet && JamulItemPlusOfferMobile}
`;

const DefaultVIPAccessWrapper = styled.div`
  font-size: 2.3vmin;
  @media screen and (max-width: 1550px) {
    font-size: 2vmin;
  }
`;

const LadyluckVIPAccessWrapperTablet = css`
  top: -2.5rem;
  @media screen and (max-height: 800px) {
    top: -2.5rem;
  }
`;

const LadyluckVIPAccessWrapperMobile = css`
  top: ${isNotStandaloneMobilePWA ? -1.1 : -2.8}rem;
  @media screen and (max-height: 800px) {
    top: ${isNotStandaloneMobilePWA ? -1.1 : -2.8}rem;
  }
`;

const LadyluckVIPAccessWrapper = styled(DefaultVIPAccessWrapper)`
  position: relative;
  top: -4.5rem;
  @media screen and (max-height: 800px) {
    top: -5rem;
  }
  ${isTablet && LadyluckVIPAccessWrapperTablet}
  ${isMobile && !isTablet && LadyluckVIPAccessWrapperMobile}
`;

const JamulVIPAccessWrapperMobile = css`
  top: ${isNotStandaloneMobilePWA ? -1.5 : -3}rem;
  @media screen and (max-height: 800px) {
    top: ${isNotStandaloneMobilePWA ? -1.5 : -3}rem;
  }
`;

const JamulVIPAccessWrapperTablet = css`
  top: -2.7rem;
  @media screen and (max-height: 800px) {
    top: -2.7rem;
  }
`;

const JamulVIPAccessWrapper = styled(DefaultVIPAccessWrapper)`
  position: relative;
  top: -4.5rem;
  @media screen and (max-height: 800px) {
    top: -5rem;
  }
  ${isMobile && !isTablet && JamulVIPAccessWrapperMobile}
  ${isTablet && JamulVIPAccessWrapperTablet}
`;

const ItemPrice = styled.div`
  font-size: 1.8rem;
  @media screen and (max-width: 1550px) {
    font-size: 1.3rem;
  }
`;

const PlusLoyaltyText = styled.div`
  font-size: 1.8rem;
  @media screen and (max-width: 1550px) {
    font-size: 1.3rem;
  }
  ${({ theme }) => theme}
`;

const positionStyles = isTablet
  ? { top: '-7%', right: '-12px', bottom: '-7%', left: '-12px' }
  : { top: '29px', right: '0px', bottom: '29px', left: '0px' };

const Styles = {
  buyButtonImage: {
    width: '70%',
    height: '2.5rem',
    cursor: 'pointer',
  },
  buyButtonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
    height: '4rem',
  },
  buyButtonText: {
    fontSize: '26px',
  },
  tileInnerStyle: {
    ...positionStyles,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common[1],
  },
};

const defaultVIPPackTitle = css`
  padding-top: ${!isMobile ? '41%' : isTablet ? '44%' : '58%'};
  ${!isMobile &&
  `@media screen and (max-width: 1300px) and (min-height: 880px) {
        padding-top: 55%;
      }`}
`;

const VIPPackTitleWithIcon = css`
  padding-top: 10px;
`;

const packageTitleTextStyle = generateRender({
  default: defaultVIPPackTitle,
  hri: VIPPackTitleWithIcon,
  millelacs: VIPPackTitleWithIcon,
  jamul: VIPPackTitleWithIcon,
  ladyluck: VIPPackTitleWithIcon,
});

const DefaultDurationFriendlyFull = styled.div`
  font-size: 2.2rem;
  line-height: 1;
  font-weight: 700;

  @media screen and (max-width: 1550px) {
    font-size: 1.7rem;
  }
  ${packageTitleTextStyle}
`;

const LadyluckDurationFriendlyFullTablet = css`
  top: -2.5rem;
  font-size: 2.2rem;
  @media screen and (max-height: 800px) {
    font-size: 2.1rem;
    top: -2.5rem;
  }
`;

const LadyluckDurationFriendlyFullMobile = css`
  top: ${isNotStandaloneMobilePWA ? -1.3 : -3}rem;
  font-size: 2.8rem;
  @media screen and (max-height: 800px) {
    font-size: 2.3rem;
    top: ${isNotStandaloneMobilePWA ? -1.3 : -2.8}rem;
  }
`;

const LadyluckDurationFriendlyFull = styled(DefaultDurationFriendlyFull)`
  position: relative;
  top: -4.5rem;
  font-size: 2.8rem;
  @media screen and (max-height: 800px) {
    font-size: 2.3rem;
    top: -5.2rem;
  }
  ${isTablet && LadyluckDurationFriendlyFullTablet}
  ${isMobile && !isTablet && LadyluckDurationFriendlyFullMobile}
`;

const JamulDurationFriendlyFullTablet = css`
  top: -2.5rem;
  font-size: 2.2rem;
  @media screen and (max-height: 800px) {
    font-size: 2.1rem;
    top: -2.5rem;
  }
`;

const JamulDurationFriendlyFullMobile = css`
  top: ${isNotStandaloneMobilePWA ? -1.5 : -3}rem;
  font-size: 2.8rem;
  @media screen and (max-height: 800px) {
    font-size: 2.3rem;
    top: ${isNotStandaloneMobilePWA ? -1.5 : -2.8}rem;
  }
`;

const JamulDurationFriendlyFull = styled(DefaultDurationFriendlyFull)`
  position: relative;
  top: -4.5rem;
  font-size: 2.8rem;
  @media screen and (max-height: 800px) {
    font-size: 2.3rem;
    top: -5.2rem;
  }
  ${isMobile && !isTablet && JamulDurationFriendlyFullMobile}
  ${isTablet && JamulDurationFriendlyFullTablet}
`;

const ShopStyles = generateRender({
  default: {
    PriceWrapper: DefaultPriceWrapper,
    ButtonWrapper: DefaultButtonWrapper,
    ItemPlusOffer: DefaultItemPlusOffer,
    VIPAccessWrapper: DefaultVIPAccessWrapper,
    DurationFriendlyFull: DefaultDurationFriendlyFull,
  },
  ladyluck: {
    PriceWrapper: LadyluckPriceWrapper,
    ButtonWrapper: LadyluckButtonWrapper,
    ItemPlusOffer: LadyluckItemPlusOffer,
    VIPAccessWrapper: LadyluckVIPAccessWrapper,
    DurationFriendlyFull: LadyluckDurationFriendlyFull,
  },
  jamul: {
    PriceWrapper: JamulPriceWrapper,
    ButtonWrapper: JamulButtonWrapper,
    ItemPlusOffer: JamulItemPlusOffer,
    VIPAccessWrapper: JamulVIPAccessWrapper,
    DurationFriendlyFull: JamulDurationFriendlyFull,
  },
});

const { PriceWrapper, ButtonWrapper, ItemPlusOffer, VIPAccessWrapper, DurationFriendlyFull } = ShopStyles;

export {
  LoyaltyPointsLogo,
  PriceWrapper,
  ButtonWrapper,
  ItemPlusOffer,
  VIPAccessWrapper,
  ItemPrice,
  PlusLoyaltyText,
  Styles,
  DurationFriendlyFull,
};

