import generateRender from './generateRender';
import theme from '../assets/style/theme';
var scrollBarTrackColor = generateRender({
  default: theme.palette.secondary[27],
  sga: theme.palette.secondary[41],
  ti: 'rgba(11, 39, 53, 1)',
  ladyluck: 'rgba(2255,255,255,0.5)'
});
var scrollBarThumbColor = generateRender({
  default: theme.palette.primary[34],
  sga: 'rgba(241, 133, 76, 1)',
  ti: theme.palette.primary[31]
});
export { scrollBarTrackColor, scrollBarThumbColor };