import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { AUTH_TYPES } from '../../../utils/authFlow/constants';
import { INVITE_FRIENDS_FEATURE_STATUS } from '../constants';
import { useGetFriendsInviteQuery } from '../../../state/query/friendsInvite';
import Logger from '../../../lib/analytics/logger';
import { FEATURES_LIST } from '../../../constants/types';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import { HTTP_STATUS } from '../../../state/api/services/utils/constants';
var useFriendsInvite = function useFriendsInvite(_ref) {
  var skip = _ref.skip;
  var _useSelector = useSelector(function (state) {
      var _state$token$lastLogi;
      return {
        loginMethod: (_state$token$lastLogi = state.token.lastLoginInfo) === null || _state$token$lastLogi === void 0 ? void 0 : _state$token$lastLogi.loginMethod,
        friendsInviteAssets: state.assetsPackage.assets.FRIENDS_INVITE_FEATURE
      };
    }, shallowEqual),
    loginMethod = _useSelector.loginMethod,
    friendsInviteAssets = _useSelector.friendsInviteAssets;
  var _useGetFriendsInviteQ = useGetFriendsInviteQuery({
      requestAssetsData: true
    }, {
      forceRefetch: true,
      skip: skip
    }),
    data = _useGetFriendsInviteQ.data,
    isLoading = _useGetFriendsInviteQ.isLoading,
    isError = _useGetFriendsInviteQ.isError,
    _useGetFriendsInviteQ2 = _useGetFriendsInviteQ.error,
    error = _useGetFriendsInviteQ2 === void 0 ? null : _useGetFriendsInviteQ2;
  useEffect(function () {
    Logger.sendEvent(EVENT_TYPES.GTM_FEATURE_ENGAGEMENT, {
      feature: FEATURES_LIST.FRIENDS_INVITE
    });
  }, []);
  var friendsInviteFeatureState = data === null || data === void 0 ? void 0 : data.friendsInviteFeatureState;
  var inviteSetup = friendsInviteFeatureState === null || friendsInviteFeatureState === void 0 ? void 0 : friendsInviteFeatureState.inviteSetup;
  var inviteLink = friendsInviteFeatureState === null || friendsInviteFeatureState === void 0 ? void 0 : friendsInviteFeatureState.inviteLink;
  var friendsInviteGameplayStatus = inviteSetup === null || inviteSetup === void 0 ? void 0 : inviteSetup.friendsInviteGameplayStatus;
  var limitReached = (inviteLink === null || inviteLink === void 0 ? void 0 : inviteLink.maxRedeems) - (inviteLink === null || inviteLink === void 0 ? void 0 : inviteLink.redeemsCount) <= 0;
  return {
    inviteErrorNotFound: (error === null || error === void 0 ? void 0 : error.status) === HTTP_STATUS.NOT_FOUND,
    inviteLoading: isLoading,
    friendsInviteError: isError,
    friendsInviteAssets: friendsInviteAssets,
    friendsInviteFeatureState: friendsInviteFeatureState,
    isGuestUser: loginMethod === AUTH_TYPES.GUEST,
    hasActiveState: friendsInviteGameplayStatus === INVITE_FRIENDS_FEATURE_STATUS.RUNNING,
    hasComingSoonState: friendsInviteGameplayStatus === INVITE_FRIENDS_FEATURE_STATUS.COMING_SOON,
    hasPendingState: friendsInviteGameplayStatus === INVITE_FRIENDS_FEATURE_STATUS.PENDING,
    limitReached: limitReached
  };
};
export default useFriendsInvite;