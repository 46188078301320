import React, { Children, cloneElement, useRef, useEffect, useState } from 'react';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import useCustomScrollBar from './hooks/useCustomScrollBar';
import useDragToScroll from './hooks/useDragToScroll';
import { ArrowButton } from './styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default function CustomScrollBar({
  customWidth = '100%',
  children,
  showByDefault,
  autoHideBehaviour = 'never',
  timeout = 0,
  customStyle = {},
  containerStyle = {},
  customArrowStyle = {},
  customClassNames = '',
  arrowClickScrollAmount = 320,
  showHorizontal = false,
  showVertical = true,
  showHorizontalArrows = false,
  isDraggableX = false,
  isDraggableY = false,
  scrollToPosition = null,
  onScrollCallback = null,
  updateKey = 0,
  updateScrollInstance = useRef(() => null),
}) {
  const scrollBoxRef = useRef();
  const viewportRef = useRef();
  const instanceRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const [initialize, instance] = useOverlayScrollbars({
    options: {
      scrollbars: {
        clickScroll: 'instant',
        visibility: showByDefault ? 'visible' : 'auto',
        autoHideDelay: timeout,
        autoHide: autoHideBehaviour,
      },
      overflow: {
        x: showHorizontal ? 'scroll' : 'hidden',
        y: showVertical ? 'scroll' : 'hidden',
      },
      paddingAbsolute: true,
    },
  });

  const checkToSetScrollBar = () => {
    if (scrollBoxRef.current) {
      if (!instanceRef.current) {
        initialize(scrollBoxRef.current);
        if (instance) updateScrollInstance.current = () => instance().update();
        instanceRef.current = instance ? instance() : null;
      } else if (instanceRef.current.update) {
        instanceRef.current.update();
      }
      setOverlayScrollbarsInstance();
    }
  };

  useEffect(() => {
    checkToSetScrollBar();
    setTimeout(() => {
      checkToSetScrollBar();
    }, 300);
  }, [updateKey]);

  const enhancedChildren = Children.map(children, child => cloneElement(child, { scrollBoxRef: viewportRef }));

  const setOverlayScrollbarsInstance = () => {
    if (instance && instance()) {
      viewportRef.current = instance().elements().viewport;
      setIsInitialized(true);
      if (scrollToPosition !== null) {
        if (showVertical) {
          viewportRef.current.scrollTop = scrollToPosition;
        } else {
          viewportRef.current.scrollLeft = scrollToPosition;
        }
      }
    }
  };

  const handleLeftClick = () => handleArrowScrollClick('left');
  const handleRightClick = () => handleArrowScrollClick();

  useEffect(() => {
    if (isInitialized && viewportRef.current) {
      const handleScroll = () => onScrollCallback && onScrollCallback();

      viewportRef.current.addEventListener('scroll', handleScroll);
      return () => {
        viewportRef.current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isInitialized, onScrollCallback]);

  useEffect(() => {
    if (scrollToPosition !== null && viewportRef.current) {
      requestAnimationFrame(() => {
        if (viewportRef.current) {
          if (showVertical) {
            viewportRef.current.scrollTop = scrollToPosition;
          } else {
            viewportRef.current.scrollLeft = scrollToPosition;
          }
        }
      });
    }
  }, [scrollToPosition, showVertical]);

  useDragToScroll({ scrollBoxRef: viewportRef, isDraggableX, isDraggableY, isInitialized });
  const { handleArrowScrollClick } = useCustomScrollBar({ arrowClickScrollAmount, scrollBoxRef: viewportRef });

  return (
    <>
      <div
        ref={scrollBoxRef}
        className={`custom-scrollbar ${customClassNames}`}
        style={{
          marginRight: '-2px',
          width: customWidth ? customWidth : '100%',
          ...containerStyle,
        }}
      >
        <div style={customStyle}>{enhancedChildren}</div>
      </div>
      {showHorizontal && showHorizontalArrows && (
        <div style={{ position: 'relative', width: customWidth }}>
          <LeftArrow onClick={handleLeftClick} customArrowStyle={customArrowStyle} />
          <RightArrow onClick={handleRightClick} customArrowStyle={customArrowStyle} />
        </div>
      )}
    </>
  );
}
const LeftArrow = ({ onClick, customArrowStyle }) => (
  <ArrowButton src={assetSource(ASSET_KEY.BACK_ARROW)} onClick={onClick} customArrowStyle={customArrowStyle} />
);

const RightArrow = ({ onClick, customArrowStyle }) => (
  <ArrowButton
    isRightArrow
    src={assetSource(ASSET_KEY.BACK_ARROW)}
    onClick={onClick}
    customArrowStyle={customArrowStyle}
  />
);

