import React from 'react';
import { FTUE_STEPS_KEYS, TAG } from 'shared/constants';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../../utils/mobileSupported';
import { isTablet } from 'react-device-detect';
import LazyImage from '../../LazyImage/LazyImage';

const Home = ({ showHomeButton, navigateBack, isHome, ftueStep, themeContext, getNavStateAnimationClassName }) => {
  return (
    <div
      onClick={showHomeButton() ? navigateBack : () => {}}
      onTouchStart={showHomeButton() ? navigateBack : () => {}}
      className={`HomeIcon ${
        isHome || ftueStep > FTUE_STEPS_KEYS.HIGHLIGHT_A_GAME || !showHomeButton() ? 'disable' : ''
      } ${isTablet ? 'tablet' : ''} ${getNavStateAnimationClassName(!showHomeButton())}`}
      style={{ margin: 0 }}
    >
      <LazyImage
        tag={TAG.IMG}
        src={assetSource(ASSET_KEY.HOME_BUTTON)}
        alt="home"
        style={pwaStyleAdapter(themeContext.NavigationBar.HomeIcon)}
      />
    </div>
  );
};

export default Home;

