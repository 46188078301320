import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import SRewardCenterOnboarding from 'shared/screens/RewardCenter/Onboarding';
import { isNotStandaloneMobilePWA } from '../../utils/mobileSupported';

export default class RewardCenterOnboarding extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-reward-center-onboarding',
    };
  }

  render() {
    return (
      <SRewardCenterOnboarding
        navigation={this.props.navigation}
        dismissModal={this.props.dismissModal}
        render={args => {
          const { clickHandler, rewardCenterOnboardingAsset } = args;

          return (
            <OnboardingContainer onClick={clickHandler}>
              <OnboardingImage src={rewardCenterOnboardingAsset} alt="Reward Center Onboarding" />
            </OnboardingContainer>
          );
        }}
      />
    );
  }
}

RewardCenterOnboarding.hideDefaultBackground = true;

const windowHeight = window.innerHeight;

const OnboardingContainer = styled.div`
  cursor: pointer;
`;

const OnboardingImageSmall = css`
  width: ${windowHeight > 340 ? 88 : windowHeight > 300 ? 94 : 86}%;
  height: ${windowHeight > 340 ? 88 : windowHeight > 300 ? 94 : 86}%;
  margin-left: ${windowHeight > 340 ? 6 : windowHeight > 300 ? 3 : 7}%;
`;

const OnboardingImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1em;
  ${isNotStandaloneMobilePWA && OnboardingImageSmall}
`;
