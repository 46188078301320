import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from '@reduxjs/toolkit';
import { ASSETS_KEYS } from '../../../constants';
import utils from '../../../utils';
var sliceName = 'ASSETS_PACKAGE';
var initialState = {
  lastRevisionNumber: {},
  assetsLoaded: false,
  assets: {}
};
var isWebPlatform = utils.isWebOrMobileWeb();
var buildUrl = function buildUrl(baseUrl, key, value) {
  var filename = typeof value === 'number' ? key : value === null || value === void 0 ? void 0 : value.filename;
  var revision = typeof value === 'number' ? value : value === null || value === void 0 ? void 0 : value.revision;
  return "".concat(baseUrl).concat(filename, "?revision=").concat(revision);
};
var normalizeLastRevisionNumber = function normalizeLastRevisionNumber(lastRevisionNumber) {
  var normalized = {};
  for (var key in lastRevisionNumber) {
    var value = lastRevisionNumber[key];
    if (typeof value === 'object' && value.revision !== undefined) normalized[value.filename] = value.revision;else normalized[key] = value;
  }
  return normalized;
};
var assetsPackageSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    assetsPackageRequestError: function assetsPackageRequestError(state, action) {
      state.errorMsg = action.payload;
    },
    assetsPackageRequestDataSuccess: function assetsPackageRequestDataSuccess(state, action) {
      var _action$payload = action.payload,
        items = _action$payload.items,
        assetsPackageCategory = _action$payload.assetsPackageCategory,
        baseUrl = _action$payload.baseUrl;
      var _loop = function _loop() {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          itemKey = _Object$entries$_i[0],
          itemValue = _Object$entries$_i[1];
        var assetKey = Object.keys(ASSETS_KEYS).find(function (key) {
          return ASSETS_KEYS[key] === itemKey;
        });
        if (assetKey) {
          var assetUrl = buildUrl(baseUrl, itemKey, itemValue);
          state.assets[assetsPackageCategory] = state.assets[assetsPackageCategory] || {};
          state.assets[assetsPackageCategory][assetKey] = isWebPlatform ? assetUrl : {
            uri: assetUrl
          };
        }
      };
      for (var _i = 0, _Object$entries = Object.entries(items); _i < _Object$entries.length; _i++) {
        _loop();
      }
    },
    assetsPackageRequestListDataSuccess: function assetsPackageRequestListDataSuccess(state, action) {
      for (var _i2 = 0, _Object$values = Object.values(action.payload); _i2 < _Object$values.length; _i2++) {
        var assetPackage = _Object$values[_i2];
        var items = assetPackage.items,
          assetsPackageCategory = assetPackage.assetsPackageCategory,
          assetsPackageId = assetPackage.assetsPackageId,
          baseUrl = assetPackage.baseUrl;
        var _loop2 = function _loop2() {
          var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i3], 2),
            itemKey = _Object$entries2$_i[0],
            itemValue = _Object$entries2$_i[1];
          var assetKey = Object.keys(ASSETS_KEYS).find(function (key) {
            return ASSETS_KEYS[key] === itemKey;
          });
          if (assetKey) {
            var assetUrl = buildUrl(baseUrl, itemKey, itemValue);
            state.assets[assetsPackageCategory] = state.assets[assetsPackageCategory] || {};
            state.assets[assetsPackageCategory][assetsPackageId] = state.assets[assetsPackageCategory][assetsPackageId] || {};
            state.assets[assetsPackageCategory][assetsPackageId][assetKey] = isWebPlatform ? assetUrl : {
              uri: assetUrl
            };
          }
        };
        for (var _i3 = 0, _Object$entries2 = Object.entries(items); _i3 < _Object$entries2.length; _i3++) {
          _loop2();
        }
      }
    },
    assetsPackageRequestInitApp: function assetsPackageRequestInitApp(state, action) {
      for (var _i4 = 0, _Object$values2 = Object.values(action.payload); _i4 < _Object$values2.length; _i4++) {
        var assetPackage = _Object$values2[_i4];
        var items = assetPackage.items,
          assetsPackageCategory = assetPackage.assetsPackageCategory,
          baseUrl = assetPackage.baseUrl;
        var _loop3 = function _loop3() {
          var _Object$entries3$_i = _slicedToArray(_Object$entries3[_i5], 2),
            itemKey = _Object$entries3$_i[0],
            itemValue = _Object$entries3$_i[1];
          var assetKey = Object.keys(ASSETS_KEYS).find(function (key) {
            return ASSETS_KEYS[key] === itemKey;
          });
          if (assetKey) {
            var assetUrl = buildUrl(baseUrl, itemKey, itemValue);
            state.assets[assetsPackageCategory] = state.assets[assetsPackageCategory] || {};
            state.assets[assetsPackageCategory][assetKey] = isWebPlatform ? assetUrl : {
              uri: assetUrl
            };
          }
        };
        for (var _i5 = 0, _Object$entries3 = Object.entries(items); _i5 < _Object$entries3.length; _i5++) {
          _loop3();
        }
      }
    },
    setAssetsLoaded: function setAssetsLoaded(state, action) {
      state.assetsLoaded = action.payload;
    },
    setStockControl: function setStockControl(state, action) {
      state.lastRevisionNumber = _objectSpread(_objectSpread({}, state.lastRevisionNumber), {}, _defineProperty({}, action.payload.assetsPackageId, normalizeLastRevisionNumber(action.payload.lastRevisionNumber)));
    }
  }
});
var actions = assetsPackageSlice.actions,
  reducer = assetsPackageSlice.reducer;
var assetsPackageRequestError = actions.assetsPackageRequestError,
  assetsPackageRequestDataSuccess = actions.assetsPackageRequestDataSuccess,
  assetsPackageRequestListDataSuccess = actions.assetsPackageRequestListDataSuccess,
  assetsPackageRequestInitApp = actions.assetsPackageRequestInitApp,
  setAssetsLoaded = actions.setAssetsLoaded,
  setStockControl = actions.setStockControl;
export { assetsPackageRequestError, assetsPackageRequestDataSuccess, assetsPackageRequestListDataSuccess, assetsPackageRequestInitApp, setAssetsLoaded, setStockControl };
export default reducer;