import React from 'react';
import { Spinner } from 'react-bootstrap';
import useAuthSwitchShared from 'shared/components/AuthSwitch/useAuthSwitch';
import AppBackgroundType from 'shared/utils/AppBackgroundType';
import AssetPreloader from '../AssetPreloader';
import useAuthSwitch from './useAuthSwitch';
import { getAppBackgroundImage } from './utils';
import LazyImage from '../LazyImage/LazyImage';
import { TAG } from 'shared/constants';
import useCacheLoadingScreenAsset from '../../hooks/useCacheLoadingScreenAsset';

const AuthSwitch = props => {
  useAuthSwitch();
  useCacheLoadingScreenAsset();
  const {
    descriptor,
    hasAssetsLoaded,
    initTextsLoaded,
    SceneView,
    appBackground,
    backgroundPromo,
    isConnected,
    promotionImagesUrl,
  } = useAuthSwitchShared(props);

  return (
    <div className="App">
      <LazyImage
        tag={TAG.IMG}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: appBackground === AppBackgroundType.VIP_GAMES ? 1 : -1,
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
        }}
        loadingMethod="eager"
        alt="background"
        src={getAppBackgroundImage(
          isConnected ? appBackground : AppBackgroundType.NORMAL,
          backgroundPromo,
          promotionImagesUrl
        )}
      />
      {!initTextsLoaded && (
        <div style={{ position: 'absolute', zIndex: 99, top: '50%', left: '50%' }}>
          <Spinner animation="border" />
        </div>
      )}
      {isConnected && initTextsLoaded && (
        <>
          {hasAssetsLoaded ? (
            <SceneView component={descriptor.getComponent()} navigation={descriptor?.navigation} />
          ) : (
            <AssetPreloader navigation={descriptor?.navigation} />
          )}
        </>
      )}
    </div>
  );
};

export default AuthSwitch;

