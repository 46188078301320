import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'shared/node_modules/react-redux';
import useCreateSliderInterval from 'shared/components/PinnedPanel/hooks/useCreateSliderInterval';
import BannerSlide from './components/BannerSlide';
import SliderIndicator from './components/SliderIndicator/';
import usePanelHeightCalculator from './hooks/usePanelHeightCalculator';
import { PINNED_PANEL_CONTAINER_ID } from './constants';

export default function PinnedPanel({ navigation }) {
  const lobbyPromotions = useSelector(state => state.promotions.lobbyNews);
  const { bannerCount, selectedSlide, onDotPress } = useCreateSliderInterval({ lobbyPromotions });

  const { pinnedPanelRef, updateParentHeight } = usePanelHeightCalculator();

  return (
    <PinnedPannelItemsWrapper id={PINNED_PANEL_CONTAINER_ID} onLoad={updateParentHeight} ref={pinnedPanelRef}>
      {lobbyPromotions.map((item, index) => {
        return (
          <BannerSlide
            hideItem={index !== selectedSlide}
            slideIndex={index}
            key={`${item[0]?.image + index}`}
            itemData={item}
            navigation={navigation}
          />
        );
      })}
      {bannerCount > 1 && (
        <SliderIndicator onDotPress={onDotPress} selectedSlide={selectedSlide} lobbyPromotions={lobbyPromotions} />
      )}
    </PinnedPannelItemsWrapper>
  );
}

const PinnedPannelItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
