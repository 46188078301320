import useFeatureFlags from '../../hooks/useFeatureFlags';
import { FE_LOBBY_BANNERS } from '../../utils/featureFlags/constants';
import { PAGE_SIZE } from '../../screens/Home/Home';
export var generateGameListData = function generateGameListData(pagedGames, pageNumber, ftueStep) {
  var scrollItemIndex = 0;
  var totalGamesCount = 0;
  var topRow = [];
  var bottomRow = [];
  pagedGames.forEach(function (gamePage, pageIndex) {
    totalGamesCount += gamePage.length;
    gamePage.forEach(function (game, index) {
      var targetRow = gamePage.length === PAGE_SIZE ? index < PAGE_SIZE / 2 ? topRow : bottomRow : index % 2 === 0 ? topRow : bottomRow;
      targetRow.push(game);
      if (pageIndex === pageNumber) {
        scrollItemIndex = ftueStep !== null ? 0 : topRow.length - 5;
      }
    });
  });
  var gameList = topRow.map(function (item, index) {
    return [item, bottomRow[index]];
  });
  return {
    gameList: gameList,
    scrollItemIndex: scrollItemIndex,
    totalGamesCount: totalGamesCount
  };
};
export var checkIfBannersEnabled = function checkIfBannersEnabled(bannersExist) {
  var _getFeatureConfig;
  var _useFeatureFlags = useFeatureFlags(),
    getFeatureConfig = _useFeatureFlags.getFeatureConfig;
  var bannerEnabled = (_getFeatureConfig = getFeatureConfig(FE_LOBBY_BANNERS)) === null || _getFeatureConfig === void 0 ? void 0 : _getFeatureConfig.enabled;
  return bannerEnabled && bannersExist;
};