import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import classNames from 'classnames/bind';
import FormValidator from 'shared/components/FormValidator';
import Validators from 'shared/components/Form/InputValidator';
import AutoFormInput from '../../components/AutoFormInput';
import FormType from 'shared/components/Form/FormType';
import FormId from 'shared/components/Form/FormId';
import store from 'shared/state/store';
import { setCardNickname } from 'shared/state/actions/prePurchase';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Tooltip from '../../components/Tooltip';
import LinkText from '../../components/LinkText/LinkText';
import useFeatureFlags from 'shared/hooks/useFeatureFlags';
import { FE_CREDIT_CARD_TYPE } from 'shared/utils/featureFlags/constants';
import { CREDIT_CARD } from './utils';
import { pwaStyleAdapter, isNotStandaloneMobilePWA, isStandalonePWA } from '../../utils/mobileSupported';
import FormContentContainer from './components/FormContentContainer';
const cardRegexes = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^(?:5[1-5][0-9]{14}|2[2-7][0-9]{14})$/,
  amex: /^3[47][0-9]{13}$/,
};
export default class CreditCardDetails extends Component {
  state = {
    saveCard: false,
    showTooltip: false,
    cardNickname: 'visa',
    isSavedName: false,
    buyButtonHasBeenClicked: false,
    isValidCard: null,
  };

  _hoverHandler = event => {
    this.setState({
      showTooltip: true,
    });
  };

  _leaveHandler = event => {
    this.setState({
      showTooltip: false,
    });
  };

  _toggleTooltip = event => {
    this.setState(prevState => ({
      showTooltip: !prevState.showTooltip,
    }));
  };

  onValidationFailed = nextHandle => {
    nextHandle(false);
  };

  componentDidMount() {
    store.dispatch(setCardNickname(null));
  }

  getDropdown = (
    controlId,
    formId,
    validators,
    formType,
    label,
    options,
    isValid,
    newDataChangeHandle,
    customStyle,
    name
  ) => {
    const { PurchaseFlow } = this.context;
    return (
      <>
        <AutoFormInput
          controlId={controlId}
          id={formId}
          validator={validators}
          type={formType}
          keyboardHint={''}
          label={label}
          options={options}
          validated={isValid}
          emitInput={newDataChangeHandle}
          customStyle={customStyle}
          name={name}
        />
        <img
          style={PurchaseFlow.CreditCardDetailDownArrowIcon}
          src={assetSource(ASSET_KEY.CARET_DOWN)}
          alt="caret_down"
        />
      </>
    );
  };

  getFormField = (
    controlId,
    formId,
    validators,
    formType,
    label,
    isValid,
    customStyle,
    newDataChangeHandle,
    maxChar,
    name
  ) => {
    const trimInput = controlId === FormId.CARD_NUMBER || controlId === FormId.CARD_CVV;
    return (
      <AutoFormInput
        controlId={controlId}
        id={formId}
        validator={validators}
        type={formType}
        keyboardHint={''}
        label={label}
        validated={isValid}
        emitInput={newDataChangeHandle}
        customStyle={customStyle}
        maxChar={maxChar}
        name={name}
        trimInput={trimInput}
      />
    );
  };

  handleBuyButtonClick = (submitHandle, data) => {
    const isValidCard = cardRegexes[data[FormId.CARD_TYPE]].test(data[FormId.CARD_NUMBER]);

    if (this.buyButtonHasBeenClicked || !isValidCard) {
      this.setState({ isValidCard });
      return;
    } else {
      this.setState({ buyButtonHasBeenClicked: true });
      submitHandle();
    }
  };

  toggleSaveCard = data => {
    this.setState({ saveCard: !this.state.saveCard }, () => {
      if (this.state.saveCard) {
        data[FormId.SAVE_CARD] = true;
      } else {
        data[FormId.SAVE_CARD] = '';
      }
    });
  };

  render() {
    const themeContext = this.context;
    const {
      nextHandle,
      purchaseType,
      promoReward,
      getPurchaseStrings,
      hasOffer,
      renderTotal,
      onSubmit,
      packageData,
      termsAndConditionsUrl,
    } = this.props;

    const { getFeatureConfig } = useFeatureFlags();
    const cardOptions = Object.values(getFeatureConfig(FE_CREDIT_CARD_TYPE));

    if (packageData === null) return null;

    let { purchaseNameString, purchasePriceString } = getPurchaseStrings(purchaseType, packageData, hasOffer);

    var months = [{ value: 'mm', label: getText(TEXT_KEY.CREDIT_CARD_MONTH) }];
    for (let i = 1; i <= 12; i++) {
      months.push({ value: i.toString(), label: i });
    }

    var years = [{ value: 'yy', label: getText(TEXT_KEY.CREDIT_CARD_YEAR) }];
    for (let i = 0; i < 20; i++) {
      var yr = (new Date().getFullYear() + i) % 100;
      years.push({ value: yr, label: yr });
    }

    return (
      <FormValidator
        schema={{
          [FormId.CARD_TYPE]: Validators.NOT_EMPTY,
          [FormId.CARD_HOLDER_NAME]: Validators.NO_VALIDATION,
          [FormId.CARD_NUMBER]: Validators.CREDIT_CARD,
          [FormId.CARD_EXPIRY_MONTH]: Validators.NUMBERS_ONLY,
          [FormId.CARD_EXPIRY_YEAR]: Validators.NUMBERS_ONLY,
          [FormId.CARD_CVV]: Validators.CVV,
          [FormId.CARD_NICKNAME]: (value, data) => {
            if (data[FormId.SAVE_CARD]) {
              return Validators.NOT_EMPTY(value);
            }
            return Validators.NO_VALIDATION(value);
          },
          [FormId.SAVE_CARD]: Validators.NO_VALIDATION,
          [FormId.PROMO_CODE]: Validators.NO_VALIDATION,
        }}
        key="billing-form-2"
        onSubmit={validatedData => onSubmit(nextHandle, validatedData)}
        onValidationFailed={() => this.onValidationFailed(nextHandle)}
        render={({ data, valid, complete, newDataChangeHandle, submitHandle }) => (
          <CreditCardDetailsForm method={''} action={''} onSubmit={submitHandle}>
            <FormContentContainer scrollable={standAloneOnlyMobile}>
              <CustomFormRow>
                <CustomCol>
                  <CreditCardWrapper>
                    {cardOptions.map(({ value }) => (
                      <CreditCardImage key={value} src={CREDIT_CARD[value]} alt="" />
                    ))}
                  </CreditCardWrapper>
                  <div
                    className={`cardType ${isMobile ? 'isMobile' : ''}`}
                    onChange={e => this.setState({ cardNickname: e.target.value, isValidCard: null })}
                  >
                    {this.getDropdown(
                      FormId.CARD_TYPE,
                      FormId.CARD_TYPE,
                      Validators.NO_VALIDATION,
                      FormType.DROPDOWN,
                      getText(TEXT_KEY.CARD_TYPE),
                      cardOptions,
                      valid[FormId.CARD_TYPE],
                      newDataChangeHandle,
                      themeContext.PurchaseFlow.creditCardField
                    )}
                  </div>
                </CustomCol>
              </CustomFormRow>
              <FormRow>
                <CustomCol style={{ paddingBottom: '2rem' }}>
                  {this.state.isValidCard === false && (
                    <ValidCardText>
                      {getText(TEXT_KEY.VALID_CREDIT_CARD_PREFIX) +
                        this.state.cardNickname.toUpperCase() +
                        getText(TEXT_KEY.VALID_CREDIT_CARD_POSTFIX)}
                    </ValidCardText>
                  )}
                  <div className={`cardNumber ${isMobile ? 'isMobile' : ''}`}>
                    {this.getFormField(
                      FormId.CARD_NUMBER,
                      FormId.CARD_NUMBER,
                      Validators.CREDIT_CARD,
                      FormType.TEXT,
                      getText(TEXT_KEY.CREDIT_CARD_NUMBER),
                      valid[FormId.CARD_NUMBER],
                      themeContext.PurchaseFlow.creditCardField,
                      newDataChangeHandle,
                      16,
                      ''
                    )}
                  </div>
                </CustomCol>
              </FormRow>
              <FormRow>
                <CustomCol>
                  <div className={`expiryMonth ${isMobile ? 'isMobile' : ''}`}>
                    {this.getDropdown(
                      FormId.CARD_EXPIRY_MONTH,
                      FormId.CARD_EXPIRY_MONTH,
                      Validators.NUMBERS_ONLY,
                      FormType.DROPDOWN,
                      getText(TEXT_KEY.EXPIRY_MONTH),
                      months,
                      valid[FormId.CARD_EXPIRY_MONTH],
                      newDataChangeHandle,
                      themeContext.PurchaseFlow.creditCardField
                    )}
                  </div>
                </CustomCol>
                <CustomCol>
                  <div className={`expiryYear ${isMobile ? 'isMobile' : ''}`}>
                    {this.getDropdown(
                      FormId.CARD_EXPIRY_YEAR,
                      FormId.CARD_EXPIRY_YEAR,
                      Validators.NUMBERS_ONLY,
                      FormType.DROPDOWN,
                      getText(TEXT_KEY.EXPIRY_YEAR),
                      years,
                      valid[FormId.CARD_EXPIRY_YEAR],
                      newDataChangeHandle,
                      themeContext.PurchaseFlow.creditCardField
                    )}
                  </div>
                </CustomCol>
                <CustomCol>
                  <div className={`ccvForm`}>
                    {this.getFormField(
                      FormId.CARD_CVV,
                      FormId.CARD_CVV,
                      Validators.CVV,
                      FormType.TEXT,
                      getText(TEXT_KEY.CVV),
                      valid[FormId.CARD_CVV],
                      themeContext.PurchaseFlow.creditCardField,
                      newDataChangeHandle,
                      4
                    )}
                    <QuestionIconContainer
                      onMouseEnter={this._hoverHandler.bind(this)}
                      onMouseLeave={this._leaveHandler}
                      onClick={this._toggleTooltip}
                      theme={themeContext.PurchaseFlow.QuestionIconContainer}
                    >
                      <QuestionIcon src={assetSource(ASSET_KEY.ICON_QUESTION)} alt="Question Icon" />
                    </QuestionIconContainer>
                  </div>
                </CustomCol>
              </FormRow>
              <TooltipContainer className={classNames('cvvTooltip', { hidden: !this.state.showTooltip })}>
                <Tooltip
                  visible={this.state.showTooltip}
                  style={themeContext.CreditCardDetails.CvvTooltip}
                  arrowOverStyle={themeContext.CreditCardDetails.CvvTooltipArrowOver}
                >
                  <div>
                    <span>{getText(TEXT_KEY.CVV_NUMBER)}</span>
                    <br />
                    <span>{getText(TEXT_KEY.CVV_OUTLINED_IN_GREEN)}</span>
                    <div>
                      <CvvHelpImage src={assetSource(ASSET_KEY.VISA_MC)} alt="visa_mastercard" />
                    </div>
                  </div>
                </Tooltip>
              </TooltipContainer>
              <CustomFormRow>
                <SaveCardCheckBoxHolder>
                  <div className="saveCard">
                    <div
                      style={themeContext.CreditCardDetails.CheckBoxContainer}
                      onClick={() => {
                        this.toggleSaveCard(data);
                      }}
                    >
                      <img
                        style={themeContext.CreditCardDetails.CheckBoxBackgroundImage}
                        className="checkBox"
                        src={
                          this.state.saveCard
                            ? assetSource(ASSET_KEY.CHECKBOX)
                            : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)
                        }
                        alt="Checkbox"
                      />
                    </div>
                    <AutoFormInput
                      controlId={FormId.SAVE_CARD}
                      id={FormId.SAVE_CARD}
                      validator={Validators.NO_VALIDATION}
                      type={FormType.CHECKBOX}
                      keyboardHint={''}
                      label={getText(TEXT_KEY.PAYMENT_INFO_SAVE_YOUR_CARD)}
                      validated={valid[FormId.SAVE_CARD]}
                      emitInput={newDataChangeHandle}
                      focused
                    />
                  </div>
                </SaveCardCheckBoxHolder>
              </CustomFormRow>
              <SavedCardRow className={`cardNickName ${isMobile ? 'isMobile' : ''}`} state={this.state.saveCard}>
                <CustomCol>
                  <AutoFormInput
                    controlId={FormId.CARD_NICKNAME}
                    id={FormId.CARD_NICKNAME}
                    validator={Validators.NO_VALIDATION}
                    type={FormType.TEXT}
                    keyboardHint={''}
                    label={getText(TEXT_KEY.CARD_NICKNAME)}
                    initialValue={`${getText(TEXT_KEY.MY)} ` + this.state.cardNickname.toUpperCase()}
                    customStyle={themeContext.PurchaseFlow.creditCardField}
                    validated={valid[FormId.CARD_NICKNAME]}
                    emitInput={newDataChangeHandle}
                    maxChar={100}
                  />
                </CustomCol>
              </SavedCardRow>
              {renderTotal(purchaseNameString, purchasePriceString, promoReward)}
              <BuyButtonWrapper>
                <Button
                  imageSource={
                    complete
                      ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
                      : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
                  }
                  textStyle={themeContext.CreditCardDetails.BuyNowButton}
                  label={getText(TEXT_KEY.BUY_NOW)}
                  onClick={complete ? () => this.handleBuyButtonClick(submitHandle, data) : () => {}}
                  disabled={!complete}
                  textStroke={BUTTON_STROKES.PRIMARY}
                />
              </BuyButtonWrapper>
              <TermsTexContainer>
                <LinkText href={termsAndConditionsUrl} target="_blank">
                  {getText(TEXT_KEY.TERMS_AND_CONDITIONS_IAP)} {getText(TEXT_KEY.APPLY)}
                </LinkText>
              </TermsTexContainer>
            </FormContentContainer>
          </CreditCardDetailsForm>
        )}
      />
    );
  }
}

CreditCardDetails.contextType = ThemeContext;

const mobileNotBookmarked = isNotStandaloneMobilePWA && !isTablet;
const standAloneOnlyMobile = isStandalonePWA && !isTablet;
const windowHeight = window.innerHeight;
const CustomCol = styled(Col)`
  ${isTablet ? 'padding: 0.3rem;' : isMobile ? 'padding: 0.5rem;' : ''}
`;

const QuestionIconContainer = styled.div`
  ${({ theme }) => theme}
`;

const QuestionIcon = styled.img`
  width: 1.5rem;
  pointer-events: auto;
  cursor: pointer;
`;

const TooltipContainer = styled.div`
  top: ${standAloneOnlyMobile
    ? '77%'
    : mobileNotBookmarked
      ? windowHeight < 310
        ? '100%'
        : '92%'
      : isTablet
        ? '57%'
        : '55%'};
  z-index: 10;
  ${({ theme }) => theme}
`;

const CreditCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  height: ${pwaStyleAdapter(2.5, 'rem')};
`;

const CreditCardDetailsForm = styled(Form).attrs({
  className: 'form-container credit-card-details',
  id: 'payment_form',
})`
  height: ${({ windowHeight }) =>
    standAloneOnlyMobile ? '65vh' : mobileNotBookmarked ? (windowHeight > 630 ? `60vh` : `50vh`) : 'auto'} !important;
`;

const CreditCardImage = styled.img`
  height: 100%;
  margin-inline-end: 0.5rem;
`;

const CvvHelpImage = styled.img`
  width: 75%;
`;

const CustomFormRow =
  isMobile && !isTablet
    ? styled(Row)`
        margin-left: 0px;
        margin-right: 0px;
      `
    : styled(Row)``;

const FormRow = styled(CustomFormRow)`
  margin-top: -0.5em;
  justify-content: center;
`;

const SaveCardCheckBoxHolder = styled(CustomCol)`
  text-align: center;
  margin-top: ${isMobile && !isTablet ? 1 : 0}rem;
`;

const SavedCardRow = styled(CustomFormRow)`
  margin-top: ${({ state }) => (isMobile && !isTablet && !state ? -6 : -2)}rem;
  visibility: ${({ state }) => (state ? 'visible' : 'hidden')};
`;

const TermsTexContainer = styled(CustomFormRow)`
  bottom: -4%;
  justify-content: center;
`;

const BuyButtonWrapper = styled(CustomFormRow)`
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const ValidCardText = styled.p`
  font-size: 18px;
  text-align: center;
  color: red;
`;

