import { useSelector, shallowEqual } from '../../../node_modules/react-redux';
import Logger from '../../../lib/analytics/logger';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import { FEATURES_LIST } from '../../../constants/types';
export default function useWheelLogHandler() {
  var _useSelector = useSelector(function (state) {
      return {
        loyalty: parseInt(state.loyalty.value),
        coin: parseInt(state.coin.value),
        userLevel: state.levelInfo.level
      };
    }, shallowEqual),
    loyalty = _useSelector.loyalty,
    coin = _useSelector.coin,
    userLevel = _useSelector.userLevel;
  var claimLogHandler = function claimLogHandler(res, bonusInfo, totalLoyalty, totalCoinsAfterTierBonus) {
    var _res$login_streak_coi, _res$login_streak_loy;
    var isSkipped = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    var _currentMemberTier = bonusInfo.CURRENT_MEMBER_TIER;
    var tierBonus = bonusInfo.MEMBER_TIERS.find(function (currentMemberTier) {
      return currentMemberTier.tier_name == _currentMemberTier;
    });
    var eventPayload = {
      balance_before: (coin - parseInt(totalCoinsAfterTierBonus || 0)).toString(),
      loyalty_points_before: (loyalty - parseInt(totalLoyalty)).toString(),
      loyalty_points: totalLoyalty === null || totalLoyalty === void 0 ? void 0 : totalLoyalty.toString(),
      coins: totalCoinsAfterTierBonus === null || totalCoinsAfterTierBonus === void 0 ? void 0 : totalCoinsAfterTierBonus.toString(),
      streak_coins: (_res$login_streak_coi = res.login_streak_coins_list) === null || _res$login_streak_coi === void 0 || (_res$login_streak_coi = _res$login_streak_coi[res.current_reward_day]) === null || _res$login_streak_coi === void 0 ? void 0 : _res$login_streak_coi.amount,
      streak_loyalty_points: (_res$login_streak_loy = res.login_streak_loyalty_list) === null || _res$login_streak_loy === void 0 || (_res$login_streak_loy = _res$login_streak_loy[res.current_reward_day]) === null || _res$login_streak_loy === void 0 ? void 0 : _res$login_streak_loy.amount,
      login_streak: res.login_streak_day,
      skipped: isSkipped,
      tier_bonus: "".concat(tierBonus.daily_bonus_boost_percentage, "%"),
      user_level: userLevel
    };
    Logger.sendEvent(EVENT_TYPES.DAILY_WHEEL_CLAIMED, eventPayload);
  };
  var featureEngagementLogHandler = function featureEngagementLogHandler() {
    return Logger.sendEvent(EVENT_TYPES.GTM_FEATURE_ENGAGEMENT, {
      feature: FEATURES_LIST.DAILY_WHEEL
    });
  };
  return {
    claimLogHandler: claimLogHandler,
    featureEngagementLogHandler: featureEngagementLogHandler
  };
}