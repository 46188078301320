import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';
const BattlesTabsHeader = ({ battlesAssets, activeTab, onClick }) => {
  return (
    <>
      <BackgroundActive src={battlesAssets.BATTLES_STREAK_INFO} activeTab={activeTab} />
      <BackgroundInactive src={battlesAssets.BATTLES_STREAK_INFO_2} activeTab={activeTab} />
      <Clickable onClick={onClick} />
    </>
  );
};

export default BattlesTabsHeader;

const isMobileBrowser = isMobile && isNotStandaloneMobilePWA && !isTablet;

const BackgroundActive = styled.img`
  position: absolute;
  width: ${isMobileBrowser ? 23 : 33}vw;
  ${({ theme }) => theme}
  display: ${({ activeTab }) => (!activeTab ? 'flex' : 'none')}
`;

const BackgroundInactive = styled.img`
  position: absolute;
  width: ${isMobileBrowser ? 23 : 33}vw;
  ${({ theme }) => theme}
  display: ${({ activeTab }) => (activeTab ? 'flex' : 'none')}
`;

const Clickable = styled.div`
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7vh;
  margin-top: 2vh;
`;

