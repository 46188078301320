import React from 'react';
import Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';
import styled from 'styled-components';
import usePromoDataHandler from 'shared/components/PinnedPanel/hooks/usePromoDataHandler';
import useSlideFadeAnimation from 'shared/components/PinnedPanel/hooks/useSlideFadeAnimation';
import { TAG } from 'shared/constants';
import LazyImage from '../../LazyImage/LazyImage';

export default function BannerSlide(props) {
  const { hideItem, itemData, slideIndex } = props;
  const { showItemOpacity } = useSlideFadeAnimation({ hideItem, Animated, Easing });
  const itemsHandler = usePromoDataHandler({ itemData, slideIndex });
  const itemCount = itemsHandler.length;
  return (
    <PanelContainer
      style={{
        opacity: showItemOpacity,
      }}
      zindex={hideItem ? 0 : 1}
      count={itemCount}
    >
      {itemsHandler.map((item, index) => (
        <LazyImage
          tag={TAG.IMG}
          key={`${item.imageUrl + index}`}
          onClick={item.actionHandler}
          src={item.imageUrl}
          alt={`Banner number ${index + 1}`}
          style={{
            width: '100%',
            cursor: 'pointer',
          }}
        />
      ))}
    </PanelContainer>
  );
}

const PanelContainer = styled(Animated.div)`
  display: grid;
  width: 100%;
  position: absolute;
  grid-template-rows: 1fr;
  gap: ${({ count }) => (count === 2 ? 1.3 : 1.1)}rem;
  z-index: ${({ zindex }) => zindex};
`;

