import { isTablet, isMobile } from 'react-device-detect';
import styled from 'styled-components';

const windowWidth = window.innerWidth;

const barTotalWidthPx = withCard => (withCard ? (windowWidth < 1400 ? 165 : 224) : 224);
const barTotalHeightPx = withCard => (withCard ? (windowWidth < 1400 ? 32 : 53) : 53);
const fillBarTotalHeightPx = withCard =>
  withCard
    ? windowWidth < 1400
      ? barTotalHeightPx(withCard) + 10
      : barTotalHeightPx(withCard) - 10
    : barTotalHeightPx(withCard) - 10;

const Container = styled.div`
  width: ${isTablet ? (windowWidth < 1150 ? 160 : 150) : 180}px;
  position: relative;
  left: ${isTablet ? 3 : 2.8}rem;
  @media only screen and (min-width: 1300px) {
    left: ${({ withCard }) => (withCard ? 3.5 : 3)}rem;
  }
  @media only screen and (min-width: 1500px) {
    left: ${({ withCard }) => (withCard ? (isMobile ? 3.2 : 3.5) : 3)}rem;
  }
`;

const MissionIcon = styled.img`
  ${({ theme }) => theme}
  position: absolute;
  z-index: 1;
`;

const TimeBar = styled.img`
  ${({ theme }) => theme}
  position: absolute;
`;

const BaseBar = styled.img`
  ${({ theme }) => theme}
  width: ${({ withCard }) => barTotalWidthPx(withCard)}px;
  position: absolute;
`;

const FillBar = styled.div`
  ${({ theme }) => theme}
  position: absolute;
  height: ${({ withCard }) => barTotalHeightPx(withCard)}px;
  align-items: center;
  z-index: 0;
`;

const FillProgress = styled.img`
  ${({ theme }) => theme}
  height: ${({ withCard }) => fillBarTotalHeightPx(withCard)}px;
  width: ${props =>
    ((props.percentage === 100 ? 100 : Math.min(props.percentage, 98.5)) * barTotalWidthPx(props.withCard)) / 100}px;
`;

const FillEnd = styled.img`
  ${({ theme }) => theme}
  height: ${({ withCard }) => fillBarTotalHeightPx(withCard)}px;
`;

const TextProgress = styled.p`
  ${({ theme }) => theme}
  position: absolute;
  z-index: 5;
  color: white;
  font-weight: 700;
  font-size: 30px;
  text-shadow: 0 0px 3px #000;
`;

const TimeText = {
  position: 'absolute',
  top: 53,
  color: 'white',
  fontWeight: 700,
  fontSize: 20,
  textShadow: '0 0px 2px #000',
};

const CollectBar = styled.img`
  position: absolute;
  top: 8px;
  ${({ theme }) => theme}
`;

const CollectBarContainerStyle = {
  background: 'rgb(5, 181, 1)',
  boxShadow: 'inset 0 2.5px 8px rgba(255,255,255,0.9), inset 0 -4px 2px rgba(0,0,0,0.3)',
  cursor: 'pointer',
};

const ProgressBarContainerStyle = {
  background: 'linear-gradient(180deg, rgba(33,30,28,1) 0%, rgba(2,81,198,1) 100%)',
};

const ProgressBarStyle = {
  background: `linear-gradient(
    to bottom,
    rgba(2, 101, 236, 1) 30%,
    rgba(0, 204, 255, 1) 50%,
    rgba(2, 101, 236, 1) 70%
  )`,
  height: '2.6rem',
};

const PercentageStyle = {
  color: 'white',
  fontWeight: 700,
  fontSize: 30,
  textShadow: '0 0px 3px #000',
  position: 'absolute',
  top: '-0.2rem',
};

const BarLabelStyle = {
  fontSize: 26,
  fontStyle: 'italic',
  position: 'relative',
  fontWeight: 800,
  WebkitTextStroke: '1.5px rgb(4, 128, 1)',
  textShadow: `0px 0px 0px rgb(4, 128, 1), 
               1px 1px 0px rgb(4, 128, 1), 
               -1px -1px 0px rgb(4, 128, 1), 
               -1px 1px 0px rgb(4, 128, 1), 
               1px -1px 0px rgb(4, 128, 1)`,
  letterSpacing: -2,
};

export {
  Container,
  MissionIcon,
  TimeBar,
  BaseBar,
  FillBar,
  FillProgress,
  FillEnd,
  TextProgress,
  TimeText,
  CollectBar,
  CollectBarContainerStyle,
  ProgressBarContainerStyle,
  ProgressBarStyle,
  PercentageStyle,
  BarLabelStyle,
};

