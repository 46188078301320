import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postAddFavouriteGameMutation, postRemoveFavouriteGameMutation } from './queries';
export var lobbyApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postAddFavouriteGame: builder.mutation(postAddFavouriteGameMutation),
      postRemoveFavouriteGame: builder.mutation(postRemoveFavouriteGameMutation)
    };
  },
  overrideExisting: true
});
lobbyApi.enhanceEndpoints({
  addTagTypes: ['LobbyItems']
});
var usePostAddFavouriteGameMutation = lobbyApi.usePostAddFavouriteGameMutation,
  usePostRemoveFavouriteGameMutation = lobbyApi.usePostRemoveFavouriteGameMutation,
  _lobbyApi$endpoints = lobbyApi.endpoints,
  postAddFavouriteGame = _lobbyApi$endpoints.postAddFavouriteGame,
  postRemoveFavouriteGame = _lobbyApi$endpoints.postRemoveFavouriteGame;
export { usePostAddFavouriteGameMutation, usePostRemoveFavouriteGameMutation, postAddFavouriteGame, postRemoveFavouriteGame };