import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { APP_INTERFACE_CACHE_TAGS } from '../tags';
import { postInitappMutation, getAppEventsUpdateQuery, getFacebookAppIdQuery } from './queries';
export var appInterfaceApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postInitapp: builder.mutation(postInitappMutation),
      getAppEventsUpdate: builder.query(getAppEventsUpdateQuery),
      getFacebookAppId: builder.query(getFacebookAppIdQuery)
    };
  }
});
appInterfaceApi.enhanceEndpoints({
  addTagTypes: [APP_INTERFACE_CACHE_TAGS.INIT_APP, APP_INTERFACE_CACHE_TAGS.UPDATE_APP_EVENTS, APP_INTERFACE_CACHE_TAGS.GET_FACEBOOK_APP_ID]
});
var useLazyGetAppEventsUpdateQuery = appInterfaceApi.useLazyGetAppEventsUpdateQuery,
  useLazyGetFacebookAppIdQuery = appInterfaceApi.useLazyGetFacebookAppIdQuery,
  _appInterfaceApi$endp = appInterfaceApi.endpoints,
  postInitapp = _appInterfaceApi$endp.postInitapp,
  getAppEventsUpdate = _appInterfaceApi$endp.getAppEventsUpdate,
  getFacebookAppId = _appInterfaceApi$endp.getFacebookAppId;
export { useLazyGetAppEventsUpdateQuery, useLazyGetFacebookAppIdQuery, postInitapp, getAppEventsUpdate, getFacebookAppId };