import React from 'react';
import styled from 'styled-components';
import MissionTabs from '../Missions/components/MissionTabs';
import useBattles from 'shared/screens/Battles/hooks/useBattles';
import BattlesHelp from './components/BattlesHelp';
import BattlesBegin from './components/BattlesBegin';
import BattlesRoom from './components/BattlesRoom';
// import BattlesEmpty from './components/BattlesEmpty'; TODO

const Battles = ({ navigation }) => {
  const battlesData = useBattles({ navigation });
  return (
    <Container>
      <BattlesHelp {...battlesData} />
      <BattlesBegin {...battlesData} />
      <MissionTabs navigation={navigation} index={1} {...battlesData} />
      <BattlesRoom {...battlesData} />
      {/* <BattlesEmpty {...battlesData} /> */}
    </Container>
  );
};

export default Battles;

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

