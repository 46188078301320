import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getWebTrackingUrlDataQuery, postCheckIfEmailExistsMutation, postGuestLoginMutation, postLoginMutation } from './queries';
export var loginApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postLogin: builder.mutation(postLoginMutation),
      postGuestLogin: builder.mutation(postGuestLoginMutation),
      postCheckIfEmailExists: builder.mutation(postCheckIfEmailExistsMutation),
      getWebTrackingUrlData: builder.query(getWebTrackingUrlDataQuery)
    };
  }
});
loginApi.enhanceEndpoints({
  addTagTypes: ['Login'],
  keepUnusedDataFor: 10
});
var usePostLoginMutation = loginApi.usePostLoginMutation,
  usePostCheckIfEmailExistsMutation = loginApi.usePostCheckIfEmailExistsMutation,
  useGetWebTrackingUrlDataQuery = loginApi.useGetWebTrackingUrlDataQuery,
  _loginApi$endpoints = loginApi.endpoints,
  postLogin = _loginApi$endpoints.postLogin,
  postGuestLogin = _loginApi$endpoints.postGuestLogin;
export { usePostLoginMutation, usePostCheckIfEmailExistsMutation, useGetWebTrackingUrlDataQuery, postLogin, postGuestLogin };