import SideMenu from './SideMenu';
import { connect } from '../../node_modules/react-redux';
import { clearToken as _clearToken } from '../../state/actions/auth';
import { ONBOARDING_FLOWS } from '../../utils/onboarding';
import { TUTORIAL_NAME } from '../../utils/tutorial';
var mapStateToProps = function mapStateToProps(state) {
  return {
    userCanFavourite: parseInt(state.levelInfo.level) >= parseInt(state.ftue.onboardingsUnlockLevels[ONBOARDING_FLOWS.FAVOURITES]),
    navState: state.navState.navState,
    sidebarState: state.sidebarState.sidebarState,
    isVipRoomUnlocked: state.consumables.isVipRoomUnlocked,
    isAccountLinked: state.playerInfo.isAccountLinked === 'yes',
    sportsGamesRoom: state.sportsGames,
    isBlockedForMissionsTutorial: state.tutorial.blockLobby && state.tutorial.tutorialName === TUTORIAL_NAME.MISSIONS_TUTORIAL
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    clearToken: function clearToken() {
      dispatch(_clearToken(true));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);