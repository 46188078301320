import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import SliderDot from '../SliderDot';
import { BOTTOM_BAR_CONTENT_CONTAINER_ID } from '../../../BottomBar/constants';
import useMobileSliderDotPosCalculator from '../../hooks/useMobileSliderDotPosCalculator';
import { useSelector } from 'shared/node_modules/react-redux';
import { TUTORIAL_NAME } from 'shared/utils/tutorial';
import useCheckOrientation from '../../../../hooks/useCheckOrientation';

function SliderIndicatorMobile({ lobbyPromotions, selectedSlide, onDotPress }) {
  const targetContainer = document.getElementById(BOTTOM_BAR_CONTENT_CONTAINER_ID);
  const { position } = useMobileSliderDotPosCalculator();
  const { isLandscape } = useCheckOrientation();
  const isBlockedForMissionsTutorial = useSelector(
    state => state.tutorial.blockLobby && state.tutorial.tutorialName === TUTORIAL_NAME.MISSIONS_TUTORIAL
  );
  const RenderElement = (
    <SliderDotWrapper hide={isBlockedForMissionsTutorial || !isLandscape} position={position}>
      {lobbyPromotions.map((_, index) => (
        <SliderDot index={index} key={index} selected={selectedSlide === index} onPress={onDotPress} size={50} />
      ))}
    </SliderDotWrapper>
  );
  const OutPut = targetContainer ? ReactDOM.createPortal(RenderElement, targetContainer.parentNode) : RenderElement;
  return OutPut;
}

export default SliderIndicatorMobile;

const SliderDotWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  width: ${({ position }) => position.width}px;
  height: 60px;
  z-index: ${({ hide }) => (hide ? 0 : 1000)};
  bottom: ${({ position }) => position.bottom}px;
  left: ${({ position }) => position.left}px;
`;

