import React from 'react';
import styled from 'styled-components';
import { pwaStyleAdapter } from '../../../utils/mobileSupported';

export default function NotificationBadge({ theme, count, textStyle }) {
  const textLength = count?.toString().length;
  return (
    <NotificationWrapper theme={theme} collapsable={false}>
      <NotificationText length={textLength} theme={textStyle}>
        {count}
      </NotificationText>
    </NotificationWrapper>
  );
}

const NotificationText = styled.p`
  font-size: ${({ length }) => (length > 1 ? 0.8 : 1)}rem;
  text-align: center;
  font-weight: 600;
  color: white;
  margin: 0px !important;
  ${({ theme }) => theme}
`;

const NotificationWrapper = styled.div`
  z-index: 1;
  background-color: #ff343b;
  position: absolute;
  border-radius: ${pwaStyleAdapter(0.75, 'rem')};
  width: ${pwaStyleAdapter(1.5, 'rem')};
  height: ${pwaStyleAdapter(1.5, 'rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  ${({ theme }) => theme}
`;

