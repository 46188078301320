import React, { Component } from 'react';
import SVIPGames from 'shared/screens/VIPGames';
import Utils from 'shared/utils';
import audio from 'shared/utils/audio';
import GamePage from '../../components/GamePage';
import TimeRemaining from '../../components/TimeRemaining';
import { GameListWrapper } from '../Home/styledComponents';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { GAME_TRIGGERS } from 'shared/constants';

export default class VIPGames extends Component {
  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    return (
      <SVIPGames
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { firstTimeAccess, canEnterRoom, pagedGames, lobbyPromotions, timeRemaining } = args;
          const hasPromotions = !Utils.isEmpty(lobbyPromotions);
          const hasGames = !Utils.isEmpty(pagedGames);

          return (
            <>
              <div className="navigation-darker">
                <img alt="darker" src={assetSource(ASSET_KEY.NAVIGATION_DARKER)} />
              </div>
              <div className="roomPage">
                <div className="roomContent">
                  {!canEnterRoom && <AccessDenied canEnterRoom={canEnterRoom} firstTimeAccess={firstTimeAccess} />}
                  {canEnterRoom && (
                    <>
                      {timeRemaining > 0 && <TimeRemaining timeRemaining={timeRemaining} />}
                      {hasGames && (
                        <GameListWrapper banner={hasPromotions}>
                          <div className={'GameListContainer'}>
                            <GamePage
                              {...args}
                              navigation={this.props.navigation}
                              hasPromotions={hasPromotions}
                              trigger={GAME_TRIGGERS.VIP}
                            />
                          </div>
                        </GameListWrapper>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}

