export const DEFAULT_RESET_PASSWORD_TEXTS = {
  CHOOSE_PASSWORD: 'CHOOSE PASSWORD',
  SENDING_NEW_PASSWORD_REQUEST: '...Sending the change password request',
  INVALID_BODY_MESSAGE: 'Your new password must be at least 8 characters and a combination of number and letters',
  TYPE_NEW_PASSWORD_BELOW: 'Please type your new password below',
  PASSWORD_HAS_BEEN_RESET: 'Your password has been Reset! Please login with your new password!',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm password',
  THANKS: 'THANKS',
  SUBMIT: 'SUBMIT',
  CANCEL: 'CANCEL',
  OK: 'OK',
};

export const RESET_PASS_ACTIONS = {
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  SENDING_DATA: 'SENDING_DATA',
  INVALID_BODY: 'INVALID_BODY',
  SUCCESS_RESPONSE: 'SUCCESS_RESPONSE',
  ERROR_RESPONSE: 'ERROR_RESPONSE',
  TOGGLE_PASSWORD: 'TOGGLE_PASSWORD',
};

