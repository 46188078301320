/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import ImageBW from '../../components/ImageBoundsWrapper';
import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { purchaseActionTriggers } from 'shared/constants/userActions';
import Button from '../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import LazyImage from '../../components/LazyImage/LazyImage';
import { TAG } from 'shared/constants';

const assets = {
  purple: {
    backing: assetSource(ASSET_KEY.ITEM_ROW_PURPLE),
    left: assetSource(ASSET_KEY.SHOP_PURPLE_CAP),
    triangle: assetSource(ASSET_KEY.SHOP_PURPLE_TRIANGLE_DIVIDER),
  },
  gold: {
    backing: assetSource(ASSET_KEY.ITEM_ROW_GOLD),
    left: assetSource(ASSET_KEY.SHOP_GOLD_CAP),
    triangle: assetSource(ASSET_KEY.SHOP_GOLD_TRIANGLE_DIVIDER),
  },
  ibw: {
    backing: require(asset`store-item-backing.ibw`),
    left: require(asset`store-item-left-end.ibw`),
    triangle: require(asset`store-item-triangle.ibw`),
    upsell: require(asset`store-item-upsell.ibw`),
  },
  upsell: {
    amazing: assetSource(ASSET_KEY.UPSELL_TAB_AMAZING),
    'best deal': assetSource(ASSET_KEY.UPSELL_TAB_BEST_DEAL),
    popular: assetSource(ASSET_KEY.UPSELL_TAB_POPULAR),
  },
};

/** @typedef {import('shared/api/Constants').CurrencyPackageItemKeys} CPIK */

/** @param {CPIK} item */
export default function makeCoinItem(
  item,
  buyHandle,
  showPromoBoost = false,
  itemStyle,
  innerItemStyle,
  stackStyle,
  stackIconStyle,
  stackAmountStyle,
  coinAmountStyle,
  extraStyle,
  buttonTextStyle,
  assetsUrls
) {
  const percent = showPromoBoost ? item.PROMO_BOOST_PERCENTAGE : parseInt(item.PERCENT_BOOST);
  let coinAmount = item.AMOUNT;
  if (showPromoBoost && !item.IS_SALE) {
    coinAmount = item.PROMO_BOOSTED_VALUE;
  } else if (showPromoBoost && item.IS_SALE) {
    coinAmount = item.AMOUNT_INCLUDING_SALE_AND_PROMO;
  }
  // TODO: trigger gold with some variable that likely doesn't exist at time of writing
  const variant = item.CORNER_FLAG ? 'gold' : 'purple';

  const buyButtonSrc = generateRender({
    ti: assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY),
    default: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  });

  /*
   * Absolutely positioned centering calculations.
   * Result is icon positioned absolutely at the intersection of left inset and the rest of the bar.
   * * Since these are using two different units, it has to be evaluated with CSS's calc().
   */

  return (
    <ImageBW
      source={assets[variant].backing}
      ibw={assets.ibw.backing}
      manualAssetScale={3}
      style={itemStyle}
      innerStyle={innerItemStyle}
      key={item.ORDER + ''}
    >
      <ImageBW
        source={assets[variant].left}
        ibw={assets.ibw.left}
        manualAssetScale={3}
        style={{
          width: '12vmin',
          height: '100%',
          objectFit: 'stretch',
        }}
        innerStyle={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <div style={stackStyle}>
          <ImageBW
            source={assetSource(ASSET_KEY.LOYALTY_ICON_BONUS)}
            ibw={require(asset`loyalty-icon.ibw`)}
            style={stackIconStyle}
          />
          <div
            style={{
              ...stackAmountStyle,
              color: variant === 'purple' ? theme.palette.common[1] : theme.palette.common[0],
            }}
          >
            {item.BONUS_LOYALTY_AMOUNT}
          </div>
        </div>
      </ImageBW>
      <ImageBW
        source={assets[variant].triangle}
        ibw={assets.ibw.triangle}
        manualAssetScale={3}
        style={{
          width: '3vmin',
          height: '100%',
          objectFit: 'stretch',
        }}
        innerStyle={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            fontSize: 24,
            fontWeight: 600,
            color: variant === 'purple' ? theme.palette.common[1] : theme.palette.common[0],
            marginRight: 5,
          }}
        >
          +
        </div>
      </ImageBW>
      <div
        style={{
          position: 'relative',
          height: '100%',
          width: '20vmin',
        }}
      >
        <LazyImage
          tag={TAG.IMG}
          src={`${assetsUrls.ImagesCdnBaseUrl}/marketing/coinpkg/${item.IMAGE}`}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
        {item.CORNER_FLAG && (
          <LazyImage
            tag={TAG.IMG}
            src={assets.upsell[item.CORNER_FLAG] || assets.upsell['amazing']}
            style={{
              position: 'absolute',
              top: -6,
              left: 17,
              right: 17,
              height: 25,
              objectFit: 'contain',
            }}
          />
        )}
      </div>
      <div
        className="stack"
        style={{
          flex: 1,
          textAlign: 'center',
        }}
      >
        <div
          style={{
            ...coinAmountStyle,
            color: item.CORNER_FLAG ? theme.palette.common[0] : theme.palette.common[1],
          }}
        >
          {coinAmount}
        </div>
        {((showPromoBoost && !item.IS_SALE) || (percent > 0 && !item.IS_SALE)) && (
          <div
            style={{
              ...extraStyle,
              color: item.CORNER_FLAG ? theme.palette.common[4] : theme.palette.primary[13],
            }}
          >
            {percent}
            {getText(TEXT_KEY.EXTRA_PERCENTAGE)}
          </div>
        )}
        {!showPromoBoost && item.IS_SALE && (
          <div
            style={{
              ...extraStyle,
              color: item.CORNER_FLAG ? theme.palette.common[0] : theme.palette.common[1],
            }}
          >
            <span>{getText(TEXT_KEY.SALE)}!</span> {getText(TEXT_KEY.WAS)}{' '}
            <span style={{ textDecoration: 'line-through' }}>{item.ORIGINAL_AMOUNT}</span>
          </div>
        )}
        {showPromoBoost && item.IS_SALE && (
          <div
            style={{
              ...extraStyle,
              color: item.CORNER_FLAG ? theme.palette.common[4] : theme.palette.primary[13],
            }}
          >
            <span>
              {parseInt(item.PROMO_BOOST_PERCENTAGE) + parseInt(item.SALE_PERCENTAGE)}{' '}
              {getText(TEXT_KEY.EXTRA_PERCENTAGE)}
            </span>
          </div>
        )}
      </div>
      <div
        style={{
          width: '20vmin',
          color: item.CORNER_FLAG ? theme.palette.common[0] : theme.palette.common[1],
          textAlign: 'center',
          paddingRight: 0,
        }}
      >
        <p style={{ fontSize: '1.5rem', margin: 0 }}>{`${getText(TEXT_KEY.CURRENCY_SIGN)}${item.PRICE}`}</p>
      </div>
      <Button
        imageStyle={styles.buyButtonImage}
        textStroke={BUTTON_STROKES.GREEN}
        imageSource={buyButtonSrc}
        label={getText(TEXT_KEY.BUY)}
        textStyle={buttonTextStyle}
        onClick={() =>
          buyHandle(item.CURRENCY_ITEM_ID, item.NAME, null, item.PRICE, purchaseActionTriggers.STORE_COIN_ITEM)
        }
      />
    </ImageBW>
  );
}

const styles = {
  buyButtonImage: {
    objectFit: 'contain',
    width: '7vw',
    minWidth: 100,
    maxWidth: 130,
    pointerEvents: 'all',
    cursor: 'pointer',
  },
};

