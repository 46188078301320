import styled, { css } from 'styled-components';
import { isTablet } from 'react-device-detect';
import { isStandalonePWA, isNotStandaloneMobilePWA } from '../../../../utils/mobileSupported';
import { Tooltip } from '..';

const CompleteRequestMobilePWA = css`
  left: 43vh;
`;

const CompleteRequestTabletNotPWA = css`
  left: ${isTablet ? 32 : 36}vh;
  top: ${isTablet ? 33 : 25}vh;
`;

export const COMPLETE_REQUESTED = {
  TOOLTIP: styled(Tooltip)`
    width: 50vh;
    top: 33vh;
    left: 36vh;
    ${isStandalonePWA && !isTablet && CompleteRequestMobilePWA}
    ${isNotStandaloneMobilePWA && CompleteRequestTabletNotPWA}
  `,
};

