import NavigationBar from './NavigationBar';
import { connect } from '../../node_modules/react-redux';
import { addNotif as _addNotif } from '../../state/actions/notification';
import { setModal as _setModal } from '../../state/actions/modal';
import { setSidebarState as _setSidebarState } from '../../state/actions/sidebar_state';
import { shouldShowTooltip as _shouldShowTooltip, tooltipDetails as _tooltipDetails } from '../../state/actions/tooltip';
import { getEvents as _getEvents } from '../../state/query/events';
import { TUTORIAL_NAME } from '../../utils/tutorial';
import { getAppEventsUpdate as _getAppEventsUpdate } from '../../state/query/app';
var mapStateToProps = function mapStateToProps(state) {
  return {
    navState: state.navState.navState,
    sidebarState: state.sidebarState.sidebarState,
    coin: state.coin,
    level: state.levelInfo.level,
    loyalty: state.loyalty,
    messageCount: state.messageCount,
    sale: state.shopList.saleAvailable,
    limitedTimeOffer: state.consumables.ltoOffer,
    gameInfo: state.gameInfo,
    hideActiveTracker: state.navState.hideActiveTracker,
    ftueStep: state.currentOnboarding.ftueStep,
    showLinkLoyaltyCardSetting: state.properties.data.showLinkLoyaltyCardSetting,
    activeTutorial: state.tutorial.activeTutorial,
    missionsAssets: state.assetsPackage.assets.MISSIONS_FEATURE,
    assetsLoaded: state.assetsPackage.assetsLoaded,
    showMissionsProgressBar: state.missions.showMissionsProgressBar,
    isBlockedForMissionsTutorial: state.tutorial.blockLobby && state.tutorial.tutorialName === TUTORIAL_NAME.MISSIONS_TUTORIAL
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    shouldShowTooltip: function shouldShowTooltip(value) {
      dispatch(_shouldShowTooltip(value));
    },
    tooltipDetails: function tooltipDetails(detail) {
      dispatch(_tooltipDetails(detail));
    },
    setModal: function setModal(newModal) {
      dispatch(_setModal(newModal));
    },
    addNotif: function addNotif(notif) {
      dispatch(_addNotif(notif));
    },
    setSidebarState: function setSidebarState(newState) {
      dispatch(_setSidebarState(newState));
    },
    getEvents: function getEvents(args, params) {
      dispatch(_getEvents.initiate(args, params));
    },
    getAppEventsUpdate: function getAppEventsUpdate() {
      return dispatch(_getAppEventsUpdate.initiate({}, {
        forceRefetch: true
      }));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);