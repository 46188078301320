import React, { useRef, useContext, useEffect } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { lightAssets } from 'shared/screens/DailySpin/utils';
import ThemeContext from 'shared/context/ThemeContext';
import ImageBW from '../../../../../components/ImageBoundsWrapper';
import {
  StreakColumnStyles,
  RegDaysRow,
  RegDaysLightImageLeft,
  RegDaysLightImageRight,
  RegDaysStreakDayText,
  RegDaysDayText,
  RegDayRowContentWrapper,
  RegDaysItemText,
  RegDaysItemWrapper,
  RegDaysItemCoinIcon,
  RegDaysItemWrapperCenter,
  RegDaysItemWrapperRight,
  RegDaysItemWrapperInnerText,
  RegDaysItemWrapperInnerIcon,
  EmptyDivSpace,
} from '../../styledComponents';
import useAutoScroll from '../../../hooks/useAutoScroll';

export default function StreakDaysRowDefault(props) {
  const themeContext = useContext(ThemeContext);
  const { item, index, day, bonusInfo, dailyWheelAssets, enableLoyalty, scrollRef } = props;
  const shouldScroll = bonusInfo?.CURRENT_REWARD_DAY === day && index > 6;
  useAutoScroll({ scrollRef, shouldScroll });
  const marker = useRef(null).current;
  const opacity = bonusInfo?.CURRENT_REWARD_DAY >= day + 1 ? 0.25 : 1;
  const light = lightAssets(dailyWheelAssets);

  return (
    <React.Fragment>
      <RegDaysRow>
        <RegDaysLightImageLeft src={light[bonusInfo?.CURRENT_REWARD_DAY >= day ? index + 1 : 0]} alt="" />
        <RegDaysLightImageRight src={light[bonusInfo?.CURRENT_REWARD_DAY >= day ? index + 1 : 0]} alt="" />
        {bonusInfo?.CURRENT_REWARD_DAY === day && (
          <>
            <ImageBW
              customRef={marker}
              source={dailyWheelAssets.DAILY_SPIN_STREAK_DAY_INDICATOR}
              ibw={require(asset`images/daily-spin/daily-bonus-daily-prize-indicator.ibw`)}
              style={
                day > 100 ? StreakColumnStyles.streakDayRowIndicatorBigNumber : StreakColumnStyles.streakDayRowIndicator
              }
              innerStyle={StreakColumnStyles.streakDayRowIndicatorInner}
            >
              <RegDaysStreakDayText
                theme={
                  day > 99 ? themeContext.DailySpin.StreakDaysRowBigNumber : themeContext.DailySpin.StreakDaysRowNumber
                }
                length={String(day).length}
              >
                {`${day}${day > 100 ? '+' : ''}`}
              </RegDaysStreakDayText>
            </ImageBW>
          </>
        )}
        {bonusInfo?.CURRENT_REWARD_DAY !== day && (
          <RegDaysDayText day={day} opacity={opacity}>
            {day}
          </RegDaysDayText>
        )}
        <RegDayRowContentWrapper>
          <RegDaysItemWrapper>
            <RegDaysItemWrapperInnerIcon>
              <RegDaysItemCoinIcon opacity={opacity} src={icons.coin} alt="coins" />
            </RegDaysItemWrapperInnerIcon>
            <RegDaysItemWrapperInnerText flex={enableLoyalty ? 2 : 1.5}>
              <RegDaysItemText
                theme={themeContext.DailySpin.StreakDaysRowValues}
                data-label={item.coins}
                opacity={opacity}
              >
                {item.coins}
              </RegDaysItemText>
            </RegDaysItemWrapperInnerText>
          </RegDaysItemWrapper>
          {enableLoyalty && item.loyalty && item.loyalty !== '0' ? (
            <>
              <RegDaysItemWrapperCenter opacity={opacity}>{getText(TEXT_KEY.PLUS_SYMBOL)}</RegDaysItemWrapperCenter>
              <RegDaysItemWrapperRight>
                <EmptyDivSpace flex={0.4} />
                <RegDaysItemWrapperInnerIcon paddingright={5}>
                  <ImageBW
                    source={icons.loyalty}
                    ibw={require(asset`loyalty-icon.ibw`)}
                    resizeMode="contain"
                    style={{
                      ...themeContext.DailySpin.RegDaysItemLoyaltyIcon,
                      opacity: opacity,
                    }}
                  />
                </RegDaysItemWrapperInnerIcon>
                <RegDaysItemWrapperInnerText>
                  <RegDaysItemText
                    theme={themeContext.DailySpin.StreakDaysRowValues}
                    data-label={item.loyalty}
                    opacity={opacity}
                  >
                    {item.loyalty}
                  </RegDaysItemText>
                </RegDaysItemWrapperInnerText>
              </RegDaysItemWrapperRight>
            </>
          ) : (
            <EmptyDivSpace flex={enableLoyalty ? 1.3 : 0} />
          )}
        </RegDayRowContentWrapper>
      </RegDaysRow>
      {index < 8 && (
        <img src={dailyWheelAssets.DAILY_SPIN_STREAK_ROW_DIVIDER} alt="" style={StreakColumnStyles.rowDivider} />
      )}
    </React.Fragment>
  );
}

const icons = {
  coin: assetSource(ASSET_KEY.COIN_ICON),
  loyalty: assetSource(ASSET_KEY.LOYALTY_ICON),
};

