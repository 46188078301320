/**
 * Height list of iPhone devices that works for conditon x < height
 * 600 iPhone 12 mini with tab
 * 630 iPhone 15 pro with tab
 * 650 iPhone 16 pro with tab
 * 660 iPhone 12 mini without tab
 * 690 iPhone 15 pro without tab
 * 700 iPhone 15 pro max with tab
 * 710 iPhone 16 pro without tab
 * 780 iPhone 15 pro max without tab
 * Tablets:
 * 730 iPad pro 11 with tab
 * 780 iPad pro 11 without tab
 * 800 iPad mini with tab
 * 840 iPad mini without tab
 */
export const CheckoutTooltipTopPosConditions = [
  [600, 46],
  [650, 42],
  [660, 37],
  [700, 39],
  [710, 36],
  [780, 34],
];
export const CheckoutTooltipTopPosConditionsTablet = [
  [730, 26],
  [780, 23],
  [800, 25],
  [840, 23],
];

export const SkipButtonTopPosConditions = [
  [660, 14],
  [780, 15],
];

export const SkipButtonTopPosConditionsTablet = [
  [780, 12],
  [840, 12],
];

export const SkipButtonLeftPosConditions = [
  [600, 9],
  [780, 10],
];

export const SkipButtonLeftPosConditionsTablet = [
  [780, 5],
  [840, 5],
];

// CHECK_GOAL Step = 2
export const CheckGoalStepTopPosConditions = [
  [650, 43],
  [780, 48],
];

export const CheckGoalStepTopPosConditionsTablet = [
  [730, 60],
  [780, 57],
  [800, 57],
  [840, 57],
];

export const CheckGoalStepTopLayerHeightConditions = [
  [650, 61.5],
  [780, 65],
];

export const CheckGoalStepTopLayerHeightConditionsTablet = [
  [780, 75],
  [800, 74.5],
  [840, 74.5],
];

export const CheckGoalStepTopLayerLeftConditions = [
  [600, 86],
  [630, 85.5],
  [660, 85],
  [690, 85.5],
  [780, 84.5],
];

export const CheckGoalStepTopLayerLeftConditionsTablet = [[840, 90]];

export const CheckGoalStepBottomLayerHeightConditions = [
  [650, 58.5],
  [660, 63],
  [780, 64],
];

export const CheckGoalStepBottomLayerHeightConditionsTablet = [
  [780, 75.5],
  [800, 75.5],
  [840, 75.5],
];

export const CheckGoalStepRightLayerPosConditions = [
  [600, 118],
  [620, 116.5],
  [630, 117.5],
  [660, 117],
  [690, 117.5],
  [780, 116.5],
];

export const CheckGoalStepRightLayerPosConditionsTablet = [
  [780, 124.95],
  [840, 125],
];

export const CheckGoalStepLeftLayerPosConditions = [
  [600, 86],
  [620, 84.5],
  [630, 85.5],
  [660, 85],
  [690, 85.5],
  [780, 84.5],
];

export const CheckGoalStepLeftLayerPosConditionsTablet = [
  [780, 90],
  [840, 90],
];

// OPEN_GRADUALLY step = 3
// CHECK_REWARD step = 4
export const CheckRewardsStepTooltipTopPosConditions = [
  [650, 38],
  [660, 42],
  [710, 40],
  [780, 43],
];

export const CheckRewardsStepTooltipTopPosConditionsTablet = [
  [780, 52],
  [800, 51],
  [840, 50],
];

export const CheckRewardsStepTopLayerHeightConditions = [
  [630, 54],
  [650, 53.3],
  [660, 58],
  [780, 57],
];

export const CheckRewardsStepTopLayerHeightConditionsTablet = [
  [730, 66.5],
  [780, 66.5],
  [840, 65.5],
];

export const CheckRewardsStepBottomLayerTopConditions = [
  [650, 52],
  [660, 56],
  [780, 56],
];

export const CheckRewardsStepBottomLayerTopConditionsTablet = [
  [730, 66],
  [780, 66],
  [840, 66],
];

// COLLECT_GRAND step = 6

export const CollectGrandStepTooltipTopPosConditions = [
  [600, 41],
  [650, 40],
  [660, 44],
  [780, 43],
];

export const CollectGrandStepTooltipTopPosConditionsTablet = [
  [780, 52],
  [840, 51],
];

export const CollectGrandStepTopLayerHeightConditions = [
  [630, 54],
  [650, 53.3],
  [660, 58],
  [780, 57],
];

export const CollectGrandStepTopLayerHeightConditionsTablet = [
  [780, 66.5],
  [840, 65.5],
];

export const CollectGrandStepBottomLayerTopConditions = [
  [650, 52],
  [780, 56],
];

export const CollectGrandStepBottomLayerTopConditionsTablet = [[840, 66]];

// START_MISSION step = 7

export const StartMissionstepTooltipTopPosConditions = [
  [660, 15],
  [780, 14],
];

export const StartMissionstepTooltipTopPosConditionsTablet = [
  [780, 12],
  [840, 11],
];

// SEE_PROGRESS step = 8
// COMPLETED step = 9
// EXPLORE step = 1
export const SeeProgressStepTooltipTopPosConditions = [[780, 15]];

export const SeeProgressStepTooltipTopPosConditionsTablet = [
  [780, 11],
  [840, 11],
];

export const SeeProgressStepTooltipRightPosConditionsTablet = [
  [780, 'calc(52vw - 610px)'],
  [840, 'calc(46vw - 610px)'],
];

