import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import LobbyOverlay from './LobbyOverlay';
import { connect } from '../../node_modules/react-redux';
import appInitDispatch from '../../lib/load/redux/appInitDispatch';
var mapStateToProps = function mapStateToProps(state) {
  return {
    activeConsumables: state.consumables.active,
    appBackground: state.app.appBackground,
    coin: state.coin.value,
    loyalty: state.loyalty.value,
    device: state.device,
    gameInfo: state.gameInfo,
    ftueStep: state.currentOnboarding.ftueStep,
    ftueEnabled: state.currentOnboarding.ftueEnabled,
    favouriteGames: state.favouriteGames,
    ftue: state.ftue,
    initiatePurchase: state.purchase.initiatePurchase,
    modal: state.modal,
    navState: state.navState.navState,
    notifications: state.notifications,
    notificationsSent: state.notifications.notificationsSent,
    onboardingsSeen: state.ftue.onboardingsSeen[state.playerInfo.playerId],
    onboardingsUnlockLevels: state.ftue.onboardingsUnlockLevels,
    pendingMessages: state.pendingMessages,
    showTooltip: state.tooltip.shouldShowTooltip,
    token: state.token.token,
    tooltipDetails: state.tooltip.tooltipDetails.tooltipDetails,
    userLevel: state.levelInfo.level,
    assetsUrls: state.app.assetsUrls,
    initAppResponse: state.load.initAppResponse,
    lastInitApp: state.load.lastInitApp,
    isInGame: state.gameInfo.gameId !== '' && state.gameInfo.gameProvider !== '',
    playerId: state.playerInfo.playerId,
    showCustomModal: state.app.showCustomModal,
    updatedTerms: state.properties.data.isTermsUpdated,
    actionCommands: state.actionCommands.actions,
    promoActionRunning: state.actionCommands.promoActionRunning,
    tutorialStep: state.tutorial.tutorialStep,
    missions: state.missions.data
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return _objectSpread({}, appInitDispatch(dispatch));
};
export default connect(mapStateToProps, mapDispatchToProps)(LobbyOverlay);