import { isTablet, isMobile } from 'react-device-detect';
import generateRender from '../generateRender';
import { pwaStyleAdapter } from '../../../web/src/utils/mobileSupported';
var windowWidth = window.innerWidth;
export var termsModalHeaderfontSizeCalc = function termsModalHeaderfontSizeCalc(str) {
  var textLength = str === null || str === void 0 ? void 0 : str.length;
  var fontSize = 20;
  if (textLength < 30) fontSize = isMobile ? pwaStyleAdapter(isTablet ? 28 : 22, 'px') : 22;
  if (textLength < 20) fontSize = isMobile ? pwaStyleAdapter(isTablet ? 30 : 24, 'px') : 29;
  return {
    fontSize: fontSize
  };
};
export var dailyWheelCollectButtonFontSizeCalc = function dailyWheelCollectButtonFontSizeCalc(str) {
  var textLength = str === null || str === void 0 ? void 0 : str.length;
  var fontSize = '2em';
  if (windowWidth < 1600) {
    if (textLength > 8) fontSize = '1.7em';
  } else {
    fontSize = '2.5em';
    if (textLength > 8) fontSize = '2em';
  }
  return {
    fontSize: fontSize
  };
};
export var rewardOffersCoinSizeCalc = function rewardOffersCoinSizeCalc(text) {
  var topPositionCalc = function topPositionCalc(textLength) {
    return function () {
      return generateRender({
        default: textLength >= 20 ? '-27%' : '-29%',
        ti: textLength >= 20 ? '-29%' : '-31%'
      });
    };
  };
  var textLength = text === null || text === void 0 ? void 0 : text.length;
  if (textLength >= 34) return {
    fontSize: '0.5em',
    coinSize: '20px',
    top: topPositionCalc(textLength)
  };
  if (textLength >= 20) return {
    fontSize: '0.6em',
    coinSize: '22px',
    top: topPositionCalc(textLength)
  };
  return {
    fontSize: '1em',
    coinSize: '25px',
    top: topPositionCalc(textLength)
  };
};