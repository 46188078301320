/* eslint-disable no-undef */
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

function useMediaMetadata() {
  useEffect(() => {
    if (isMobile && 'mediaSession' in navigator && typeof MediaMetadata !== 'undefined') {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: ENVIRONMENT_VARIABLES.TITLE,
        artist: ENVIRONMENT_VARIABLES.TITLE,
        artwork: [{ src: '/icons/icon-512.png', sizes: '512x512', type: 'image/png' }],
      });
    }
  }, []);
}

export default useMediaMetadata;

