import { useCallback, useEffect } from 'react';
import { BROWSER_EVENTS, PENDING_MESSAGE } from 'shared/constants';
import Logger from 'shared/lib/analytics/logger';
import { useDispatch } from 'shared/node_modules/react-redux';
import { setLastActive } from 'shared/state/actions/auth';
import { addPendingMessage } from 'shared/state/actions/pendingMessage';
import audio from 'shared/utils/audio';
import QueryParamProxy from 'shared/utils/queryParamProxy';
import { BONUS_LINK, PROMO_LINK } from 'shared/utils/routeParamConstants';

const useAuthSwitch = () => {
  const dispatch = useDispatch();

  const handleLink = useCallback(() => {
    const bonusLink = {
      hash: QueryParamProxy.findParam(BONUS_LINK.HASH, false),
      id: QueryParamProxy.findParam(BONUS_LINK.ID, false),
    };
    const promoLink = {
      code: QueryParamProxy.findParam(PROMO_LINK.CODE, false),
    };

    if (bonusLink.id && bonusLink.hash) {
      dispatch(
        addPendingMessage({
          type: PENDING_MESSAGE.BONUS_LINK,
          ...bonusLink,
        })
      );
    } else if (promoLink.code) {
      dispatch(
        addPendingMessage({
          type: PENDING_MESSAGE.PROMO_LINK,
          code: promoLink.code,
          active: false,
        })
      );
      QueryParamProxy.setQueryParams({ [PROMO_LINK.CODE]: undefined });
    }
    Logger.storeQueryString(window.location.search);
  }, [dispatch]);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      audio.pauseSfx('backgroundMusic');
      dispatch(setLastActive(Date.now()));
    } else {
      audio.playMusic('backgroundMusic');
    }
  }, [dispatch]);

  useEffect(() => {
    handleLink();
  }, [handleLink]);

  useEffect(() => {
    document.addEventListener(BROWSER_EVENTS.VISIBILTY_CHANGE, handleVisibilityChange, false);
    return () => {
      document.removeEventListener(BROWSER_EVENTS.VISIBILTY_CHANGE, handleVisibilityChange, false);
    };
  }, []);
};

export default useAuthSwitch;

