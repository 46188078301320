import React, { useContext } from 'react';
import { useSelector } from 'shared/node_modules/react-redux';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import SSettings from 'shared/screens/Settings';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import Account from './Account/Account';
import CustomerSupport from './components/CustomerSupport';
import ChangePassword from './components/ChangePassword';
import UpdateEmail from './components/UpdateEmail';
import ImageBW from '../../components/ImageBoundsWrapper';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { isHri } from 'shared/utils/targetApplication';
import Button from '../../components/Button/Button';
import { listButtonTextStyle, styles } from './customStyles';
import useNotification from 'shared/hooks/useNotification';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import LanguageSwitcher from './components/LanguageSwitcher';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const SettingsComponents = {
  account: Account,
  languageSwitcher: LanguageSwitcher,
  customerSupport: CustomerSupport,
  changePassword: ChangePassword,
  updateEmail: UpdateEmail,
};

const Settings = props => {
  const { Settings: SettingsStyles } = useContext(ThemeContext);
  const { addNotification } = useNotification();

  const property = useSelector(state => {
    const { userProperty, propertySelector } = state.properties.data;
    return propertySelector.find(property => property.propertyId === userProperty);
  });

  const platformLogoutHandler = callback => {
    addNotification({
      title: getText(TEXT_KEY.SIGN_OUT),
      message: getText(TEXT_KEY.CONFIRM_LOG_OUT),
      button: getText(TEXT_KEY.LEAVE),
      buttonAction: () => {
        callback();
      },
      hasCancelButton: true,
      hideCloseButton: true,
      hideHelpButton: true,
    });
  };

  return (
    <SSettings
      navigation={props.navigation}
      dismissModal={props.dismissModal}
      platformLogout={platformLogoutHandler}
      render={args => {
        const {
          logoutHandle,
          isGuest,
          settingsScreen,
          onChangeComponentHandler,
          goBackHandler,
          titleEnum,
          screensEnum,
          showPopUp,
          popUpTitle,
          showCheatMenu,
          onClickCheatMenu,
          privacyPolicy,
          termsAndConditions,
          isLocalizationEnabled,
        } = args;

        const CurrentSettingsComponent = SettingsComponents[settingsScreen] || <div />;

        const version = `${ENVIRONMENT_VARIABLES.VERSION_MAJOR}.${ENVIRONMENT_VARIABLES.VERSION_MINOR}.${ENVIRONMENT_VARIABLES.VERSION_PATCH}.${ENVIRONMENT_VARIABLES.VERSION_BUILD}`;

        const createListItem = (titleKey, handle, showDivider = true) => ({
          title: getText(titleKey),
          handle,
          showDivider,
        });

        const supportItems = [
          createListItem(TEXT_KEY.CONTACT_SUPPORT, () => onChangeComponentHandler(screensEnum.customerSupport)),
          createListItem(TEXT_KEY.TERMS_AND_CONDITIONS, () =>
            window.open(property?.termsAndConditionsURL || termsAndConditions)
          ),
          createListItem(
            TEXT_KEY.PRIVACY_POLICY,
            () => window.open(property?.privacyPolicyURL || privacyPolicy),
            false
          ),
        ];
        const accountItems = [
          createListItem(TEXT_KEY.ACCOUNT, () => onChangeComponentHandler(screensEnum.account)),
          isLocalizationEnabled &&
            createListItem(TEXT_KEY.LOCALIZATION_TITLE, () => onChangeComponentHandler(screensEnum.languageSwitcher)),
        ].filter(Boolean);

        return (
          <div style={SettingsStyles.BackgroundLightDivider}>
            <div className="settings-header" style={styles.header}>
              <ImageBW
                source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
                ibw={require(asset`full-modal-header.ibw`)}
                style={SettingsStyles.HeaderImage}
              >
                <div style={SettingsStyles.HeaderContent}>
                  {settingsScreen !== screensEnum.settings && (
                    <div style={SettingsStyles.BackArrowContainer} onClick={() => goBackHandler()}>
                      <ImageBW
                        source={assetSource(ASSET_KEY.BACK_ARROW)}
                        ibw={require(asset`back-arrow.ibw`)}
                        style={styles.backArrow}
                        alt="Close"
                        widthScale={0.5}
                      />
                    </div>
                  )}
                  <span
                    className={isHri ? 'settings-title hri' : 'settings-title'}
                    data-label={titleEnum[showPopUp ? popUpTitle : settingsScreen] || titleEnum.settings}
                  >
                    {titleEnum[showPopUp ? popUpTitle : settingsScreen] || titleEnum.settings}
                  </span>
                </div>
              </ImageBW>
            </div>

            <div className="settings-container">
              {settingsScreen === screensEnum.settings ? (
                <>
                  <div className="actions-container">
                    <div className="action-column">
                      {supportItems.map(({ title, handle, showDivider }) =>
                        getListButton(title, handle, showDivider, SettingsStyles)
                      )}
                    </div>
                    <div className="action-column">
                      {accountItems.map(({ title, handle }) => getListButton(title, handle, true, SettingsStyles))}
                      {isLocalizationEnabled ? (
                        getSmallSwitch(args, SettingsStyles)
                      ) : (
                        <>
                          {getSwitch(TEXT_KEY.SOUND_EFFECTS, args.toggleSound, args.soundEffects, SettingsStyles)}
                          {getSwitch(TEXT_KEY.MUSIC, args.toggleMusic, args.music, SettingsStyles, false)}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="bottom-container">
                    {!isGuest && (
                      <Button
                        textStroke={BUTTON_STROKES.PRIMARY}
                        imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                        onClick={logoutHandle}
                        label={getText(TEXT_KEY.SIGN_OUT)}
                        containerStyle={styles.signOutButton}
                        textStyle={SettingsStyles.SignoutButtonText}
                        objectFit={'contain'}
                      />
                    )}
                    <div className="version-container">
                      <div>
                        {getText(TEXT_KEY.VERSION)}
                        <span className="CanSelect">{version}</span>
                      </div>
                      <div>{getText(TEXT_KEY.SETTINGS_COPYRIGHT)}</div>
                    </div>
                    {showCheatMenu && (
                      <button className="cheat-menu-button" data-label="Cheat Menu" onClick={onClickCheatMenu}>
                        {getText(TEXT_KEY.CHEAT_MENU)}
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <CurrentSettingsComponent getSwitch={getSwitch} version={version} {...args} />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

Settings.DismissHandler = () => {
  QueryParamsProxy.setQueryParams({
    [RouteParamConstants.PARAM_MODAL]: undefined,
    [RouteParamConstants.PARAM_SETTINGS_SCREEN]: undefined,
  });
};

Settings.AsModalProps = {
  hideBeforeSelector: isHri,
};

const getSmallSwitch = (args, SettingsStyles) => {
  const createListItem = (titleKey, handle, value) => ({
    title: getText(titleKey),
    handle,
    value,
  });
  const accountItems = [
    createListItem(TEXT_KEY.SOUND_EFFECTS, args.toggleSound, args.soundEffects),
    createListItem(TEXT_KEY.MUSIC, args.toggleMusic, args.music),
  ];

  return (
    <div className="menu-item" style={{ padding: '0.7rem 0px 1.3rem', flexDirection: 'column' }}>
      {accountItems.map(({ title, handle, value }, index) => (
        <div key={index} style={styles.switchContainer}>
          <span
            className={`${value ? 'settings-text' : 'settings-text-disabled'} ${
              ENVIRONMENT_VARIABLES.APPLICATION_TARGET
            }`}
            style={SettingsStyles.TitleMargin}
            data-label={title}
          >
            <span style={SettingsStyles.TitleShadow}>{title}</span>
          </span>
          <div>
            <label className="custom-switch" style={{ marginBottom: 0 }}>
              <>
                <input type="checkbox" checked={value} onChange={handle} readOnly />
                <span className="slider" />
              </>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

const getSwitch = (title, handle, value = false, customStyles, showDivider = true) => {
  return (
    <>
      <div className="menu-item" style={{ padding: '0.7rem 0px 1.3rem' }}>
        <span
          className={`${value ? 'settings-text' : 'settings-text-disabled'} ${
            ENVIRONMENT_VARIABLES.APPLICATION_TARGET
          }`}
          style={customStyles.TitleMargin}
          data-label={title}
        >
          <span style={customStyles.TitleShadow}>{getText(title)}</span>
        </span>
        <div>
          <label className="custom-switch" style={{ marginBottom: 0 }}>
            <>
              <input type="checkbox" checked={value} onChange={handle} readOnly />
              <span className="slider" />
            </>
          </label>
        </div>
      </div>
      {showDivider && (
        <div className="bottom-divider">
          <ImageBW
            source={assetSource(ASSET_KEY.SETTINGS_DIVIDER)}
            ibw={require(asset`./images/settings/settings-divider.ibw`)}
            style={styles.divider}
            resizeMode="stretch"
          />
        </div>
      )}
    </>
  );
};

const getListButton = (title, handle, showDivider = true, SettingsStyles) => {
  return (
    <>
      <div className="menu-item pointer" style={styles.listButton} onClick={handle}>
        <span
          className={`settings-text ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
          style={SettingsStyles.TitleMargin}
          data-label={title}
        >
          <span style={{ ...SettingsStyles.TitleShadow, ...listButtonTextStyle(title) }}>{title}</span>
        </span>
        <div className="forward-arrow-container" onClick={handle}>
          <ImageBW
            source={assetSource(ASSET_KEY.FORWARD_ARROW)}
            ibw={require(asset`./images/settings/forward-arrow.ibw`)}
            style={styles.forwardArrow}
            alt="Close"
            widthScale={0.5}
          />
        </div>
      </div>
      {showDivider && (
        <div className="bottom-divider">
          <ImageBW
            style={styles.divider}
            source={assetSource(ASSET_KEY.SETTINGS_DIVIDER)}
            ibw={require(asset`./images/settings/settings-divider.ibw`)}
            widthScale={0.5}
          />
        </div>
      )}
    </>
  );
};

export default Settings;

