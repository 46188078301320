import { useState, useEffect } from 'react';
import { DEVICE_ORIENTATION } from 'shared/constants/types';
const useCheckOrientation = () => {
  const getOrientation = () =>
    window.matchMedia('(orientation: portrait)').matches ? DEVICE_ORIENTATION.PORTRAIT : DEVICE_ORIENTATION.LANDSCAPE;

  const [orientation, setOrientation] = useState(getOrientation);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    const handleChange = e => {
      setOrientation(e.matches ? DEVICE_ORIENTATION.PORTRAIT : DEVICE_ORIENTATION.LANDSCAPE);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return {
    isPortrait: orientation === DEVICE_ORIENTATION.PORTRAIT,
    isLandscape: orientation === DEVICE_ORIENTATION.LANDSCAPE,
  };
};

export default useCheckOrientation;

