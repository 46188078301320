import useSetAppTarget from './hooks/useSetAppTarget';
import useInitFacebook from './hooks/useInitFacebook';
function AppInit({ children }) {
  useSetAppTarget();
  useInitFacebook();

  return children;
}

export default AppInit;

