import generateRender from 'shared/utils/generateRender';
import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';

const HistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const HistoryTableHeaderContainer = styled.thead`
  background-size: 100% 100%;
  background-image: ${({ background }) => `url(${background})`};
  display: table;
  width: 100%;
`;

const HistoryTableHeader = styled.tr`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const HistoryTableHeaderItem = styled.th`
  padding: 8px;
  text-align: center;
  width: ${({ customWidth }) => (customWidth ? `${customWidth}%` : '20%')};
`;

const HistoryTableBody = styled.tbody`
  display: block;
  max-height: 500px;
  width: 100%;
`;

const DefaultTableRow = styled.tr`
  display: flex;
  width: 100%;
  table-layout: fixed;
  background-color: ${({ index }) => (index % 2 === 0 ? '' : theme.palette.primary[5])};
  justify-content: ${({ withDetailsButton }) => (withDetailsButton ? 'space-evenly' : 'flex-start')};
`;

const HistoryTableRow = generateRender({
  default: DefaultTableRow,
});

const HistoryTableRowItem = styled.td`
  position: relative;
  align-self: center;
  width: 20%;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  ${({ customStyle }) => customStyle}
`;

const DefaultDescriptionRowItem = styled(HistoryTableRowItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 35%;
  margin-left: ${({ displayDetailsColumn, withDetailsButton }) =>
    displayDetailsColumn ? (withDetailsButton ? '0%' : '1%') : '10%'};
  left: ${({ displayDetailsColumn }) => (displayDetailsColumn ? '2rem' : '1rem')};
`;

const DescriptionRowItem = generateRender({
  default: DefaultDescriptionRowItem,
});

const DescriptionText = styled.p`
  margin: 0;
  width: 100%;
  text-align: left;
`;

const DescriptionImage = styled.img`
  object-fit: contain;
  height: 40px;
  width: 40px;
  ${({ ieHack }) =>
    ieHack &&
    css`
      height: 1.9em;
      width: 2em;
      align-self: center;
    `}
`;

const DefaultStateRowItem = styled(HistoryTableRowItem)`
  left: ${({ displayDetailsColumn, withDetailsButton }) =>
    displayDetailsColumn ? (withDetailsButton ? '0rem' : '0.5rem') : '1.5rem'};
`;

const StateRowItem = generateRender({
  default: DefaultStateRowItem,
});

const DefaultDateRowItem = styled(HistoryTableRowItem)`
  left: ${({ displayDetailsColumn, withDetailsButton }) =>
    displayDetailsColumn ? (withDetailsButton ? '0.2rem' : '1.5rem') : '6rem'};
`;

const DateRowItem = generateRender({
  default: DefaultDateRowItem,
});

const RemainingRowItem = styled(HistoryTableRowItem)`
  left: ${({ displayDetailsColumn, withDetailsButton }) =>
    displayDetailsColumn ? (withDetailsButton ? '0.5rem' : '-3.5rem') : '7rem'};
`;

export {
  HistoryTable,
  HistoryTableHeaderContainer,
  HistoryTableHeader,
  HistoryTableHeaderItem,
  HistoryTableBody,
  HistoryTableRow,
  HistoryTableRowItem,
  DescriptionRowItem,
  DescriptionText,
  DescriptionImage,
  StateRowItem,
  DateRowItem,
  RemainingRowItem,
};

