/**
 * EVENT_DOMAINS defines a logical group for UI events
 */
export var EVENT_DOMAINS = {
  GENERAL: 'GENERAL',
  GAME: 'GAME',
  MISSIONS: 'MISSIONS',
  ACTIONS_COMMANDS: 'ACTION_COMMANDS',
  EVENTS: 'EVENTS',
  GAME_TUTORIAL: 'GAME_TUTORIAL',
  ALLOW_NOTIFICATIONS_NATIVE_MODAL: 'ALLOW_NOTIFICATIONS_NATIVE_MODAL',
  PROMO_GRID: 'PROMO_GRID',
  WELCOME_NEWS: 'WELCOME_NEWS',
  REWARD_CENTER: 'REWARD_CENTER'
};