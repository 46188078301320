import React, { useContext, useState } from 'react';
import { filterCustomProperties } from 'shared/utils/properties';
import { Form, Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { useSelector } from 'shared/node_modules/react-redux';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import ThemeContext from 'shared/context/ThemeContext';
import { checkIfOtpRequeridedConsents } from 'shared/screens/OneTimePassword/utils';
import {
  registerCardsFormKeys,
  generateReqObject,
  setPropertyId,
  checkResponseData,
  closeModal,
  checkUserProperty,
  checkIfAllFiledsHasValue,
} from 'shared/screens/RegisterRewardCard/utils';
import { styles } from './styles';
import FormInputs from './components/FormInputs';
import FormFooter from './components/FormFooter';
import Button from '../../components/Button/Button';
import ImageBW from '../../components/ImageBoundsWrapper';
import useRegisterCardForm from 'shared/screens/RegisterRewardCard/hooks/useRegisterCardForm';
import { usePostRegisterNewCardMutation } from 'shared/state/query/RegisterNewCard';
import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA } from '../../utils/mobileSupported';

export default function RegisterRewardCard() {
  const themeContext = useContext(ThemeContext);
  const [postRegisterNewCard, result] = usePostRegisterNewCardMutation();
  const { userProperty, propertySelector } = useSelector(state => state.properties.data);
  const termsAndConditionsUrl = useSelector(state => state.assetsUrls?.termsAndConditions);

  const [activeOTPCheckbox, changeOTPCheckBoxState] = useState(false);
  const [selectedProperty, changeSelectedProperty] = useState(checkUserProperty(propertySelector, userProperty) || {});
  const { isLoading, data, error } = result;

  const showProperty = ENVIRONMENT_VARIABLES.SHOW_PROPERTY_SELECTOR === 'true';

  const submitFormHandler = formInputs => {
    const request = generateReqObject(formInputs);
    const { token, ...requestObject } = request;
    if (!showProperty) {
      requestObject[registerCardsFormKeys.PROPERTY] = userProperty;
    }
    postRegisterNewCard(requestObject);
  };

  const isWeb = true;

  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    submitForm,
    isValid,
    propertyRequiredFields,
    genericError,
  } = useRegisterCardForm({
    isWeb,
    showProperty,
    submitFormHandler,
    selectedProperty,
    userProperty,
  });

  const handleOPTCheckbox = () => {
    changeOTPCheckBoxState(state => !state);
  };

  const changeProperty = propertyId => {
    setFieldValue(registerCardsFormKeys.PROPERTY, propertyId);
    const selectedPropertyObj = propertySelector.find(property => property.propertyId === Number(propertyId));
    changeSelectedProperty(selectedPropertyObj);
  };

  const customPropertyOption =
    userProperty === 1
      ? null
      : propertySelector.filter(filterCustomProperties(userProperty)).map(property => ({
          value: property.propertyId,
          label: property.propertyName,
        }));

  const showOtpCheckbox =
    selectedProperty?.requiresConsents ||
    checkIfOtpRequeridedConsents(setPropertyId(userProperty, values[registerCardsFormKeys.PROPERTY]), propertySelector);

  const responseCheck = checkResponseData(data, error);

  const isReadyToSend = isValid && (!showOtpCheckbox || (showOtpCheckbox && activeOTPCheckbox));

  const { isPropertySelected } = values;

  const completedForm =
    (!showOtpCheckbox || (showOtpCheckbox && activeOTPCheckbox)) &&
    checkIfAllFiledsHasValue(values, propertyRequiredFields, true);

  return (
    <>
      <Header>
        <ImageBW
          source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
          ibw={require(asset`full-modal-header.ibw`)}
          style={themeContext.RegisterRewardCard.HeaderImage}
        >
          <div style={themeContext.RegisterRewardCard.HeaderTitle}>
            <span>{getText(TEXT_KEY.REGISTER_REWARD_CARD_TITLE)}</span>
          </div>
        </ImageBW>
      </Header>
      {!responseCheck?.success && (
        <p style={themeContext.RegisterRewardCard.FormDescription}>
          {getText(TEXT_KEY.REGISTER_REWARD_CARD_DESCRIPTION)}
        </p>
      )}
      <ContentWrapper success={responseCheck?.success ? 1 : 0}>
        {!responseCheck?.success ? (
          <Scrollbars
            style={styles.scrollBar}
            renderThumbVertical={props => <div {...props} className="thumb-vertical" style={{ width: '0.5rem' }} />}
            renderTrackVertical={props => <div {...props} className="track-vertical" style={{ opacity: 1 }} />}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
          >
            <ScrollContent>
              <Form className="form-container payment-info" onSubmit={handleSubmit}>
                <Form.Group className="form-input-group">
                  <FormInputs
                    propertyRequiredFields={propertyRequiredFields}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    changeProperty={changeProperty}
                    showProperty={showProperty}
                    values={values}
                    customPropertyOption={customPropertyOption}
                  />
                  {(!showProperty || isPropertySelected || customPropertyOption) && (
                    <FormFooter
                      termsAndConditionsUrl={termsAndConditionsUrl}
                      showOTPConsents={showOtpCheckbox}
                      activeOTPCheckbox={activeOTPCheckbox}
                      handleOPTCheckbox={handleOPTCheckbox}
                      selectedPropertyObj={selectedProperty}
                    />
                  )}
                  {genericError && <FormMandatoryMessage>{genericError}</FormMandatoryMessage>}
                  {responseCheck && (
                    <MessageHolder>
                      <div className="message">{responseCheck.message}</div>
                    </MessageHolder>
                  )}
                  <RowHolderCenterItem>
                    <Button
                      imageSource={
                        completedForm && !isLoading
                          ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
                          : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
                      }
                      label={getText(TEXT_KEY.REGISTER_REWARD_CARD_BUTTON)}
                      onClick={() => submitForm(isReadyToSend, result)}
                      isDebounce={true}
                      disabled={isLoading || !completedForm}
                    />
                  </RowHolderCenterItem>
                </Form.Group>
              </Form>
            </ScrollContent>
          </Scrollbars>
        ) : (
          <RowHolderCenterItem>
            <MessageHolder>
              <div className="message">{responseCheck?.message}</div>
            </MessageHolder>
            <Button
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              label={getText(TEXT_KEY.DONE)}
              onClick={closeModal}
              isDebounce={false}
            />
          </RowHolderCenterItem>
        )}
      </ContentWrapper>
    </>
  );
}

const Header = styled.div`
  width: 100%;
  height: 3.7rem;
`;

const ContentWrapper = styled.div`
  margin: 5px;
  height: ${({ success }) => (success ? 20 : (isNotStandaloneMobilePWA && !isTablet) ? 57 : 65)}vh;
`;

const ScrollContent = styled.div`
  margin: 20px;
`;

const RowHolder = styled(Row)`
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
`;

const RowHolderCenterItem = styled(RowHolder)`
  justify-content: center;
  margin-top: 2.5em;
  margin-bottom: 0.5em;
`;

const MessageHolder = styled(RowHolder)`
  justify-content: center;
  color: ${theme.palette.common[1]};
  text-align: center;
  margin-bottom: 35px;
`;

const FormMandatoryMessage = styled.p`
  text-align: center;
  color: ${theme.palette.error[1]};
`;

