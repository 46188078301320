import React from 'react';
import styled from 'styled-components';

const BattlesTabsScreen = ({ battlesAssets, rank, progress, points }) => {
  return (
    <Container>
      <Grid>
        <RankIcon src={battlesAssets.BATTLES_INFO_BOX_ICON} />
      </Grid>
      <Grid>
        <InfoText>{progress}</InfoText>
      </Grid>
      <Grid>
        <PointsIcon src={battlesAssets.BATTLES_POINTS_ICON_BATTLES_LARGE} />
        <InfoText>{points}</InfoText>
      </Grid>
    </Container>
  );
};

export default BattlesTabsScreen;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Grid = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const RankIcon = styled.img`
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  object-fit: contain;
`;

const PointsIcon = styled.img`
  display: flex;
  width: 50%;
  height: 50%;
  justify-content: center;
  align-items: center;
  object-fit: contain;
`;

const InfoText = styled.span`
  color: #ffffff;
  font-size: 7vh;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
`;

