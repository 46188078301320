import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';

const findRewardContentStyleDefault = (rewardValue, hasMysteryRewardTypeNoRevealed) => {
  let iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.5 : 0.7;
  const dynamicStyle = {
    fontSize: isNotStandaloneMobilePWA ? '2vh' : '2.25vh',
    marginBottom: '5vh',
    lineHeight: '2.25vh',
    winFontSize: hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '2.25vh'
        : '2.7vh'
      : isNotStandaloneMobilePWA
        ? '2.7vh'
        : '3.06vh',
    winMarginBottom: '6.9%',
  };
  const textLength = rewardValue?.length || 0;

  if (textLength < 25) {
    iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.3 : 0.5;
    dynamicStyle.marginBottom = '5vh';
  }

  if (textLength < 23) {
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '2.97vh'
        : '3.42vh'
      : isNotStandaloneMobilePWA
        ? '3.6vh'
        : '4.05vh';
    dynamicStyle.winMarginBottom = '7.8%';
  }

  if (textLength < 20) {
    dynamicStyle.fontSize = isNotStandaloneMobilePWA ? '2.25vh' : '2.7vh';
    dynamicStyle.lineHeight = '4.4vh';
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '2.7vh'
        : '3.15vh'
      : isNotStandaloneMobilePWA
        ? '3.33vh'
        : '3.78vh';
    dynamicStyle.winMarginBottom = '5.8%';
    iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.5 : 0.7;
  }

  if (textLength < 13) {
    dynamicStyle.fontSize = isNotStandaloneMobilePWA ? '3.6vh' : '4.05vh';
    dynamicStyle.marginBottom = '3.8vh';
    dynamicStyle.lineHeight = '5.5vh';
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed
      ? isNotStandaloneMobilePWA
        ? '2.97vh'
        : '3.42vh'
      : isNotStandaloneMobilePWA
        ? '3.6vh'
        : '4.05vh';
    dynamicStyle.winMarginBottom = '4.7%';
    iconSizeMultiplier = isNotStandaloneMobilePWA ? 0.65 : 0.95;
  }

  if (textLength === 0) {
    dynamicStyle.winMarginBottom = '6.8%';
  }

  return {
    dynamicStyle,
    iconSizeMultiplier,
  };
};

export default findRewardContentStyleDefault;

