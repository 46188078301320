import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import Utils from '../../utils';
import QueryParamsProxy from '../../utils/queryParamProxy';
import RouteParamConstants, { PurchaseTypes as paramPurchaseTypes } from '../../utils/routeParamConstants';
import { ShopTabs } from './Constants';
import { KYC_STATUS } from '../../utils/kyc/Constants';
import Screens from '../index';
import { casinoIdStatus as PlayerStatus } from '../../api/PlayerInfoConstants';
export default function buyClickedHandler(sku, name) {
  var dynamicTriggeredSaleId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (this.props.isKYCDone && this.props.KYCResult === KYC_STATUS.FAILED) {
    QueryParamsProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.KYCLockout));
    return;
  }
  var purchaseType = paramPurchaseTypes.CURRENCY;
  if (this.state && this.state.shopTab === ShopTabs.VIP) {
    purchaseType = paramPurchaseTypes.VIP;
  }
  this.props.setPurchaseType(purchaseType);
  var casinoIdStatus = this.props.casinoIdStatus;
  var queryParams = _defineProperty(_defineProperty(_defineProperty({}, RouteParamConstants.PARAM_PURCHASE_SKU, sku), RouteParamConstants.PARAM_PURCHASE_TYPE, purchaseType), RouteParamConstants.PARAM_PURCHASE_NAME, name);
  if (Utils.isWebOrMobileWeb()) {
    queryParams[RouteParamConstants.PARAM_MODAL] = Screens.PurchaseFlow;
  }
  if (dynamicTriggeredSaleId > 0) {
    queryParams[RouteParamConstants.PARAM_PURCHASE_DTS_ID] = dynamicTriggeredSaleId;
  }
  switch (casinoIdStatus) {
    case PlayerStatus.PHYSICALLY_VERIFIED:
    case PlayerStatus.DIGITALLY_VERIFIED:
    case PlayerStatus.UNVERIFIED:
    case PlayerStatus.GUEST:
    case PlayerStatus.FACEBOOK:
    case PlayerStatus.GOOGLE:
    case PlayerStatus.APPLE:
      this.props.initiatePurchase();
      break;
    default:
      this.props.initiatePurchase();
  }
  QueryParamsProxy.setQueryParams(queryParams);
}