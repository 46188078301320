import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import ThemeContext from 'shared/context/ThemeContext';
import styled from 'styled-components';
import { VIP_TIMER_OVERLAY_CONTAINER_ID } from '../LobbyOverlay/styledComponents';
import TimeRemainingText from '../TimeRemainingText';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import generateRender from 'shared/utils/generateRender';

const TimeRemainingMobile = ({ timeRemaining }) => {
  const themeContext = useContext(ThemeContext);
  const targetContainer = document.getElementById(VIP_TIMER_OVERLAY_CONTAINER_ID);

  const RenderElement = (
    <VIPTimer theme={themeContext.TimeRemaining.MobileVipTimerContainer} $targetContainer={targetContainer}>
      <ClockIconGlowing src={assetSource(ASSET_KEY.CLOCK_ICON_GLOWING)} alt={'Clock Icon'} />
      <TimeRemainingText style={themeContext.TimeRemaining.TimerLabelMobile} until={timeRemaining} />
    </VIPTimer>
  );
  const OutPut = targetContainer ? ReactDOM.createPortal(RenderElement, targetContainer) : RenderElement;
  return OutPut;
};

export default TimeRemainingMobile;

export const ClockIconGlowing = styled.img`
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translate(-50%, -50%);
  width: 2.5rem;
  height: 2.5rem;
  z-index: 10;
`;

const defaultColors = {
  BACKGROUND: '#084E75',
  BORDER_BACKGROUND: `linear-gradient(to bottom, #FFFC88 0%, #FFFC88 49%, #F38200 51%, #F38200 100%)`,
};

const ladyluckColors = {
  BACKGROUND: '#320438',
  BORDER_BACKGROUND: `linear-gradient(to bottom, #be00cb 0%, #620066 100%)`,
};

const sgaColors = {
  BACKGROUND: '#350053',
  BORDER_BACKGROUND: `linear-gradient(to bottom, #EC95F7 0%, #F63BFF 100%)`,
};

const jamulColors = {
  BACKGROUND: `linear-gradient(to bottom, #427272 0%, #315454 50%, #427272 100%)`,
  BORDER_BACKGROUND: `linear-gradient(to bottom, #FFFC88 0%, #FFFC88 49%, #F38200 51%, #F38200 100%)`,
};

const hriColors = {
  BACKGROUND: '#490F19',
  BORDER_BACKGROUND: `linear-gradient(to bottom, #FFFC88 0%, #FFFC88 49%, #F38200 51%, #F38200 100%)`,
};

const TimerColors = generateRender({
  default: defaultColors,
  ladyluck: ladyluckColors,
  jamul: jamulColors,
  hri: hriColors,
  sga: sgaColors,
});

const VIPTimer = styled.div`
  position: absolute;
  z-index: 9999;
  top: ${({ $targetContainer }) => ($targetContainer ? 2 : 8)}rem;
  left: ${({ $targetContainer }) => ($targetContainer ? -6 : 6)}rem;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10.5rem;
  height: 3rem;
  background: ${TimerColors.BACKGROUND};
  border-radius: 4rem;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 4px;
    background: ${TimerColors.BORDER_BACKGROUND};
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
  }

  ${({ theme }) => theme}
`;

