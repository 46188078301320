import DefaultPurchaseFlow from './DefaultPurchaseFlow';
import TiPurchaseFlow from './TiPurchaseFlow';
import generateRender from 'shared/utils/generateRender';

const PurchaseFlow = generateRender({
  default: DefaultPurchaseFlow,
  ti: TiPurchaseFlow,
});

export default PurchaseFlow;

