import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { format, getFullUnitName, transformUnits } from './utils';
import transformObjectResponseKeys from '../../../utils/transformObjectResponseKeys';
import transformObjectArrayResponse from '../../../utils/transformObjectArrayResponse';
import { CurrencyPackageNewsListItemKeys, CurrencyPackagesResponseKeys, CurrencyPackageItemKeys } from '../../../api/Constants';
export var getBillingProfileAdapter = function getBillingProfileAdapter(response) {
  if (response.billingProfiles !== undefined) response.billingProfiles = transformObjectArrayResponse(response.billingProfiles);
  return response;
};
export var checkToRedirectOnSuccess = function checkToRedirectOnSuccess(data, response) {
  var redirectOnSuccess = data.redirectOnSuccess,
    billing = data.billing;
  if (redirectOnSuccess && response !== null && response !== void 0 && response.success) {
    var expiryMonth = billing.expiryMonth.toString().length < 2 ? "0".concat(billing.expiryMonth) : billing.expiryMonth;
    var newLoc = "".concat(response.formurl, "?billing-cc-number=").concat(billing.cc, "&billing-cvv=").concat(billing.cvv, "&billing-cc-exp=").concat(expiryMonth).concat(billing.expiryYear);
    window.location = newLoc;
  }
};
export var currencyPackageTransformer = function currencyPackageTransformer(response) {
  var transformed = transformObjectResponseKeys(response, CurrencyPackagesResponseKeys);
  transformed.NEWS_LIST = transformObjectArrayResponse(transformed.NEWS_LIST).map(function (newsItem) {
    return transformObjectResponseKeys(newsItem, CurrencyPackageNewsListItemKeys);
  });
  var coinsPackageListInfo = transformed.PACKAGE_LIST['coins'];
  coinsPackageListInfo.items = transformObjectArrayResponse(coinsPackageListInfo.items);
  var promo_percentage = coinsPackageListInfo.promo_percentage,
    sale_percentage = coinsPackageListInfo.sale_percentage;
  coinsPackageListInfo.items = coinsPackageListInfo.items.map(function (item) {
    var transformedItem = transformObjectResponseKeys(item, CurrencyPackageItemKeys);
    return _objectSpread(_objectSpread({}, transformedItem), {}, {
      AMOUNT: format(transformedItem.AMOUNT),
      ALT_AMOUNT: format(transformedItem.ALT_AMOUNT),
      ORIGINAL_AMOUNT: format(transformedItem.ORIGINAL_AMOUNT),
      BONUS_LOYALTY_AMOUNT: format(transformedItem.BONUS_LOYALTY_AMOUNT),
      PROMO_BOOSTED_VALUE: format(transformedItem.PROMO_BOOSTED_VALUE),
      AMOUNT_INCLUDING_SALE_AND_PROMO: format(transformedItem.AMOUNT_INCLUDING_SALE_AND_PROMO),
      PROMO_BOOST_PERCENTAGE: format(promo_percentage),
      SALE_PERCENTAGE: format(sale_percentage)
    });
  });
  transformed.PACKAGE_LIST.vip_room_access.items = transformObjectArrayResponse(transformed.PACKAGE_LIST.vip_room_access.items).map(function (item) {
    var _transformUnits = transformUnits(item.duration_in_seconds, 'Sec'),
      value = _transformUnits.value,
      unitShort = _transformUnits.unit;
    var unit = getFullUnitName(unitShort, value);
    return _objectSpread(_objectSpread({}, item), {}, {
      duration_friendly: "".concat(value, " ").concat(unitShort),
      duration_friendly_full: "".concat(value, " ").concat(unit)
    });
  });
  return transformed;
};