import DefaultUpdateTerms from './components/DefaultUpdateTerms';
import TextLinksTermsLayout from './components/TextLinksTermsLayout';
import generateRender from 'shared/utils/generateRender';

const UpdateTerms = generateRender({
  default: DefaultUpdateTerms,
  ladyluck: TextLinksTermsLayout,
});

export default UpdateTerms;
