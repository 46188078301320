import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import ImageBW from '../ImageBoundsWrapper';
import { pwaStyleAdapter } from '../../utils/mobileSupported';
import { isMobile, isTablet } from 'react-device-detect';

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const TermsFooterWrapper = styled.div`
  justify-content: space-between !important;
  height: 10% !important;
  margin-top: 3%;
`;

export const TermsItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
`;

export const TermsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin: 1em auto;
  gap: ${isTablet ? '0.8rem' : '0'};

  ${!isMobile && `
    @media only screen and (max-width: 1400px) and (max-height: 800px) {
      gap: 0px;
    }

    @media only screen and (max-width: 1600px) {
      gap: 0px;
    }
  `}
  
`;

export const TermsLinkText = styled.a`
  display: flex;
  flex: 1.1;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  font-size: ${pwaStyleAdapter(1.3, 'rem')};
  font-weight: 800;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-underline-offset: 8px;
  color: ${theme.palette.common[1]};

  @media only screen and (max-width: 1400px) and (max-height: 800px) {
    font-size: ${pwaStyleAdapter(1.1, 'rem')};
  }

  @media only screen and (max-width: 1300px), only screen and (min-width: 1800px) {
    font-size: ${pwaStyleAdapter(1.6, 'rem')};
  }

  &:hover {
    ${({ theme }) => theme}
  }

  ${({ theme }) => theme}
`;

export const PrivacyLinkText = styled(TermsLinkText)`
  flex: 0.9;
  justify-content: flex-end;
`;

export const TermsLinkArrow = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  transform: rotate(180deg);
  object-fit: contain;
  @media only screen and (max-width: 1400px) and (max-height: 800px) {
    height: 1.8rem;
    width: 1.8rem;
  }
`;

export const TermsUpdateScreenTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${pwaStyleAdapter(0.8, 'rem')};

  @media only screen and (max-width: 1300px), only screen and (min-width: 1800px) {
    font-size: ${isTablet ? pwaStyleAdapter(1.2, 'rem') : isMobile ? pwaStyleAdapter(1, 'rem') : '1.2rem'};
  }

  ${({ theme }) => theme}
`;

export const FooterAgreeTermsText = styled.p`
  font-weight: 300;
  font-size: ${pwaStyleAdapter(0.8, 'rem')};
  margin-left: 0.5rem !important;
  color: ${theme.palette.common[1]};
  cursor: pointer;

  @media only screen and (max-width: 1300px), only screen and (min-width: 1800px) {
    font-size: ${isTablet ? pwaStyleAdapter(1.2, 'rem') : isMobile ? pwaStyleAdapter(1, 'rem') : '1.2rem'};
  }
  
  ${({ theme }) => theme}
`;

export const CheckboxFooter = styled(ImageBW)`
  ${({ theme }) => theme}
`;

export const ErrorText = styled(TermsUpdateScreenTextWrapper)`
  text-align: center;
  color: red;
`;

