import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { messagePinkButtonStroke, messageOrangeButtonStroke } from 'shared/screens/Social/utils';
import generateRender from 'shared/utils/generateRender';
import { messageTypes } from 'shared/utils/messageTypes';

const buttonTypes = () => ({
  COLLECT: {
    background: assetSource(ASSET_KEY.ORANGE_BUTTON),
    text: getText(TEXT_KEY.COLLECT_BUTTON),
    textStroke: messageOrangeButtonStroke,
  },
  REDEEM: {
    background: assetSource(ASSET_KEY.PINK_BUTTON),
    text: getText(TEXT_KEY.REDEEM_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
  PLAY: {
    background: assetSource(ASSET_KEY.PINK_BUTTON),
    text: getText(TEXT_KEY.PLAY_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
  BUY: {
    background: assetSource(ASSET_KEY.PINK_BUTTON),
    text: getText(TEXT_KEY.BUY_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
  SNEAK_PEEK: {
    background: assetSource(ASSET_KEY.PINK_BUTTON),
    text: getText(TEXT_KEY.SNEAK_PEEK_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
});

const jamulMessageTypes = () => ({
  COINS: {
    background: assetSource(ASSET_KEY.MESSAGE_COINS_BACKGROUND),
    icon: assetSource(ASSET_KEY.MESSAGE_COINS_ICON),
    image: assetSource(ASSET_KEY.COIN_ICON),
    imageIBW: require(asset`coin-icon-inbox.ibw`),
    button: buttonTypes().COLLECT,
    type: messageTypes.COINS,
  },
  LOYALTY: {
    background: assetSource(ASSET_KEY.MESSAGE_LOYALTY_BACKGROUND),
    icon: assetSource(ASSET_KEY.MESSAGE_LOYALTY_ICON),
    image: assetSource(ASSET_KEY.MESSAGE_LOYALTY_SUBTITLE_ICON),
    imageIBW: require(asset`loyalty-icon-inbox.ibw`),
    button: buttonTypes().COLLECT,
    type: messageTypes.LOYALTY,
  },
  SNEAK_PEEK: {
    background: assetSource(ASSET_KEY.MESSAGE_SNEAKPEEK_BACKGROUND),
    icon: assetSource(ASSET_KEY.MESSAGE_SNEAKPEEK_ICON),
    button: buttonTypes().SNEAK_PEEK,
    type: messageTypes.SNEAK_PEEK,
  },
  VIP_PASS: {
    background: assetSource(ASSET_KEY.MESSAGE_VIP_BACKGROUND),
    icon: assetSource(ASSET_KEY.MESSAGE_VIP_ICON),
    button: buttonTypes().REDEEM,
    type: messageTypes.VIP_PASS,
  },
  PROMO: {
    background: assetSource(ASSET_KEY.MESSAGE_DEFAULT_BACKGROUND),
    icon: assetSource(ASSET_KEY.COINS_MAIL_ICON),
    type: messageTypes.PROMO,
  },
  OFFER: {
    background: assetSource(ASSET_KEY.MESSAGE_COINS_BACKGROUND),
    icon: assetSource(ASSET_KEY.COINS_MAIL_ICON),
    button: buttonTypes().BUY,
    type: messageTypes.OFFER,
  },
  GAME: {
    background: assetSource(ASSET_KEY.MESSAGE_DEFAULT_BACKGROUND),
    icon: assetSource(ASSET_KEY.COINS_MAIL_ICON),
    button: buttonTypes().PLAY,
    type: messageTypes.GAME,
  },
});

const defaultMessageTypes = () => ({
  COINS: {
    background: assetSource(ASSET_KEY.COINS_DEFAULT),
    icon: assetSource(ASSET_KEY.COINS_MAIL_ICON),
    image: assetSource(ASSET_KEY.COIN_ICON),
    imageIBW: require(asset`coin-icon-inbox.ibw`),
    button: buttonTypes().COLLECT,
    type: messageTypes.COINS,
  },
  LOYALTY: {
    background: assetSource(ASSET_KEY.MESSAGE_INBOX_LOYALTY_PROMO),
    icon: assetSource(ASSET_KEY.LOYALTY_MAIL_ICON),
    image: assetSource(ASSET_KEY.LOYALTY_ICON),
    imageIBW: require(asset`loyalty-icon-inbox.ibw`),
    button: buttonTypes().COLLECT,
    type: messageTypes.LOYALTY,
  },
  SNEAK_PEEK: {
    background: assetSource(ASSET_KEY.SNEAK_PEEK_BACKGROUND),
    icon: assetSource(ASSET_KEY.ICON_SNEAK_PEEK),
    button: buttonTypes().SNEAK_PEEK,
    type: messageTypes.SNEAK_PEEK,
  },
  VIP_PASS: {
    background: assetSource(ASSET_KEY.VIP_MESSAGE_BACKGROUND),
    icon: assetSource(ASSET_KEY.VIP_MESSAGE_ICON),
    button: buttonTypes().REDEEM,
    type: messageTypes.VIP_PASS,
  },
  PROMO: {
    background: '',
    icon: assetSource(ASSET_KEY.COINS_MAIL_ICON),
    type: messageTypes.PROMO,
  },
  OFFER: {
    background: assetSource(ASSET_KEY.COINS_DEFAULT),
    icon: assetSource(ASSET_KEY.COINS_MAIL_ICON),
    button: buttonTypes().BUY,
    type: messageTypes.OFFER,
  },
  GAME: {
    background: '',
    icon: assetSource(ASSET_KEY.COINS_MAIL_ICON),
    button: buttonTypes().PLAY,
    type: messageTypes.GAME,
  },
});

export const messagesTypes = generateRender({
  default: defaultMessageTypes,
  jamul: jamulMessageTypes,
  ladyluck: jamulMessageTypes,
});

export const closeButton = generateRender({
  default: assetSource(ASSET_KEY.CLOSE_BUTTON_DANGER),
});

