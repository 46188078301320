import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { Spinner } from 'react-bootstrap';
import Signup from '../Signup';
import TermsModal from '../../components/TermsModal';
import { landingPageTemplates } from './landingPageTemplates';
import {
  TrackingURLContainer,
  ContentContainer,
  ClientLogo,
  PageDescriptionContainer,
  MainImage,
  PageDescription,
  LinkIconHolder,
  InnerDiv,
  Badge,
  PageBottomText,
  MainContainer,
  FormContainer,
  FormTitle,
  SignInButtonWrapper,
  SignInButtonImage,
  PlayAsGuestButtonWrapper,
} from './styledComponents';
import audio from 'shared/utils/audio';
import Button from '../../components/Button/Button';
import { availableLoginMethodsCount } from 'shared/screens/Start/utils/loginMethodsCounter';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../utils/mobileSupported';
import CustomScrollBar from '../../components/CustomScrollBar';

const TrackingURL = props => {
  const {
    landingPageState: {
      template,
      backgroundImage,
      mainImage,
      firstCheckBoxClicked,
      secondCheckBoxClicked,
      trackingPageDescription,
      trackingPageBottomTxt,
      authLoading,
      statusMessage,
      socialError,
      socialErrorType,
      error,
      isTermsModalVisible,
      terms,
      termsAccepted,
      selectedTerms,
    },
    updateLandingPageState,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    navigation,
    onFacebookActivate,
    onGuestActivate,
    redirectToSplash,
    aff_id,
    closeModal,
    onSubmit,
    closeTermsModal,
    checkSelectedTerms,
  } = props;

  const isSignUpEnabled = template === landingPageTemplates.signupEnabled;
  const { isFacebookAvailable } = availableLoginMethodsCount();

  const setFirstCheckBoxClicked = () => {
    audio.onClick();
    updateLandingPageState({ firstCheckBoxClicked: !firstCheckBoxClicked });
  };

  const setSecondCheckBoxClicked = () => {
    audio.onClick();
    updateLandingPageState({ secondCheckBoxClicked: !secondCheckBoxClicked });
  };

  const setTerms = () => {
    audio.onClick();
    updateLandingPageState({ termsAccepted: !termsAccepted, terms: !terms });
  };

  return (
    <>
      <TrackingURLContainer backgroundImage={`url(${backgroundImage})`}>
        {isSignUpEnabled && (
          <>
            <ClientLogo src={assetSource(ASSET_KEY.TRACKING_URL_LOGO)} alt="" isSignUpEnabled={isSignUpEnabled} />
            <PageDescriptionContainer>
              <PageDescription>{trackingPageDescription}</PageDescription>
            </PageDescriptionContainer>
          </>
        )}
        <CustomScrollBar>
          <MainContainer>
            {isSignUpEnabled && (
              <>
                <FormContainer>
                  <FormTitle>{getText(TEXT_KEY.SIGNUP_TITLE)}</FormTitle>
                  <Signup
                    isModal={false}
                    isLandingPage={true}
                    zIndex={101}
                    firstCheckBoxClicked={firstCheckBoxClicked}
                    secondCheckBoxClicked={secondCheckBoxClicked}
                    setFirstCheckBoxClicked={setFirstCheckBoxClicked}
                    setSecondCheckBoxClicked={setSecondCheckBoxClicked}
                    enableRegisterButton={firstCheckBoxClicked}
                    termsAndConditionsUrl={termsAndConditionsUrl}
                    privacyPolicyUrl={privacyPolicyUrl}
                    navigation={navigation}
                  />
                  {ENVIRONMENT_VARIABLES.PLAY_AS_GUEST === 'true' && (
                    <PlayAsGuestButtonWrapper>
                      <Button
                        imageStyle={{ width: pwaStyleAdapter(15, 'em') }}
                        label={getText(TEXT_KEY.PLAY_AS_GUEST)}
                        imageSource={assetSource(ASSET_KEY.GREEN_BUTTON_LANDING)}
                        textStyle={{ fontSize: '21px', fontWeight: '800' }}
                        onClick={onGuestActivate}
                        textStroke={'black'}
                      />
                    </PlayAsGuestButtonWrapper>
                  )}
                  {isFacebookAvailable && (
                    <SignInButtonWrapper className="splash-facebook-button" onClick={onFacebookActivate}>
                      <SignInButtonImage src={assetSource(ASSET_KEY.FACEBOOK_BUTTON)} />
                    </SignInButtonWrapper>
                  )}
                </FormContainer>
              </>
            )}
            <ContentContainer isSignUpEnabled={isSignUpEnabled}>
              {!isSignUpEnabled && (
                <>
                  <ClientLogo src={assetSource(ASSET_KEY.TRACKING_URL_LOGO)} alt="" />
                  <PageDescriptionContainer>
                    <PageDescription>{trackingPageDescription}</PageDescription>
                  </PageDescriptionContainer>
                </>
              )}
              <MainImage src={mainImage} alt="" onError="this.onerror=null" />
              <LinkIconHolder>
                {ENVIRONMENT_VARIABLES.APPLE_STORE_URL && (
                  <InnerDiv>
                    <a href={ENVIRONMENT_VARIABLES.APPLE_STORE_URL}>
                      <Badge src={assetSource(ASSET_KEY.BADGE_I_OS)} alt="" />
                    </a>
                  </InnerDiv>
                )}
                <InnerDiv>
                  <a href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
                    <Badge src={assetSource(ASSET_KEY.BADGE_ANDROID)} alt="" />
                  </a>
                </InnerDiv>
                <InnerDiv>
                  <div href="#" onClick={() => redirectToSplash(aff_id.current)}>
                    <Badge src={assetSource(ASSET_KEY.BADGE_WEB)} alt="" />
                  </div>
                </InnerDiv>
              </LinkIconHolder>
              <PageBottomText>{trackingPageBottomTxt}</PageBottomText>
            </ContentContainer>
          </MainContainer>
        </CustomScrollBar>
      </TrackingURLContainer>
      <TermsModal
        isVisible={isTermsModalVisible}
        closeModal={closeModal}
        closeRetryModal={closeModal}
        terms={terms}
        setTerms={setTerms}
        onSubmit={onSubmit}
        onFacebookActivate={onFacebookActivate}
        closeTermsModal={closeTermsModal}
        selectedTerms={selectedTerms}
        checkSelectedTerms={checkSelectedTerms}
        error={error}
        socialError={socialError}
        socialErrorType={socialErrorType}
        statusMessage={statusMessage}
        clientText={getText(TEXT_KEY.CLIENT_TITLE)}
        termsAndConditionsUrl={termsAndConditionsUrl}
        privacyPolicyUrl={privacyPolicyUrl}
      />
      {authLoading && <Spinner style={{ position: 'absolute' }} animation="grow" className="custom-spinner" />}
    </>
  );
};

export default TrackingURL;

