import React from 'react';
import generateRewardCenterCardDataRendering from 'shared/screens/RewardCenter/utils/generateRewardCenterCardDataRendering';
import RewardCenterCardContainer from './RewardCenterCard';
import styled from 'styled-components';
import { sendRewardCenterEvent } from 'shared/screens/RewardCenter/analytics/rewardCenterlogger';
import { REWARD_CENTER_EVENT_TYPES } from 'shared/screens/RewardCenter/constants';

const RewardCenterCategories = ({
  object,
  rewardCenterAssets,
  setFilterData,
  setShowCategories,
  setCategorySelected,
  RewardsImageBaseURL,
}) => {
  const { Frame } = generateRewardCenterCardDataRendering(object, rewardCenterAssets);
  const { categoryId, categoryName, categoryDescription, categoryImage } = object;

  const handleClick = () => {
    setFilterData(categoryName);
    setCategorySelected(categoryId);
    setShowCategories(false);
    sendRewardCenterEvent(REWARD_CENTER_EVENT_TYPES.OPEN_REWARD_CENTER_CATEGORY, { categoryId });
  };

  return (
    <RewardCenterCardContainer handleClick={handleClick} Frame={Frame}>
      <CardContent>
        <CardImage src={`${RewardsImageBaseURL}${categoryImage}`} />
        <DescriptionWrapper>
          <Title>{categoryName}</Title>
          <Subtitle>{categoryDescription}</Subtitle>
        </DescriptionWrapper>
      </CardContent>
    </RewardCenterCardContainer>
  );
};

export default RewardCenterCategories;

const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: relative;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
  height: 75%;
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: relative;
  flex: 4;

  @media only screen and (max-height: 650px) {
    margin-bottom: 0;
  }
`;

const Title = styled.p`
  font-size: 1rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0px -1.5px 2px #000;
  font-weight: 600;
  margin-bottom: 0;
  @media only screen and (min-height: 660px) {
    font-size: 1.4rem;
  }
  @media only screen and (min-height: 900px) {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  @media only screen and (min-height: 1000px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.p`
  font-size: 0.8rem;
  color: #fff;
  text-shadow: 0px -1.5px 1px #000;
  font-weight: 600;
  @media only screen and (min-height: 660px) {
    font-size: 1rem;
    margin-bottom: 0;
  }
  @media only screen and (min-height: 900px) {
    font-size: 1.2rem;
  }
`;

