import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { hasExplorerType, hasReadyState, hasRunningState } from 'shared/screens/Missions/utils/missionsPerformance';
import ProgressBarShineAnimation from './ProgressBarShineAnimation';
import styled, { css } from 'styled-components';
import { generateLayoutProgressbar } from 'shared/screens/Missions/utils/generateMissionRendering';
import { isNotStandaloneMobilePWA } from '../../../../utils/mobileSupported';
import { isSmallerScreenHeight } from '../../utils';

const ProgressBar = ({
  missionStatus,
  missionType,
  counter,
  shouldRunShineAnimation,
  missionsAssets,
  clientProgress,
  grandPrizeCard,
}) => {
  const { percentage, goal, value } = counter;

  const {
    LayoutBar = null,
    LayoutBarPurple = null,
    ShadowBar = null,
    IconLocked = null,
    IconReady = null,
    FillBarStart = null,
    FillBarProgess = null,
    FillBarEnd = null,
  } = generateLayoutProgressbar(missionsAssets)[missionStatus];

  const currentPercentageText = () => {
    if (hasExplorerType(missionType)) {
      return hasRunningState(missionStatus) ? `${Math.floor(clientProgress)}%` : `${percentage}%`;
    } else return `${value}/${goal}`;
  };

  const currentPercentageBar =
    hasExplorerType(missionType) && hasRunningState(missionStatus) ? Math.floor(clientProgress) : percentage;

  return (
    <>
      {IconLocked && <IconStatusLocked src={IconLocked} resizeMode="contain" />}
      {IconReady && <IconStatusReady src={IconReady} />}
      {shouldRunShineAnimation && <ProgressBarShineAnimation shineAsset={missionsAssets.ANIMATIONS_LIGHT_SWEEP_17} />}
      {FillBarStart && (
        <FillBar>
          <FillStart src={FillBarStart} />
          <TextProgress grandPrizeCard={grandPrizeCard}>{currentPercentageText()}</TextProgress>
          <FillProgress src={FillBarProgess} percentage={currentPercentageBar} resizeMode="stretch" />
          <FillEnd src={FillBarEnd} />
        </FillBar>
      )}
      {!hasReadyState(missionStatus) && (
        <>
          <ShadowBarCard src={ShadowBar} />
          <BarCard src={hasExplorerType(missionType) ? LayoutBar : LayoutBarPurple} />
        </>
      )}
    </>
  );
};

export default ProgressBar;

const isNotStandalonePWAOnlyMobile = isNotStandaloneMobilePWA && !isTablet;
const isNotStandalonePWAOnlyTablet = isNotStandaloneMobilePWA && isTablet;

const barTotalWidthVh = isNotStandalonePWAOnlyTablet ? 32 : isNotStandalonePWAOnlyMobile ? 28 : 36;
const barTotalHeightVh = isNotStandalonePWAOnlyMobile ? 4.5 : 5;
const horizontalMarginVh = isNotStandalonePWAOnlyMobile ? 2.1 : 2.4;
const barFillWidthVh = isNotStandalonePWAOnlyMobile ? 2.2 : 2.5;
const barFillMarginVh = isNotStandalonePWAOnlyMobile ? 0.27 : 0.2;
const barFillHeightVh = isNotStandalonePWAOnlyMobile ? 4 : 4.6;
const textProgressFontSizeVh = barTotalHeightVh - barFillMarginVh * 2;

const BarCardSmall = css`
  bottom: 15.2vh;
  left: 3.8vh;
`;

const BarCard = styled.img`
  position: absolute;
  bottom: 19vh;
  left: 5vh;
  height: ${barTotalHeightVh}vh;
  width: ${barTotalWidthVh}vh;
  z-index: 3;
  ${isNotStandalonePWAOnlyMobile && BarCardSmall}
`;

const ShadowBarCardSmall = css`
  bottom: 15.2vh;
  left: 3.8vh;
`;

const ShadowBarCard = styled.img`
  position: absolute;
  bottom: 19vh;
  left: 5vh;
  height: ${barTotalHeightVh}vh;
  width: ${barTotalWidthVh}vh;
  z-index: 5;
  ${isNotStandalonePWAOnlyMobile && ShadowBarCardSmall}
`;

const FillBarSmall = css`
  bottom: 15.6vh;
  left: 4.1vh;
`;

const FillBar = styled.div`
  position: absolute;
  bottom: 19.4vh;
  z-index: 4;
  left: 5.3vh;
  ${isNotStandalonePWAOnlyMobile && FillBarSmall}
`;

const FillStart = styled.img`
  margin-left: 0;
  margin-right: -1px;
  height: ${barFillHeightVh}vh;
  width: ${barFillWidthVh}vh;
  z-index: 4;
`;
const FillProgress = styled.img`
  height: ${barFillHeightVh}vh;
  width: ${props =>
    (Math.min(props.percentage, 98.5) * (barTotalWidthVh - horizontalMarginVh - barFillWidthVh)) / 100}vh;
  z-index: 4;
`;
const FillEnd = styled.img`
  margin-left: -1px;
  height: ${barFillHeightVh}vh;
  width: ${barFillWidthVh}vh;
  z-index: 4;
  transform: scaleX(-1);
`;

const TextProgressSmallScreen = css`
  top: ${({ grandPrizeCard }) => (grandPrizeCard ? -0.1 : -0.3)}vh;
  font-size: ${isNotStandalonePWAOnlyMobile ? 1.5 : 1.8}rem;
`;

const TextProgressSmallScreentablet = css`
  top: ${({ grandPrizeCard }) => (grandPrizeCard ? -0.1 : -0.3)}vh;
  font-size: ${isNotStandalonePWAOnlyTablet ? 1.8 : 2}rem;
`;

const TextProgress = styled.p`
  position: absolute;
  z-index: 5;
  color: white;
  font-weight: 900;
  font-style: italic;
  left: ${isNotStandalonePWAOnlyTablet ? 12.5 : isNotStandalonePWAOnlyMobile ? 11 : 14}vh;
  top: -${barTotalHeightVh - textProgressFontSizeVh + barFillMarginVh * 2}vh;
  font-size: ${textProgressFontSizeVh}vh;
  text-shadow: 0 0px 3px #000;
  ${isMobile && !isTablet && TextProgressSmallScreen}
  ${isTablet && TextProgressSmallScreentablet}
`;

const IconStatusLockedMobile = css`
  bottom: 12.5vh;
  left: 13.5vh;
  height: 10vh;
  width: 9vh;
`;

const IconStatusLockeTablet = css`
  left: 13.5vh;
`;

const IconStatusLocked = styled.img`
  position: absolute;
  bottom: 14.5vh;
  left: 15.5vh;
  height: 16vh;
  width: 15vh;
  z-index: 6;
  ${isNotStandalonePWAOnlyMobile && IconStatusLockedMobile}
  ${isNotStandalonePWAOnlyTablet && IconStatusLockeTablet}
`;

const IconStatusReadydMobile = css`
  bottom: ${isSmallerScreenHeight ? 11 : 11.6}vh;
  left: 12vh;
  height: 12vh;
  width: 12vh;
`;

const IconStatusReadydTablet = css`
  left: 12vh;
`;

const IconStatusReady = styled.img`
  position: absolute;
  bottom: 13vh;
  left: 14vh;
  height: 18vh;
  width: 18vh;
  z-index: 6;
  ${isNotStandalonePWAOnlyMobile && IconStatusReadydMobile}
  ${isNotStandalonePWAOnlyTablet && IconStatusReadydTablet}
`;

