import React from 'react';
import styled from 'styled-components';
import { pwaStyleAdapter } from '../../utils/mobileSupported';
function BoxyLayoutTextInputsWrapper({ children, ...props }) {
  const { error, icon, iconError, checkErrorLength = false, iconTheme = false, disableErrorWrapper = false } = props;
  return (
    <InputWrapper>
      {error && !disableErrorWrapper && (
        <ErrorWrapper>
          <ErrorMessage checkErrorLength={checkErrorLength} error={error}>
            {error}
          </ErrorMessage>
        </ErrorWrapper>
      )}
      {icon && (
        <IconWrapper theme={iconTheme}>
          <Icon error={error} icon={icon} iconError={iconError} />
        </IconWrapper>
      )}
      {children}
    </InputWrapper>
  );
}

export default BoxyLayoutTextInputsWrapper;

const InputWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: #b40201;
  height: 32px;
  border-top-right-radius: 0.6em;
  border-top-left-radius: 0.6em;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.span`
  color: #fff;
  font-size: ${({ error, checkErrorLength }) =>
    checkErrorLength && error?.length > 20
      ? window.innerWidth < 1400
        ? 0.8
        : window.innerWidth < 1900
          ? 0.85
          : 1
      : 1}em;
  font-weight: 500;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: ${pwaStyleAdapter(10, 'px')};
  height: ${pwaStyleAdapter(42, 'px')};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  ${({ theme }) => theme}
`;

const Icon = styled.img.attrs(({ error, icon, iconError }) => ({
  src: !error ? icon : iconError || icon,
}))`
  width: ${pwaStyleAdapter(15, 'px')};
  object-fit: contain;
`;

