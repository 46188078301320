import React, { useCallback, useEffect } from 'react';
import ERROR_CODE from 'shared/utils/errorCodes';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import { Logger } from '../../../lib/analytics/logger';
import { useLazyGetFacebookAppIdQuery } from 'shared/state/query/app';
function useInitFacebook() {
  const [getFacebookAppId] = useLazyGetFacebookAppIdQuery();
  const runFacebookInitSDK = useCallback(async () => {
    try {
      const appID = await getFacebookAppId().unwrap();
      if (appID) {
        FB.init({
          appId: appID,
          autoLogAppEvents: false,
          xfbml: false,
          version: 'v14.0',
        });
        window.facebookHasInitialized = true;
        if (window.facebookAppEventQueue) {
          for (let eventHandler of window.facebookAppEventQueue) {
            eventHandler();
          }
        }
      }
    } catch (error) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        code: ERROR_CODE.FACEBOOK_SDK_INIT_CATCH,
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      runFacebookInitSDK();
    }, 1500);
  }, [runFacebookInitSDK]);

  return <div></div>;
}

export default useInitFacebook;

