import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import useBattlesInfo from 'shared/screens/Battles/hooks/useBattlesInfo';
import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { pwaStyleAdapter } from '../../../utils/mobileSupported';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Button from '../../../components/Button/Button';

const BattlesHelp = ({ battlesAssets }) => {
  const {
    openModal,
    setOpenModal,
    handleOpenModal,
    tutorialScreen,
    findIndexScreen,
    handleBackScreen,
    handleFowardScreen,
  } = useBattlesInfo({ battlesAssets });

  return (
    <React.Fragment>
      <HelpContainer>
        <InfoImage onClick={handleOpenModal} src={battlesAssets.BATTLES_INFO_BUTTON_BATTLES} />
      </HelpContainer>
      <Modal show={openModal} size="xl" dialogClassName="modal-dialog-centered">
        <Modal.Body>
          <ModalContainer>
            <ImageBackground src={tutorialScreen} />
            <CloseButton onClick={() => setOpenModal(!openModal)} imageSource={assetSource(ASSET_KEY.CLOSE_BUTTON)} />
            <BackButton
              onClick={handleBackScreen}
              imageSource={assetSource(ASSET_KEY.BACK_ARROW)}
              display={findIndexScreen() === 0 ? 'none' : 'flex'}
            />
            <ForwardButton
              onClick={handleFowardScreen}
              imageSource={assetSource(ASSET_KEY.RIGHT_ARROW)}
              display={findIndexScreen() === 3 ? 'none' : 'flex'}
            />
          </ModalContainer>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default BattlesHelp;

const HelpContainerSmall = css`
  bottom: 2rem;
  left: 2rem;
`;
const HelpContainerRegular = css`
  top: ${pwaStyleAdapter(5.3, 'rem')};
`;

const HelpContainer = styled.div`
  position: absolute;
  flex: 1;
  z-index: 5;
  left: 1rem;
  z-index: 1000;
  ${isMobile && !isTablet ? HelpContainerSmall : HelpContainerRegular}
`;

const InfoImage = styled.img`
  cursor: pointer;
  height: 3.6rem;
  width: 3.6rem;
`;

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ImageBackground = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CloseButton = styled(Button).attrs({
  containerStyle: `
    position: absolute;
    top: 2vh;
    right: -1vw;
    width: 4vw;
    cursor: pointer;
    z-index: 10;
  `,
  imageStyle: `
  width: 100%;
  object-fit: contain;
`,
})``;

const BackButton = styled(Button).attrs({
  containerStyle: `
  position: absolute;
  left: 2vw;
  width: 3vw;
  cursor: pointer;
  z-index: 1;
`,
  imageStyle: `
  width: 100%;
  object-fit: contain;
`,
})``;

const ForwardButton = styled(Button).attrs({
  containerStyle: `
  position: absolute;
  right: 2vw;
  width: 3vw;
  cursor: pointer;
  z-index: 1;
`,
  imageStyle: `
  width: 100%;
  object-fit: contain;
`,
})``;

