import { useLayoutEffect } from 'react';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
const useCacheLoadingScreenAsset = () => {
  const localAsset = assetSource(ASSET_KEY.LOADING_SCREEN);
  useLayoutEffect(() => {
    const img = new Image();
    img.src = localAsset;
  }, []);
};

export default useCacheLoadingScreenAsset;

