import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useFormik } from 'formik';
import Screens from '..';
import { trimInputString } from '../../utils/string';
import QueryParamsProxy from '../../utils/queryParamProxy';
import { initialValues, validationSchema } from './login-validations';
import { usePostLoginMutation } from '../../state/query/login';
import { AUTH_TYPES } from '../../utils/authFlow/constants';
import { getText, TEXT_KEY } from '../../utils/localization';
var useLogin = function useLogin(navigation) {
  var _usePostLoginMutation = usePostLoginMutation(),
    _usePostLoginMutation2 = _slicedToArray(_usePostLoginMutation, 2),
    postLogin = _usePostLoginMutation2[0],
    isLoading = _usePostLoginMutation2[1].isLoading;
  var genericErrorMessage = getText(TEXT_KEY.PLAYER_EMAIL_UPDATE_ERROR);
  var onSumbitHandler = function onSumbitHandler(_ref) {
    var email = _ref.email,
      password = _ref.password;
    return postLogin({
      email: email,
      password: password,
      login_type: AUTH_TYPES.STANDARD
    }).unwrap().then(function (result) {
      return !result.success && formikValidation.setStatus(result.message || genericErrorMessage);
    }).catch(function (error) {
      return error && formikValidation.setStatus(error.data.message || genericErrorMessage);
    });
  };
  var onChangeHandler = function onChangeHandler(field, value) {
    var trim = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    formikValidation === null || formikValidation === void 0 || formikValidation.setErrors({});
    var fieldValue = trim ? trimInputString(value) : value;
    formikValidation === null || formikValidation === void 0 || formikValidation.setFieldValue(field, fieldValue);
  };
  var formikValidation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSumbitHandler,
    valideOnMount: false,
    validateOnChange: false,
    validateOnBlur: false
  });
  var openForgotPassword = function openForgotPassword() {
    return QueryParamsProxy.navigate(navigation, Screens.ForgotPassword);
  };
  var closeModal = function closeModal() {
    return QueryParamsProxy.navigate(navigation, Screens.Start);
  };
  return {
    formikValidation: formikValidation,
    closeModal: closeModal,
    openForgotPassword: openForgotPassword,
    isLoading: isLoading,
    onChangeHandler: onChangeHandler
  };
};
export default useLogin;