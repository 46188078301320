import theme from 'shared/assets/style/theme';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import defaultTheme from './defaultTheme';
import { isIOS, isTablet, isMobile } from 'react-device-detect';
import { pwaStyleAdapter, isNotStandaloneMobilePWA, isStandalonePWA, isIPadMini } from '../utils/mobileSupported';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const LadyluckTheme = {
  ...defaultTheme,
  AutoFormInput: {
    ...defaultTheme.AutoFormInput,
    FormOptions: {
      ...defaultTheme.AutoFormInput.FormOptions,
      color: theme.palette.common[1],
      backgroundColor: theme.palette.primary[32],
    },
  },
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: '1.5%',
    },
    StarIcon: {
      ...defaultTheme.LimitedTimeOffer.StarIcon,
      width: '16%',
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      left: 'calc(50% - 85px)',
    },
    MainContainer: {
      ...defaultTheme.LimitedTimeOffer.MainContainer,
      marginBottom: '0.2%',
      height: '23%',
    },
  },
  LobbyOverlay: {
    ...defaultTheme.LobbyOverlay,
    PinnedPanelContainer: {
      ...defaultTheme.LobbyOverlay.PinnedPanelContainer,
      paddingBottom: isMobile ? '4rem' : '1rem',
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    MileStoneRowLoyaltyIcon: {
      ...defaultTheme.DailySpin.MileStoneRowLoyaltyIcon,
      height: 32,
      width: 32,
      marginBottom: 5,
    },
    DaysStreakContainerSmallScreen: {
      ...defaultTheme.DailySpin.DaysStreakContainerSmallScreen,
      margin: isTablet && windowWidth > 1150 ? '0 1rem 0 1rem' : '0 1rem 0.5rem 1rem',
      padding: isTablet
        ? windowWidth > 1150
          ? '0px 1rem 0.5rem 1rem'
          : '0px 1rem 1rem 1rem'
        : '0rem 1rem 0.5rem 1rem',
    },
    StreakColumnWrapper: {
      ...defaultTheme.DailySpin.StreakColumnWrapper,
      bottom: isNotStandaloneMobilePWA ? '0.5rem' : 'auto',
      height: isTablet
        ? isStandalonePWA
          ? '85%'
          : windowWidth > 1150
            ? '95%'
            : '90%'
        : windowHeight < 380
          ? '100%'
          : '98%',
    },
    mileStoneRowCoinIcon: {
      ...defaultTheme.DailySpin.mileStoneRowCoinIcon,
      marginBottom: 0,
    },
    MilestoneCircleImage: {
      ...defaultTheme.DailySpin.MilestoneCircleImage,
      top: isTablet && isStandalonePWA ? -7 : windowHeight < 800 ? -10 : -20,
    },
    MilestoneCoinsValue: {
      ...defaultTheme.DailySpin.MilestoneCoinsValue,
      marginBottom: 0,
    },
    MilestoneRowContainer: {
      ...defaultTheme.DailySpin.MilestoneRowContainer,
      marginTop: windowWidth > 1800 && windowHeight > 1050 ? 5 : 0,
    },
    RegDaysItemLoyaltyIcon: {
      ...defaultTheme.DailySpin.RegDaysItemLoyaltyIcon,
      height: 20,
      width: 20,
    },
  },
  SocialHeaderTab: {
    ...defaultTheme.SocialHeaderTab,
    BackgroundImage: {
      ...defaultTheme.SocialHeaderTab.BackgroundImage,
      bottom: '0.25rem',
    },
  },
  PurchaseFlow: {
    ...defaultTheme.PurchaseFlow,
    billingProfileSelect: {
      ...defaultTheme.PurchaseFlow.billingProfileSelect,
      border: '1px solid rgb(255, 65, 229)',
      outline: 'none',
      background: theme.palette.primary[32],
    },
    PaymentInfoFields: {
      ...defaultTheme.PurchaseFlow.PaymentInfoFields,
      border: '1px solid rgb(255, 65, 229)',
      outline: 'none',
      background: theme.palette.primary[32],
    },
    creditCardField: {
      ...defaultTheme.PurchaseFlow.creditCardField,
      border: '1px solid rgb(255, 65, 229)',
      background: theme.palette.primary[32],
    },
    DownArrowIcon: {
      ...defaultTheme.PurchaseFlow.DownArrowIcon,
      position: 'absolute',
      right: '1.5rem',
      cursor: 'pointer',
      pointerEvents: 'none',
      bottom: isMobile && !isTablet ? '1.8rem' : '1.3rem',
    },
    CreditCardDetailDownArrowIcon: {
      ...defaultTheme.PurchaseFlow.CreditCardDetailDownArrowIcon,
      position: 'absolute',
      right: '1.5rem',
      cursor: 'pointer',
      pointerEvents: 'none',
      bottom: isTablet ? '1.5rem' : isMobile ? '2.2rem' : '2rem',
    },
    BillingProfileDownArrowIcon: {
      ...defaultTheme.PurchaseFlow.BillingProfileDownArrowIcon,
      position: 'absolute',
      right: '1.5rem',
      cursor: 'pointer',
      pointerEvents: 'none',
      bottom: isTablet ? '1.3rem' : isMobile ? '1.4rem' : '1rem',
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    BarSection: {
      ...defaultTheme.NavigationBar.BarSection,
      paddingBottom: '1.5rem',
    },
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      position: 'absolute',
      right: windowWidth < 1300 ? 'calc(50% - 200px)' : 'calc(50% - 205px)',
    },
    TopBar: {
      ...defaultTheme.NavigationBar.TopBar,
      height: '5.7rem',
    },
    DealTimerText: {
      ...defaultTheme.NavigationBar.DealTimerText,
      color: '#6D1337',
      top: '-1.25rem',
    },
    HomeIcon: {
      ...defaultTheme.NavigationBar.HomeIcon,
      height: 58,
      width: 58,
      marginTop: 3,
    },
    CoinsCurrencyContainer: {
      ...defaultTheme.NavigationBar.CoinsCurrencyContainer,
      marginRight: '1.5%',
    },
    SettingIcon: {
      ...defaultTheme.NavigationBar.SettingIcon,
      width: 70,
      height: 70,
    },
    DealSplittedTextGradient: {
      ...defaultTheme.NavigationBar.DealSplittedTextGradient,
      ...(isMobile && !isTablet && { marginTop: 1 }),
    }
  },
  VideoAds: {
    ...defaultTheme.VideoAds,
    CloseText: {
      ...defaultTheme.VideoAds.CloseText,
      fontSize: '16px',
    },
    KeepWatchingText: {
      ...defaultTheme.VideoAds.KeepWatchingText,
      fontSize: '16px',
    },
  },
  WatchAndEarnInfo: {
    ...defaultTheme.WatchAndEarnInfo,
    TimeRemainingText: {
      ...defaultTheme.WatchAndEarnInfo.TimeRemainingText,
      textShadow: 'none',
      fontWeight: 600,
      marginLeft: '1rem',
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: '#32003b',
    },
    BarProgress: {
      ...defaultTheme.Bar.BarProgress,
      background: '#0080ca',
    },
    BarText: {
      ...defaultTheme.Bar.BarText,
      marginLeft: 'auto',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      width: 75,
      ...(!isIOS && { top: -1 }),
    },
  },
  VipAccessFlow: {
    ...defaultTheme.VipAccessFlow,
    VipAccessButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonText,
      fontWeight: 800,
      paddingBottom: '1%',
    },
    VipAccessButtonImage: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonImage,
      width: 210,
    },
    VipAccessRedeemButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessRedeemButtonText,
      fontWeight: 800,
      color: theme.palette.common[42],
      paddingBottom: '1%',
    },
  },
  OutOfCoin: {
    ...defaultTheme.OutOfCoin,
    QuickBuyOfferDetails: {
      ...defaultTheme.OutOfCoin.QuickBuyOfferDetails,
      bottom: windowHeight > 1200 ? '12.5%' : '15.5%',
    },
  },
  DefaultTooltip: {
    ...defaultTheme.DefaultTooltip,
    Border: {
      ...defaultTheme.DefaultTooltip.Border,
      stopColor1: '#FFBF02',
      stopColor2: '#F49902',
    },
    Background: {
      ...defaultTheme.DefaultTooltip.Background,
      stopColor1: '#d636d8',
      stopColor2: '#32003b',
    },
  },
  SmartBanner: {
    ...defaultTheme.SmartBanner,
    LogoButtonStyle: {
      ...defaultTheme.SmartBanner.LogoButtonStyle,
      height: '150%',
      bottom: '30%',
      left: '-30%',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    BoxContainer: {
      ...defaultTheme.BonusLinkMessage.BoxContainer,
      top: '-10vh',
    },
    PromoImage: {
      ...defaultTheme.BonusLinkMessage.PromoImage,
      paddingTop: '376px',
    },
    BonusLinkButton: {
      ...defaultTheme.BonusLinkMessage.BonusLinkButton,
      textShadow: `1px 1px 0px ${theme.palette.secondary[0]}, 1px -1px 0px ${theme.palette.secondary[0]}, -1px 1px 0px ${theme.palette.secondary[0]}, -1px -1px 0px ${theme.palette.secondary[0]}`,
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      bottom: '-3.7rem',
    },
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      bottom: '-2.5rem',
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    PurchaseResponseHighlightHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHighlightHeader,
      fontWeight: '600',
    },
    PurchaseResponseHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHeader,
      fontWeight: '600',
    },
    RewardCenterPurchaseResponseMessage: {
      ...defaultTheme.OfferDetail.RewardCenterPurchaseResponseMessage,
      fontSize: '1em',
    },
    ExclamationIcon: {
      ...defaultTheme.OfferDetail.ExclamationIcon,
      width: 126,
      height: 'auto',
    },
    CarouselIndicator: {
      ...defaultTheme.OfferDetail.CarouselIndicator,
      backgroundColor: '#32003B',
      border: `3px solid #32003B`,
    },
    CarouselIndicatorActive: {
      ...defaultTheme.OfferDetail.CarouselIndicatorActive,
      backgroundColor: '#BE00DF',
      border: `3px solid #FFAF03`,
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    ScreenBackground: {
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
    HeaderTitle: {
      ...defaultTheme.LinkAccountForm.HeaderTitle,
      fontSize: '1.4rem',
      margin: 4,
    },
    NeedHelpText: {
      ...defaultTheme.LinkAccountForm.NeedHelpText,
      right: '1.5rem',
    },
    FormInput: {
      ...defaultTheme.LinkAccountForm.FormInput,
      background: theme.palette.primary[32],
      borderRadius: '0.6em',
      boxShadow: 'none',
      border: '1px solid transparent',
    },
  },
  LinkAccount: {
    ...defaultTheme.LinkAccount,
    LinkAccountTitle: {
      ...defaultTheme.LinkAccount.LinkAccountTitle,
      paddingTop: '0.5rem',
    },
  },
  VIPItem: {
    ...defaultTheme.VIPItem,
    LogoImage: {
      height: isTablet ? (isNotStandaloneMobilePWA ? '44%' : '50%') : isNotStandaloneMobilePWA ? '58%' : '75%',
      marginLeft: '0.5rem',
      position: 'relative',
      top: isTablet
        ? isStandalonePWA
          ? '1rem'
          : '0.5rem'
        : isMobile
          ? isNotStandaloneMobilePWA
            ? '1.5rem'
            : '1rem'
          : '-1rem',
    },
    VIPHourTile: {
      ...defaultTheme.VIPItem.VIPHourTile,
    },
    ItemPlusOffer: {
      ...defaultTheme.VIPItem.ItemPlusOffer,
      paddingTop: '0.5rem',
    },
    LoyaltyPointsLogo: {
      ...defaultTheme.VIPItem.LoyaltyPointsLogo,
      ...(!isTablet &&
        isNotStandaloneMobilePWA && {
          height: '4rem',
          width: '4rem',
        }),
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    BackgroundLightDivider: {
      ...defaultTheme.Settings.BackgroundLightDivider,
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
    HeaderImage: {
      ...defaultTheme.Settings.HeaderImage,
      height: '4.7rem',
    },
    BackArrowContainer: {
      ...defaultTheme.Settings.BackArrowContainer,
      top: '1.5rem',
    },
  },
  WelcomeBonus: {
    ...defaultTheme.WelcomeBonus,
    OfferDetails: {
      ...defaultTheme.WelcomeBonus.OfferDetails,
      bottom: '19.4%',
      left: '-2%',
    },
  },
  Wheel: {
    ...defaultTheme.Wheel,
    Amount: {
      ...defaultTheme.Wheel.Amount,
      color: theme.palette.common[1],
      textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    },
  },
  ResetPassword: {
    ...defaultTheme.ResetPassword,
    FormInputsFields: {
      ...defaultTheme.ResetPassword.FormInputsFields,
      background: theme.palette.primary[32],
      borderRadius: '0.6em',
      outline: 'none',
      boxShadow: 'none',
    },
    InputLabel: {
      ...defaultTheme.ResetPassword.InputLabel,
      color: theme.palette.common[1],
    },
    ModalStyle: {
      ...defaultTheme.ResetPassword.ModalStyle,
      padding: window.innerWidth < 1400 ? '0 40vmin' : '0 44vmin',
    },
  },
  SignUp: {
    ...defaultTheme.SignUp,
    FormInputTheme: {
      ...defaultTheme.SignUp.FormInputTheme,
      background: theme.palette.primary[32],
      borderRadius: '0.6em',
      outline: 'none',
      boxShadow: 'none',
    },
    MultipleInputInRowWrapper: {
      ...defaultTheme.SignUp.MultipleInputInRowWrapper,
      width: '49%',
    },
    TermsLink: {
      ...defaultTheme.SignUp.TermsLink,
      color: theme.palette.primary[9],
    },
    NeedHelpLink: {
      ...defaultTheme.SignUp.NeedHelpLink,
      color: theme.palette.primary[9],
    },
  },
  TermsModal: {
    ...defaultTheme.TermsModal,
    TermsLinkText: {
      ...defaultTheme.TermsModal,
      color: '#6cb6e5',
    },
    TermsBodyText: {
      fontSize: !isMobile ? '1.2rem' : '1.5rem',
    },
    TermsFooterText: {
      fontSize: '1.3rem',
    },
    TermsCheckboxFooter: {
      width: '25px !important',
      height: '25px !important',
    },
  },
  TermsModalWrapper: {
    ...defaultTheme.TermsModalWrapper,
    NeedHelpLink: {
      ...defaultTheme.TermsModalWrapper.NeedHelpLink,
      color: theme.palette.primary[9],
    },
    Modal: {
      ...defaultTheme.TermsModalWrapper.Modal,
      padding:
        isStandalonePWA && !isTablet
          ? '0 25vmin !important'
          : windowWidth < 700
            ? '0 4vmin !important'
            : windowWidth < 1600
              ? '0 20vmin !important'
              : windowWidth < 1900
                ? '0 30vmin !important'
                : '0 50vmin !important',
    },
  },
  GameUnavailable: {
    ...defaultTheme.GameUnavailable,
    ButtonText: {
      ...defaultTheme.GameUnavailable.ButtonText,
      paddingTop: '0px',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    Divider: {
      ...defaultTheme.BottomBar.Divider,
      bottom: -10,
    },
    BottomBarBackground: {
      ...defaultTheme.BottomBar.BottomBarBackground,
      height: isMobile && !isTablet ? '5.5rem' : '7rem',
    },
    WatchAndEarnIcon: {
      ...defaultTheme.BottomBar.WatchAndEarnIcon,
      height: '7.5em',
      marginBottom: isMobile && !isTablet ? '-5em' : '-3em',
    },
    MailIcon: {
      ...defaultTheme.BottomBar.MailIcon,
      height: '7.5em',
      marginBottom: isMobile && !isTablet ? '-5em' : '-3em',
    },
    SlotsIcon: {
      ...defaultTheme.BottomBar.SlotsIcon,
      height: '7.5em',
      marginBottom: isMobile && !isTablet ? '-5.1em' : '-3.1em',
    },
    VIPIcon: {
      ...defaultTheme.BottomBar.VIPIcon,
      height: '8.5em',
      marginBottom: isMobile && !isTablet ? '-5em' : '-3.1em',
    },
    RewardCenterIcon: {
      ...defaultTheme.BottomBar.SlotsIcon,
      height: '10em',
      marginBottom: isMobile && !isTablet ? '-6em' : '-3.8em',
    },
    MissionsIcon: {
      height: '8em',
      marginBottom: isMobile && !isTablet ? '-5.2em' : '-3.2em',
    },
    BarItemIcon: {
      ...defaultTheme.BottomBar.BarItemIcon,
      height: '8em',
      marginBottom: isMobile && !isTablet ? '-5.25em' : '-3.25em',
    },
    ChestOpenBarIcon: {
      ...defaultTheme.BottomBar.ChestOpenBarIcon,
      height: '4.5em',
      marginBottom: isTablet ? (isNotStandaloneMobilePWA ? '-0.3em' : '0.5em') : '0em',
    },
    ChestClosedBarIcon: {
      ...defaultTheme.BottomBar.ChestClosedBarIcon,
      height: '4.5em',
      marginBottom: isTablet && isNotStandaloneMobilePWA ? '-0.5em' : isMobile ? '-0.5em' : '0em',
    },
    TimerLabel: {
      ...defaultTheme.BottomBar.TimerLabel,
      position: 'relative',
      bottom: 'auto',
    },
    TimerBackground: {
      ...defaultTheme.BottomBar.TimerBackground,
      bottom: 'auto',
    },
    TimerWrapper: {
      ...defaultTheme.BottomBar.TimerWrapper,
      bottom: isMobile ? (isTablet ? '-1.8rem' : '-2.1em') : -20,
    },
    TimerCollectBackground: {
      ...defaultTheme.BottomBar.TimerCollectBackground,
      bottom: isTablet ? '-0.6em' : isMobile ? '-1em' : '-0.2em',
    },
  },
  GameTile: {
    ...defaultTheme.GameTile,
    SneakPeekTimerText: {
      ...defaultTheme.GameTile.SneakPeekTimerText,
      marginTop: '0.1rem',
    },
  },
  StoreDownload: {
    ...defaultTheme.StoreDownload,
    Container: {
      ...defaultTheme.StoreDownload.Container,
      backgroundColor: 'black',
    },
  },
  RewardCenter: {
    ...defaultTheme.RewardCenter,
    RewardCenterMainContentHolder: {
      ...defaultTheme.RewardCenter.RewardCenterMainContentHolder,
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    ProfileFooterLink: {
      ...defaultTheme.RewardCenter.ProfileFooterLink,
      color: theme.palette.primary[9],
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
  },
  Start: {
    ...defaultTheme.Start,
    Logo: {
      ...defaultTheme.Start.Logo,
      marginTop: windowHeight > 700 ? '6%' : '0px',
    },
    MainContentContainer: {
      ...defaultTheme.Start.MainContentContainer,
      marginTop: windowHeight > 700 ? 30 : -20,
    },
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 10,
      textShadow: '2px 2px 2px #000000',
    },
    MiddleLayoutButtonContainer: {
      ...defaultTheme.Start.MiddleLayoutButtonContainer,
      marginTop: isNotStandaloneMobilePWA ? '12rem' : '4rem',
    },
    ComingSoonBackgrounds: {
      ...defaultTheme.Start.ComingSoonBackgrounds,
      backgroundPosition: 'center 60%, center',
    },
    ComingSoonBackgroundsMobile: {
      ...defaultTheme.Start.ComingSoonBackgroundsMobile,
      backgroundPosition: windowWidth > windowHeight ? 'center 65%, 50%' : 'center 50%, 50%',
    },
    MiddleSectionWrapper: {
      ...defaultTheme.Start.MiddleSectionWrapper,
      paddingInlineStart: '0rem',
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.4rem',
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: '2px',
        },
        InnerItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem,
          StackLoyaltyIcon: {
            ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem.StackLoyaltyIcon,
            width: 35,
            position: 'relative',
            top: '0.2rem',
          },
        },
      },
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    MissionIcon: {
      ...defaultTheme.MissionsProgress.MissionIcon,
      top: isMobile ? '-0.8rem' : -5,
    },
    FillProgress: {
      ...defaultTheme.MissionsProgress.FillProgress,
      marginTop: windowWidth < 1400 ? '-12px' : '-8px',
    },
    FillProgressWithoutCard: {
      ...defaultTheme.MissionsProgress.FillProgressWithoutCard,
      marginTop: '-8px',
    },
    FillEnd: {
      ...defaultTheme.MissionsProgress.FillEnd,
      marginTop: windowWidth < 1400 ? '-12px' : '-8px',
    },
    FillEndWithoutCard: {
      ...defaultTheme.MissionsProgress.FillEndWithoutCard,
      marginTop: '-8px',
    },
    ProgressAnimation: {
      ...defaultTheme.MissionsProgress.ProgressAnimation,
      bottom: windowWidth < 1400 ? '-165%' : '-85%',
    },
    ProgressAnimationWithoutCard: {
      ...defaultTheme.MissionsProgress.ProgressAnimationWithoutCard,
      bottom: '-85%',
    },
    TimeBar: {
      ...defaultTheme.MissionsProgress.TimeBar,
      right: '-5px',
      top: '1.65rem',
      width: '100%',
      height: 'auto',
    },
    TimeBarWithoutCard: {
      ...defaultTheme.MissionsProgress.TimeBarWithoutCard,
      right: '-5px',
      top: '1.65rem',
      width: '100%',
      height: 'auto',
    },
    TimeRemainingText: {
      ...defaultTheme.MissionsProgress.TimeRemainingText,
      right: isTablet ? 'calc(50% - 50px)' : 'calc(50% - 60px)',
      fontSize: isTablet ? 16 : 20,
      top: isTablet
        ? windowWidth < 1150
          ? '2.8rem'
          : '2.6rem'
        : isMobile
          ? '2.7rem'
          : windowWidth > 1500
            ? '2.7rem'
            : '2.75rem',
    },
    TimeRemainingTextWithoutCard: {
      ...defaultTheme.MissionsProgress.TimeRemainingTextWithoutCard,
      right: 'calc(50% - 60px)',
      top: '2.7rem',
    },
  },
  CurrencyDisplay: {
    ...defaultTheme.CurrencyDisplay,
    Icons: {
      ...defaultTheme.CurrencyDisplay.Icons,
      loyalty: {
        ...defaultTheme.CurrencyDisplay.Icons.loyalty,
        left: -25,
      },
    },
  },
  ForgotPassword: {
    ...defaultTheme.ForgotPassword,
    ModalContainer: {
      ...defaultTheme.ForgotPassword.ModalContainer,
      height: '45vh',
      minHeight: '300px',
    },
    ModalBody: {
      ...defaultTheme.ForgotPassword.ModalBody,
      justifyContent: 'center',
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: pwaStyleAdapter(5, 'rem'),
    },
    TimerLabel: {
      ...defaultTheme.TimeRemaining.TimerLabel,
      color: theme.palette.common[1],
    },
  },
  Login: {
    ...defaultTheme.Login,
    ModalContainer: {
      ...defaultTheme.Login.ModalContainer,
      height: isNotStandaloneMobilePWA ? '55vh' : '45vh',
    },
    ModalStyle: {
      ...defaultTheme.Login.ModalStyle,
      paddingBottom: isNotStandaloneMobilePWA ? (windowHeight < 320 ? '20vh' : '12vh') : 0,
    },
    ForgetPasswordLink: {
      ...defaultTheme.Login.ForgetPasswordLink,
      textAlign: 'end',
      marginLeft: 0,
      marginRight: '0.8rem',
    },
  },
  SideBar: {
    ...defaultTheme.SideBar,
    MenuItem: {
      ...defaultTheme.SideBar.MenuItem,
      padding: `0 0 0 ${isMobile && !isTablet ? 7.5 : windowWidth < 1400 ? 2 : 3.5}rem`,
    },
    MenuContainer: {
      ...defaultTheme.SideBar.MenuContainer,
      border: '5px solid #A71FCD',
      background: 'linear-gradient(to bottom, #0b0213 0%, #330058 25%, #50008a 50%, #330058 75%, #0b0213 100%)',
    },
  },
  BillingProfile: {
    ...defaultTheme.BillingProfile,
    AddDeleteProfileText: {
      ...defaultTheme.BillingProfile.AddDeleteProfileText,
      border: '2px solid rgb(108, 182, 229)',
    },
  },
  MessageList: {
    ...defaultTheme.MessageList,
    ScrollBarCustomStyle: {
      ...defaultTheme.MessageList.ScrollBarCustomStyle,
      height: '100%',
    },
  },
  MessageContainer: {
    ...defaultTheme.MessageContainer,
    TitleText: {
      ...defaultTheme.MessageContainer.TitleText,
      textStroke: '2px #350041',
      WebkitTextStroke: '2px #350041',
    },
  },
  OfferDetailDescription: {
    ...defaultTheme.OfferDetailDescription,
    ButtonIcon: {
      ...defaultTheme.OfferDetailDescription.ButtonIcon,
      width: '3.5em',
      marginBottom: 3,
      marginLeft: -5,
    },
    ButtonText: {
      ...defaultTheme.OfferDetailDescription.ButtonText,
      paddingBottom: '2%',
      fontSize: 32,
    },
  },
  FavouritesInformation: {
    ...defaultTheme.FavouritesInformation,
    LevelUpText: {
      ...defaultTheme.FavouritesInformation.LevelUpText,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  BrowserScreen: {
    ...defaultTheme.BrowserScreen,
    DescriptionWrap: {
      ...defaultTheme.BrowserScreen.DescriptionWrap,
      marginTop: 0,
    },
    ButtonContainer: {
      ...defaultTheme.BrowserScreen.ButtonContainer,
      marginTop: '5vh',
    },
  },
};

export default LadyluckTheme;

