import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ADD_PENDING_MESSAGE, SHIFT_PENDING_MESSAGE, CLAIM_PENDING_MESSAGE, ACTIVATE_PROMOCODE, DISMISS_INVALID_PROMOCODE, CLEAR_FAKE_MESSAGES } from './types';
export var addPendingMessage = function addPendingMessage(message) {
  return {
    type: ADD_PENDING_MESSAGE,
    payload: message
  };
};
export var shiftPendingMessage = function shiftPendingMessage() {
  return {
    type: SHIFT_PENDING_MESSAGE
  };
};
export var claimPendingMessage = function claimPendingMessage(messageIndex, additionalMessageData) {
  return {
    type: CLAIM_PENDING_MESSAGE,
    payload: _objectSpread({
      messageIndex: messageIndex
    }, additionalMessageData)
  };
};
export var activatePromocode = function activatePromocode() {
  return {
    type: ACTIVATE_PROMOCODE
  };
};
export var dismissInvalidPromocode = function dismissInvalidPromocode() {
  return {
    type: DISMISS_INVALID_PROMOCODE
  };
};
export var clearFakeMessages = function clearFakeMessages() {
  return {
    type: CLEAR_FAKE_MESSAGES
  };
};