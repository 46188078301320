import { useEffect } from 'react';
import useCheckOrientation from './useCheckOrientation';
import { isMobile, isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../utils/mobileSupported';

const useViewport = () => {
  const { isPortrait, isLandscape } = useCheckOrientation();

  const updateViewport = () => {
    const viewportMeta = document.querySelector('head meta[name="viewport"]');
    if (!viewportMeta) return;

    const setViewportContent = content => {
      viewportMeta.setAttribute('content', content);
    };

    if (!isMobile) {
      document.body.style.overflow = 'auto';
      setViewportContent(
        'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover, maximum-scale=1.0, user-scalable=no'
      );
    } else if (isLandscape) {
      document.body.style.overflow = 'hidden';
      setViewportContent(
        `width=${isTablet ? '744' : 'device-width'}, initial-scale=1, shrink-to-fit=no, viewport-fit=cover, maximum-scale=${isTablet ? '.8' : '.5'}, user-scalable=no`
      );
    } else if (isPortrait) {
      if (document.activeElement && typeof document.activeElement.blur === 'function') document.activeElement.blur();

      setViewportContent(
        'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no, viewport-fit=cover, interactive-widget=resizes-content'
      );
      document.body.style.overflow = 'hidden';
    }

    isMobile && isStandalonePWA && document.documentElement.setAttribute('data-pwa-mode', 'mobile-standalone');
    isMobile && isNotStandaloneMobilePWA && document.documentElement.setAttribute('data-pwa-mode', 'mobile-browser');
    isTablet && isStandalonePWA && document.documentElement.setAttribute('data-pwa-mode', 'tablet-standalone');
    isTablet && isNotStandaloneMobilePWA && document.documentElement.setAttribute('data-pwa-mode', 'tablet-browser');

    window.dispatchEvent(new Event('resize'));
  };

  useEffect(() => {
    updateViewport();
    const timeoutId = setTimeout(() => {
      updateViewport();
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [isLandscape, isPortrait, isMobile, isTablet]);
};

export default useViewport;

