import React from 'react';
import useMessageItem from 'shared/screens/Social/hooks/useMessageItem';
import { messageTypes } from 'shared/utils/messageTypes';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import styled from 'styled-components';
import Button from '../../../../../../components/Button/Button';
import { closeButton, messagesTypes } from '../../utils/renderItems';
import { isMobile } from 'react-device-detect';

const JamulMessageItem = props => {
  const { message, messageDetailsHandle } = props;
  const { message_id, title, subject } = message;
  const renderMessage = messagesTypes();

  const { getMessageType, getBackgroundImage, getSubtitle, getIconImage, getActionHandler, deleteMessage } =
    useMessageItem({ ...props, renderMessage });

  const type = getMessageType();
  const backgroundImage = getBackgroundImage();
  const iconImage = getIconImage();
  const subtitle = getSubtitle();
  const actionHandler = () => getActionHandler();

  return (
    <>
      <Container background={backgroundImage}>
        <MessageContent>
          <IconImage src={iconImage} />
          <MessageInfo>
            <MessageTitle>{title || subject}</MessageTitle>
            <SubtitleContainer>
              {type?.image && <MessageImage src={type.image} />}
              <MessageSubtitle type={type?.type}>{subtitle}</MessageSubtitle>
            </SubtitleContainer>
          </MessageInfo>
          <Button
            imageSource={type?.button ? type.button.background : assetSource(ASSET_KEY.RIGHT_ARROW)}
            containerStyle={{ ...ButtonContainer, justifyContent: type?.button ? 'center' : 'flex-end' }}
            textStroke={type?.button ? type.button.textStroke : null}
            imageStyle={{ width: type?.button ? '25%' : '5%', zIndex: 0, right: '1rem' }}
            label={type?.button ? type.button.text : null}
            onClick={type?.button ? actionHandler : () => messageDetailsHandle(message_id)}
          />
        </MessageContent>
        <DeleteContainer onClick={() => deleteMessage(message_id)}>
          <DeleteButton src={closeButton} />
        </DeleteContainer>
      </Container>
    </>
  );
};

export default JamulMessageItem;

const Container = styled.div`
  width: 95%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  top: 1rem;
  left: -0.5rem;
  margin: 1rem auto;
`;

const MessageContent = styled.div`
  width: 95%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-radius: 2%;
  margin: 0rem;
  left: 1rem;
`;

const IconImage = styled.img`
  width: 100%;
  height: 85%;
  object-fit: contain;
  flex: 1;
  position: relative;
  left: 0.5rem;
  border-radius: 0.5rem;
`;

const MessageInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.3rem;
  flex: 6;
`;

const MessageTitle = styled.h4`
  font-size: ${isMobile ? 1.4 : 1.7}rem;
  font-weight: 600;
  margin: 0;
`;

const SubtitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.2rem;
  height: 35%;
`;

const MessageSubtitle = styled.p`
  font-size: ${({ type }) =>
    type !== messageTypes.COINS && type !== messageTypes.LOYALTY ? (isMobile ? 0.9 : 1.1) : isMobile ? 1 : 1.4}rem;
  margin: 0;
`;

const MessageImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 85%;
`;

const ButtonContainer = {
  flex: 3,
};

const DeleteButton = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const DeleteContainer = styled.div`
  position: relative;
  height: 30px;
  width: 30px;
  bottom: 44%;
  right: 97%;
  z-index: 3;
`;

